var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"permissManaTool"},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入等级权限名称"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addLevel.apply(null, arguments)}},model:{value:(_vm.level.name),callback:function ($$v) {_vm.$set(_vm.level, "name", $$v)},expression:"level.name"}}),(
        _vm.roleButton.buttonLevelComponents !== null && _vm.roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
      )?_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"size":"mini","type":"primary","icon":"el-icon-plus"},on:{"click":_vm.addLevel}},[_vm._v(" 添加等级权限 ")]):_vm._e()],1),_c('div',{staticClass:"permissManaMain"},[_c('el-collapse',{attrs:{"accordion":""},on:{"change":_vm.change},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.levels),function(l,index){return _c('el-collapse-item',{key:index,attrs:{"title":l.name,"name":l.id}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("可访问资源")]),(
                _vm.roleButton.buttonLevelComponents !== null &&
                _vm.roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
              )?_c('el-button',{staticStyle:{"float":"right","padding":"3px 0","color":"#ff0000"},attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.doDeleteLevel(l)}}}):_vm._e()],1),_c('div',[_c('el-tree',{key:index,ref:"tree",refInFor:true,attrs:{"show-checkbox":"","data":_vm.allModules,"props":_vm.defaultProps,"default-checked-keys":_vm.selectedModules,"node-key":"id"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.cancelUpdate}},[_vm._v("取消修改")]),(
                  _vm.roleButton.buttonLevelComponents !== null &&
                  _vm.roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
                )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.doUpdate(l, index)}}},[_vm._v(" 确认修改 ")]):_vm._e()],1)],1)])],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }