import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取武器属性
export function htArmsAttributes() {
	return getRequest("/hotta/role/armsAttributes");
}

// 获取武器性能
export function htArmsFunction() {
	return getRequest("/hotta/role/armsFunction");
}

// 幻塔角色列表
export function hottaRolePageData(params = {}) {
	return postRequest("/hotta/role/getPageData", params);
}

// 删除幻塔角色
export function delHottaRole(id) {
	return deleteRequest("/hotta/role/" + id);
}

// 添加幻塔角色
export function addHottaRole(params = {}) {
	return postRequest("/hotta/role", params);
}

// 更新幻塔角色
export function upHottaRole(params = {}) {
	return putRequest("/hotta/role", params);
}