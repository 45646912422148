import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有的审计操作信息
export function auditLogOperations() {
	return getRequest("/audit/log/getOperations");
}

// 添加审计操作
export function addOperation(params = {}) {
	return postRequest("/audit/log/addOperation", params);
}

// 更新审计操作
export function upOperation(params = {}) {
	return putRequest("/audit/log/updateOperation", params);
}

// 更新审计操作
export function delOperation(id) {
	return deleteRequest("/audit/log/deleteOperation/" + id);
}

// 获取审计日志信息(分页)
export function auditLogPageData(params = {}) {
	return postRequest("/audit/log/getPageData", params);
}

// 删除审计日志
export function delAuditLog(id) {
	return deleteRequest("/audit/log/" + id);
}