/**
 * 记录等级枚举
 */
export class AccountRecordCategory {
  /**
   * 支出
   */
  EXPENDITURE = 1

  /**
   * 收入
   */
  INCOME = 2
}
