<template>
  <div>
    <div style="margin-top: 15px">
      <el-button v-if="amtData.length > 0" :type="btnType" size="mini" :disabled="btnDisabled" @click="luckdraw">
        点击抽奖
      </el-button>
      <el-button type="warning" size="mini" @click="showCashView">点击兑换</el-button>
      <el-button type="danger" size="mini" @click="showConfigView">抽奖配置组</el-button>
      <el-button type="danger" size="mini" @click="showNumConfigsView">批量增加角色抽奖次数</el-button>
      <el-button type="danger" size="mini" @click="showNumConfigView">更新角色抽奖次数</el-button>
      <span v-if="amtData.length > 0" style="margin-left: 20px; color: #db6fd2; font-size: 15px">
        当前使用的配置组是 -> {{ this.amtData[0].configName }}
      </span>
      <span style="margin-left: 20px; color: red; font-size: 15px">当前可抽奖次数 -> {{ this.num }}</span>
    </div>
    <div style="margin-top: 15px; width: 2000px">
      <el-row :gutter="12">
        <el-col :span="2" v-for="(item, index) in amtData" :key="index">
          <el-card :body-style="item.bodyStyle">{{ item.amt }} : {{ item.count }}</el-card>
        </el-col>
      </el-row>
    </div>

    <div style="margin-top: 20px">
      <div>
        <el-date-picker
          style="width: 400px; margin-right: 20px"
          size="mini"
          range-separator="至"
          start-placeholder="抽奖开始日期"
          end-placeholder="抽奖结束日期"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          v-model="searchLuckdraw.time"
          value-format="yyyy-MM-dd HH:mm:ss"
          clearable
          @change="
            searchLuckdraw.currentPage = 1
            searchLuckdraw.size = 8
            initLuckdraw()
          "
        ></el-date-picker>
        <el-select
          v-model="searchLuckdraw.roleId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="抽奖人"
          clearable
          filterable
          @change="
            searchLuckdraw.currentPage = 1
            searchLuckdraw.size = 8
            initLuckdraw()
          "
        >
          <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
        </el-select>
        <el-select
          v-model="searchLuckdraw.amt"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="抽奖金额"
          clearable
          filterable
          @change="
            searchLuckdraw.currentPage = 1
            searchLuckdraw.size = 8
            initLuckdraw()
          "
        >
          <el-option v-for="item in amtData" :key="item.amt" :label="item.amt" :value="item.amt"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetLuckdraw">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchLuckdraw.currentPage = 1
            searchLuckdraw.size = 8
            initLuckdraw()
          "
        >
          搜索
        </el-button>
        <el-button type="success" size="mini" icon="el-icon-download" @click="exportData">导出数据</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="luckdrawData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="抽奖金额" align="center" prop="amt" sortable></el-table-column>
        <el-table-column label="抽奖人" align="center" prop="roleName"></el-table-column>
        <el-table-column label="抽奖时间" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.cash === 0">未兑现</el-tag>
            <el-tag type="success" v-else-if="scope.row.cash === 1">已兑现</el-tag>
            <el-tag type="info" v-else-if="scope.row.cash === 2">已发放</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="warning" @click="showCodeView(scope.row)">
              查看兑换码
            </el-button>
            <el-button
              v-if="giveOutShow(scope.row)"
              size="mini"
              style="padding: 3px"
              type="success"
              @click="giveOut(scope.row)"
            >
              发放
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Luckdraw') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteLuckdraw(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :page-sizes="[8]"
        :total="total"
        :current-page="searchLuckdraw.currentPage"
        :page-size="searchLuckdraw.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="cashDialogVisible"
      width="400px"
    >
      <el-form ref="cashForm" :model="cashData" :rules="rules">
        <table>
          <tr>
            <el-form-item label="抽奖兑换码" prop="code">
              <el-input
                size="mini"
                :disabled="cashFlag"
                style="width: 200px"
                v-model="cashData.code"
                placeholder="请输入抽奖兑换码"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashDialogVisible = false">取 消</el-button>
        <el-button v-if="!cashFlag" type="primary" @click="cashLuckdraw">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 抽奖配置组 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="configTitle"
      @close="closeConfigDialog()"
      :visible.sync="configDialogVisible"
      width="400px"
    >
      <el-form ref="configForm" :model="configData" :rules="configRules">
        <table>
          <tr>
            <el-form-item label="配置组编号">
              <el-select
                v-model="configData.configNumber"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="抽奖配置组编号"
                clearable
                filterable
                @change="initConfigInputData()"
              >
                <el-option
                  v-for="item in configs"
                  :key="item.configNumber"
                  :label="item.configList[0].configNumberName"
                  :value="item.configNumber"
                ></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="配置组名称" prop="configNumberName">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="configData.configNumberName"
                placeholder="请输入配置组名称抽奖次数配置"
              ></el-input>
            </el-form-item>
          </tr>
          <tr v-for="(inputItem, index) in configData.configs">
            <el-form-item label="抽奖金额">
              <el-input-number
                size="mini"
                v-model="configData.configs[index].amt"
                style="width: 150px"
                placeholder="请输入"
              ></el-input-number>
              <el-button style="margin-left: 10px" size="mini" type="danger" @click="delCol(index)">-</el-button>
              <el-button size="mini" type="success" @click="addCol">+</el-button>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否生效">
              <el-switch
                v-model="configData.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="padding-top: 6px"
              ></el-switch>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="configDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('Luckdraw') !== -1 &&
            configData.configNumber !== null
          "
          type="danger"
          @click="deleteConfig"
        >
          删 除
        </el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Luckdraw') !== -1
          "
          type="primary"
          @click="doConfig"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 更新角色抽奖次数 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="numConfigTitle"
      @close="closeNumConfigDialog()"
      :visible.sync="numConfigDialogVisible"
      width="400px"
    >
      <el-form ref="numConfigForm" :model="numConfigData" :rules="numConfigRules">
        <table>
          <tr>
            <el-form-item label="角色编号" prop="roleId">
              <el-select
                v-model="numConfigData.roleId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="角色编号"
                clearable
                filterable
                @change="initNumConfigInputData()"
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="抽奖次数" prop="num">
              <el-input-number
                size="mini"
                v-model="numConfigData.num"
                style="width: 200px"
                :min="0"
                placeholder="请输入抽奖次数"
              ></el-input-number>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="numConfigDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Luckdraw') !== -1
          "
          type="primary"
          @click="doNumConfig"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 批量增加角色抽奖次数 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="numConfigTitle"
      @close="closeNumConfigDialog()"
      :visible.sync="numConfigsDialogVisible"
      width="600px"
    >
      <el-form ref="numConfigsForm" :model="numConfigData" :rules="numConfigsRules">
        <table>
          <tr>
            <el-form-item label="角色编号" prop="roleIds">
              <el-select
                v-model="numConfigData.roleIds"
                size="mini"
                style="width: 400px; margin-right: 20px"
                multiple
                placeholder="角色编号"
                clearable
                filterable
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="抽奖次数" prop="num">
              <el-input-number
                size="mini"
                v-model="numConfigData.num"
                style="width: 200px"
                :min="0"
                placeholder="请输入抽奖次数"
              ></el-input-number>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="numConfigsDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Luckdraw') !== -1
          "
          type="primary"
          @click="doNumConfigs"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { roles } from '@/api/sys/role'
import {
  lottery,
  luckdrawNum,
  addLuckdrawConfig,
  delLuckdrawConfig,
  luckdrawCash,
  luckdrawCode,
  luckdrawConfigList,
  luckdrawNumConfig,
  luckdrawPageData,
  luckdrawConfig,
  upLuckdrawConfig,
  delLuckdraw,
  luckdrawRecordExport,
  luckdrawGiveOut
} from '@/api/activity/luckdraw'

export default {
  name: 'Luckdraw',
  data() {
    return {
      roles: [],
      amtData: [],
      btnType: 'primary',
      btnDisabled: false,
      amt: '',
      isShow: false,
      loading: false,
      searchLuckdraw: {
        amt: '',
        roleId: null,
        time: null,
        startTime: null,
        endTime: null,
        currentPage: 1,
        size: 8
      },
      total: 0,
      cashDialogVisible: false,
      configDialogVisible: false,
      cashData: {
        code: ''
      },
      luckdrawData: [],
      title: '',
      configTitle: '',
      cashFlag: true,
      configs: [],
      configData: {
        configNumber: null,
        configNumberName: '',
        enabled: false,
        configs: [
          {
            amt: ''
          }
        ]
      },
      num: 0,
      numConfigData: {
        roleId: null,
        roleIds: [],
        num: null
      },
      numConfigTitle: '',
      numConfigDialogVisible: false,
      numConfigsDialogVisible: false,
      rules: {
        code: [{ required: true, message: '请输入抽奖兑换码', trigger: 'blur' }]
      },
      configRules: {
        configNumberName: [{ required: true, message: '请输入配置组名称', trigger: 'blur' }]
      },
      numConfigRules: {
        roleId: [{ required: true, message: '请选择角色编号', trigger: 'change' }],
        num: [{ required: true, message: '请输入可抽奖次数', trigger: 'blur' }]
      },
      numConfigsRules: {
        roleIds: [{ required: true, message: '请选择角色编号', trigger: 'change' }],
        num: [{ required: true, message: '请输入可抽奖次数', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    this.initRoles()
    this.getNum()
    this.getConfig()
    this.initLuckdraw()
  },
  methods: {
    luckdraw() {
      if (this.num === 0) {
        this.$message.error('已经没有抽奖次数了哦~')
        return
      }

      lottery().then(async resp => {
        if (resp) {
          this.amt = resp
          // 抽奖过程中 按钮不可以点击
          this.btnType = 'info'
          this.btnDisabled = true

          // 动画处理效果
          await this.luckdrawStyle()
          // 重新获取抽奖次数
          this.getNum()
        }
      })
    },
    async luckdrawStyle() {
      // 所有效果初始化
      for (const item of this.amtData) {
        item.bodyStyle = ''
      }

      // 转圈动画
      for (let i = 0; i < 5; i++) {
        for (const item of this.amtData) {
          item.bodyStyle = 'background-color: #FF69B4'
          await this.sleep(50)
          item.bodyStyle = ''
        }
      }

      // 最后一圈找到抽奖的那个
      let flag = false
      for (const item of this.amtData) {
        if (!flag) {
          if (String(this.amt) !== String(item.amt)) {
            item.bodyStyle = 'background-color: #FF69B4'
            await this.sleep(50)
            item.bodyStyle = ''
          } else {
            flag = true
            item.bodyStyle = 'background-color: #FF69B4'
          }
        }
      }

      // 按钮打开
      this.btnDisabled = false
      this.btnType = 'primary'

      // 抽奖配置和当天抽奖记录查询
      this.getConfig()
      // 抽奖记录刷新
      this.initLuckdraw()
    },
    getConfig() {
      luckdrawConfig().then(resp => {
        if (resp) {
          this.amtData = resp
        }
      })
    },
    sleep(d) {
      return new Promise(resolve => setTimeout(resolve, d))
    },
    currentChange(page) {
      this.searchLuckdraw.currentPage = page
      this.initLuckdraw()
    },
    sizeChange(size) {
      this.searchLuckdraw.size = size
      this.initLuckdraw()
    },
    initLuckdraw() {
      // 时间处理
      if (
        this.searchLuckdraw.time !== undefined &&
        this.searchLuckdraw.time !== null &&
        this.searchLuckdraw.time !== ''
      ) {
        this.searchLuckdraw.startTime = this.searchLuckdraw.time[0]
        this.searchLuckdraw.endTime = this.searchLuckdraw.time[1]
      }

      this.loading = true
      luckdrawPageData(this.searchLuckdraw).then(resp => {
        this.loading = false
        if (resp) {
          this.luckdrawData = resp.data
          this.total = resp.total
          this.getNum()
        }
      })
    },
    resetLuckdraw() {
      this.searchLuckdraw.amt = ''
      this.searchLuckdraw.roleId = null
      this.searchLuckdraw.time = null
      this.searchLuckdraw.startTime = null
      this.searchLuckdraw.endTime = null
    },
    showCashView() {
      this.title = '兑现兑换码'
      this.cashFlag = false
      this.cashDialogVisible = true
    },
    showCodeView(data) {
      luckdrawCode(data.id).then(resp => {
        if (resp) {
          this.cashData.code = resp
          this.title = '查看兑换码'
          this.cashFlag = true
          this.cashDialogVisible = true
        }
      })
    },
    closeDialog() {},
    cashLuckdraw() {
      this.$refs['cashForm'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将兑现[' + this.cashData.code + '], 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              luckdrawCash(this.cashData).then(resp => {
                if (resp) {
                  this.cashDialogVisible = false
                  // 抽奖配置和当天抽奖记录查询
                  this.getConfig()
                  // 抽奖记录刷新
                  this.initLuckdraw()
                }
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消兑换'
              })
            })
        }
      })
    },
    deleteLuckdraw(data) {
      this.$confirm('此操作将永久删除[' + data.amt + ']元的抽奖记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delLuckdraw(data.id).then(resp => {
            if (resp) {
              // 抽奖配置和当天抽奖记录查询
              this.getConfig()
              // 抽奖记录刷新
              this.initLuckdraw()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    closeConfigDialog() {
      this.configData.configNumber = null
      this.configData.configNumberName = ''
      this.configData.enabled = false
      this.configData.configs = [
        {
          amt: ''
        }
      ]
    },
    initConfigs() {
      luckdrawConfigList().then(resp => {
        if (resp) {
          this.configs = resp
        }
      })
    },
    showConfigView() {
      this.initConfigs()
      this.configTitle = '抽奖配置组'
      this.configDialogVisible = true
    },
    initConfigInputData() {
      // 整理configList数据
      let configNumber = this.configData.configNumber

      // 如果是清除就恢复最初数据
      if (configNumber === '') {
        this.closeConfigDialog()
        return
      }

      this.configs.forEach(item => {
        if (String(item.configNumber) === String(configNumber)) {
          this.configData.configs = item.configList
          this.configData.enabled = item.configList[0].enabled
          this.configData.configNumberName = item.configList[0].configNumberName
        }
      })
    },
    delCol(index) {
      if (this.configData.configs.length === 1) {
        this.$message.error('必须存在一条')
        return
      }
      this.configData.configs.splice(index, 1)
    },
    addCol() {
      this.configData.configs.push({
        amt: ''
      })
    },
    deleteConfig() {
      this.$confirm('此操作将永久删除[' + this.configData.configNumberName + ']的抽奖配置组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delLuckdrawConfig(this.configData.configNumber).then(resp => {
            if (resp) {
              this.configDialogVisible = false
              // 抽奖配置和当天抽奖记录查询
              this.getConfig()
              // 抽奖记录刷新
              this.initLuckdraw()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doConfig() {
      if (this.configData.configNumber) {
        // 编辑
        this.$refs['configForm'].validate(valid => {
          if (valid) {
            upLuckdrawConfig(this.configData).then(resp => {
              if (resp) {
                this.configDialogVisible = false
                // 抽奖配置和当天抽奖记录查询
                this.getConfig()
                // 抽奖记录刷新
                this.initLuckdraw()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['configForm'].validate(valid => {
          if (valid) {
            addLuckdrawConfig(this.configData).then(resp => {
              if (resp) {
                this.configDialogVisible = false
                // 抽奖配置和当天抽奖记录查询
                this.getConfig()
                // 抽奖记录刷新
                this.initLuckdraw()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    showNumConfigView() {
      this.numConfigTitle = '更新角色抽奖次数'
      this.numConfigDialogVisible = true
    },
    showNumConfigsView() {
      this.numConfigTitle = '批量增加角色抽奖次数'
      this.numConfigsDialogVisible = true
    },
    closeNumConfigDialog() {
      this.numConfigData.roleId = null
      this.numConfigData.roleIds = []
      this.numConfigData.num = null
    },
    initNumConfigInputData() {
      luckdrawNum(this.numConfigData).then(resp => {
        this.numConfigData.num = resp
      })
    },
    getNum() {
      let role = JSON.parse(window.sessionStorage.getItem('role'))
      let params = {
        roleId: role.id
      }
      luckdrawNum(params).then(resp => {
        this.num = resp
      })
    },
    doNumConfig() {
      this.$refs['numConfigForm'].validate(valid => {
        if (valid) {
          luckdrawNumConfig(this.numConfigData).then(resp => {
            if (resp) {
              this.numConfigDialogVisible = false
              // 重新获取抽奖次数
              this.getNum()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    doNumConfigs() {
      this.$refs['numConfigsForm'].validate(valid => {
        if (valid) {
          luckdrawNumConfig(this.numConfigData).then(resp => {
            if (resp) {
              this.numConfigsDialogVisible = false
              // 重新获取抽奖次数
              this.getNum()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    giveOutShow(data) {
      return this.isOperator() && data.cash === 1
    },
    giveOut(data) {
      this.$confirm('此操作将发放[' + data.amt + ']元的抽奖记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          luckdrawGiveOut(data.id).then(resp => {
            if (resp) {
              // 抽奖配置和当天抽奖记录查询
              this.getConfig()
              // 抽奖记录刷新
              this.initLuckdraw()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发放'
          })
        })
    },
    exportData() {
      luckdrawRecordExport()
    }
  }
}
</script>

<style>
.showClass {
  background-color: #ff69b4;
}
</style>
