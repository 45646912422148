import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取组件信息
export function getComponents() {
	return getRequest("/sys/menu/getComponents");
}

// 获取全部父级菜单信息
export function parentMenus() {
	return getRequest("/sys/menu/getAllParentMenus");
}

// 获取全部的图标信息
export function menuIcons() {
	return getRequest("/sys/menu/getAllIcons");
}

// 菜单列表
export function menuPageData(params = {}) {
	return postRequest("/sys/menu/getPageData", params);
}

// 删除菜单
export function delMenu(id) {
	return deleteRequest("/sys/menu/" + id);
}

// 添加菜单
export function addMenu(params = {}) {
	return postRequest("/sys/menu", params);
}

// 更新菜单
export function upMenu(params = {}) {
	return putRequest("/sys/menu", params);
}