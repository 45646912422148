import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 文件列表
export function filePageData(params = {}) {
	return postRequest("/files/getPageData", params);
}

// 获取文件类型
export function fileTypes() {
	return getRequest("/files/getTypes");
}

// 删除文件
export function delFile(id) {
	return deleteRequest("/files/" + id);
}

// 添加文件
export function addFile(params = {}) {
	return postRequest("/files", params);
}

// 编辑文件
export function upFile(params = {}) {
	return putRequest("/files", params);
}