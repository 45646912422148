import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有的崩铁遗器信息
export function starRailRelics(params = {}) {
	return postRequest("/star-rail/relic/getAll", params);
}

// 崩铁遗器列表
export function starRailRelicPageData(params = {}) {
	return postRequest("/star-rail/relic/getPageData", params);
}

// 删除崩铁遗器
export function delStarRailRelic(id) {
	return deleteRequest("/star-rail/relic/" + id);
}

// 添加崩铁遗器
export function addStarRailRelic(params = {}) {
	return postRequest("/star-rail/relic", params);
}

// 更新崩铁遗器
export function upStarRailRelic(params = {}) {
	return putRequest("/star-rail/relic", params);
}