<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="角色名称"
          v-model="searchRole.name"
          clearable
          @keydown.enter.native="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        ></el-input>
        <el-select
          v-model="searchRole.areaId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色所属地区"
          clearable
          filterable
          @change="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        >
          <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select
          v-model="searchRole.positionId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色所属定位"
          clearable
          filterable
          @change="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        >
          <el-option v-for="item in positions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select
          v-model="searchRole.star"
          placeholder="请选择角色星级"
          size="mini"
          style="width: 200px; margin-right: 20px"
          clearable
          filterable
          @change="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        >
          <el-option v-for="item in arknightsStars" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="角色种族"
          v-model="searchRole.race"
          clearable
          @keydown.enter.native="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        ></el-input>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetArknightsRole">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchRole.currentPage = 1
            searchRole.size = 10
            initRole()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddRoleView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('ArknightsRole') !== -1
          "
        >
          添加角色
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="roleData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column align="center" width="100" fixed="left" label="角色名称">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="area.name" align="center" width="120" label="角色所属地区"></el-table-column>
        <el-table-column prop="level" align="center" label="角色等级">
          <template slot-scope="scope">
            <span>{{ scope.row.levelOne }} {{ scope.row.levelTwo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="race" align="center" label="角色种族"></el-table-column>
        <el-table-column label="所属定位" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.position.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="角色星级" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.star }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="module.name" width="150" align="center" label="携带模组">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.module">{{ scope.row.module.name }}</el-tag>
            <el-tag type="danger" v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="角色好感度" align="center" width="100">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.favorability }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="角色潜能" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.potential }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="角色专精" align="center" width="120">
          <template slot-scope="scope">
            <el-tag type="primary">
              {{ scope.row.masteryOne }}/{{ scope.row.masteryTwo }}/{{ scope.row.masteryThree }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="角色天赋">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.talent && scope.row.talent.length > 10"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.talent.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.talent }}</span>
              <span v-if="scope.row.talent">{{ scope.row.talent | ellipsis }}</span>
            </el-tooltip>
            <span v-else-if="scope.row.talent" v-html="scope.row.talent"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="角色技能">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.skill && toText(scope.row.talent).length > 10"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.skill.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.skill }}</span>
              <span v-if="scope.row.skill" v-html="$options.filters.ellipsisHtml(scope.row.skill)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.skill" v-html="toText(scope.row.skill)"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsRole') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateRoleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsRole') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteRole(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchRole.currentPage"
        :page-size="searchRole.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="roleForm" :model="role" :rules="rules" label-width="125px">
        <table>
          <tr>
            <el-form-item label="角色名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="role.name" placeholder="请输入角色名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色所属地区" prop="areaId">
              <el-select
                v-model="role.areaId"
                placeholder="请选择角色所属地区"
                size="mini"
                style="width: 200px"
                clearable
                filterable
              >
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr v-if="moduleFlag">
            <el-form-item label="角色拥有模组">
              <el-select
                v-model="role.moduleId"
                placeholder="请选择角色拥有模组"
                size="mini"
                style="width: 200px"
                clearable
                filterable
              >
                <el-option v-for="item in modules" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色等级" prop="level">
              <el-input
                placeholder="请输入角色等级"
                style="width: 300px"
                v-model="role.levelTwo"
                class="input-with-select"
              >
                <el-select v-model="role.levelOne" placeholder="请选择" size="mini" slot="prepend" style="width: 100px">
                  <el-option v-for="item in arknightsLevels" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色种族" prop="race">
              <el-input size="mini" style="width: 200px" v-model="role.race" placeholder="请输入角色种族"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色所属定位" prop="positionId">
              <el-select
                v-model="role.positionId"
                placeholder="请选择角色所属定位"
                size="mini"
                style="width: 200px"
                clearable
                filterable
              >
                <el-option v-for="item in positions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色星级" prop="star">
              <el-select
                v-model="role.star"
                placeholder="请选择角色星级"
                size="mini"
                style="width: 200px"
                clearable
                filterable
              >
                <el-option v-for="item in arknightsStars" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色好感度" prop="favorability">
              <el-input-number
                :min="0"
                :max="200"
                size="mini"
                style="width: 200px"
                v-model="role.favorability"
                placeholder="请输入角色好感度"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色潜能" prop="potential">
              <el-select
                v-model="role.potential"
                placeholder="请选择角色潜能"
                size="mini"
                style="width: 200px"
                clearable
                filterable
              >
                <el-option v-for="item in arknightsPotentials" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色专精" prop="mastery">
              <el-select
                v-model="role.masteryOne"
                placeholder="请选择"
                size="mini"
                style="width: 100px"
                clearable
                filterable
              >
                <el-option v-for="item in arknightsMasterys" :key="item" :label="item" :value="item"></el-option>
              </el-select>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <el-select
                v-model="role.masteryTwo"
                placeholder="请选择"
                size="mini"
                style="width: 100px"
                clearable
                filterable
              >
                <el-option v-for="item in arknightsMasterys" :key="item" :label="item" :value="item"></el-option>
              </el-select>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <el-select
                v-model="role.masteryThree"
                placeholder="请选择"
                size="mini"
                style="width: 100px"
                clearable
                filterable
              >
                <el-option v-for="item in arknightsMasterys" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色天赋" prop="talent">
              <div id="richText2"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色技能" prop="skill">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import { arknightsPositions } from '@/api/arknights/position'
import { arknightsAreas } from '@/api/arknights/area'
import { arknightsModuleByRoleId } from '@/api/arknights/module'
import { addArknightsRole, delArknightsRole, arknightsRolePageData, upArknightsRole } from '@/api/arknights/role'
let editor
let editor2

export default {
  name: 'ArknightsRole',
  data() {
    var validateLevel = (rule, value, callback) => {
      if (this.role.levelOne === '') {
        callback(new Error('请选择'))
      } else if (this.role.levelTwo === '') {
        callback(new Error('请输入角色等级'))
      } else {
        callback()
      }
    }
    var validateMastery = (rule, value, callback) => {
      if (this.role.masteryOne === '' || this.role.masteryTwo === '' || this.role.masteryThree === '') {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    var validateTalent = (rule, value, callback) => {
      if (editor2.txt.html() === undefined || editor2.txt.html() === '') {
        callback(new Error('请输入角色天赋'))
      } else {
        callback()
      }
    }
    var validateSkill = (rule, value, callback) => {
      if (editor.txt.html() === undefined || editor.txt.html() === '') {
        callback(new Error('请输入角色技能'))
      } else {
        callback()
      }
    }
    return {
      roleData: [],
      positions: [],
      areas: [],
      modules: [],
      loading: false,
      moduleFlag: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchRole: {
        name: '',
        race: '',
        areaId: null,
        positionId: null,
        star: null,
        currentPage: 1,
        size: 10
      },
      role: {
        id: null,
        name: '',
        favorability: null,
        race: '',
        levelOne: '',
        levelTwo: '',
        potential: null,
        masteryOne: '',
        masteryTwo: '',
        masteryThree: '',
        skill: '',
        talent: '',
        star: null,
        areaId: null,
        positionId: null,
        moduleId: null
      },
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        areaId: [{ required: true, message: '请输入角色所属地区', trigger: 'change' }],
        race: [{ required: true, message: '请输入角色种族', trigger: 'blur' }],
        positionId: [{ required: true, message: '请选择角色所属定位', trigger: 'change' }],
        star: [{ required: true, message: '请选择角色星级', trigger: 'change' }],
        favorability: [{ required: true, message: '请输入角色好感度', trigger: 'blur' }],
        potential: [{ required: true, message: '请选择角色潜能', trigger: 'change' }],
        talent: [{ validator: validateTalent, trigger: 'blur' }],
        skill: [{ validator: validateSkill, trigger: 'blur' }],
        level: [{ validator: validateLevel, trigger: 'blur' }],
        mastery: [{ validator: validateMastery, trigger: 'blur' }]
      },
      arknightsLevels: store.getters.arknightsLevelTypes,
      arknightsMasterys: store.getters.arknightsMasteryTypes,
      arknightsPotentials: store.getters.arknightsPotentials,
      arknightsStars: store.getters.arknightsStars,
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
    editor2 = ''
  },
  mounted() {
    this.initRole()
    this.initAllPositions()
    this.initAllAreas()
  },
  methods: {
    initRole() {
      this.loading = true
      arknightsRolePageData(this.searchRole).then(resp => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.roleData = resp.data
        }
      })
    },
    initAllPositions() {
      arknightsPositions().then(resp => {
        if (resp) {
          this.positions = resp
        }
      })
    },
    initAllAreas() {
      arknightsAreas().then(resp => {
        if (resp) {
          this.areas = resp
        }
      })
    },
    currentChange(page) {
      this.searchRole.currentPage = page
      this.initRole()
    },
    sizeChange(page) {
      this.searchRole.size = page
      this.initRole()
    },
    deleteRole(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delArknightsRole(data.id).then(resp => {
            if (resp) {
              this.initRole()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddRoleView(data) {
      this.role = {
        id: null,
        name: '',
        favorability: null,
        race: '',
        levelOne: '',
        levelTwo: '',
        potential: null,
        masteryOne: '',
        masteryTwo: '',
        masteryThree: '',
        skill: '',
        talent: '',
        star: null,
        areaId: null,
        positionId: null,
        moduleId: null
      }
      this.title = '添加角色'
      this.modules = []
      this.moduleFlag = false
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        // 清空内容
        editor.txt.html('')
        editor2.txt.html('')
      })
    },
    showUpdateRoleView(data) {
      // 拷贝
      this.role = Object.assign({}, data)
      this.modules = []
      this.getModuleByRole(data.id)
      this.moduleFlag = true
      this.title = '编辑角色'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor2.txt.html('')
        editor.txt.html(this.role.skill)
        editor2.txt.html(this.role.talent)
      })
    },
    closeDialog() {
      this.$refs['roleForm'].resetFields()
    },
    doRole() {
      if (this.role.id) {
        // 编辑
        this.$refs['roleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.role.skill = editor.txt.html()
            this.role.talent = editor2.txt.html()
            upArknightsRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['roleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.role.skill = editor.txt.html()
            this.role.talent = editor2.txt.html()
            addArknightsRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    getModuleByRole(id) {
      arknightsModuleByRoleId(id).then(resp => {
        if (resp) {
          this.modules = resp
        }
      })
    },
    resetArknightsRole() {
      this.searchRole.name = ''
      this.searchRole.race = ''
      this.searchRole.areaId = null
      this.searchRole.positionId = null
      this.searchRole.star = null
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
