import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取登录的用户信息
export function getCurrentAdminInfo() {
	return getRequest("/userinfo/getCurrentAdminInfo");
}

// 获取全部等级信息
export function getUserInfoLevels() {
	return getRequest("/userinfo/levels");
}

// 修改当前登录的角色密码
export function updateCurrentAdminPassword(params = {}) {
	return putRequest("/userinfo/updateCurrentAdminPassword", params);
}

// 更新角色信息
export function updateUserInfo(params = {}) {
	return putRequest("/userinfo", params);
}

// 获取全部的按钮权限
export function userinfoButtonLevels() {
	return getRequest("/userinfo/buttonLevels");
}