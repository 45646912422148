<template>
  <div style="padding: 10px">
    <el-card>
      <div id="myChart" :style="{ width: '800px', height: '600px' }"></div>
    </el-card>
  </div>
</template>

<script>
import { friendCount } from '@/api/record/friend'

export default {
  name: 'FriendEcharts',
  data() {
    return {}
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$root.echarts.init(document.getElementById('myChart'))
      let option = {
        title: {
          text: '朋友比例统计图',
          subtext: '虚拟数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: 'bottom',
          trigger: 'item'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '朋友比例',
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      }
      friendCount().then((res) => {
        res.forEach((item) => {
          option.series[0].data.push({ name: item.address, value: item.count })
        })
        // 绘制图表
        myChart.setOption(option)
      })
    }
  }
}
</script>

<style></style>
