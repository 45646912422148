<template>
  <div class="base">
    <!-- 滑动验证码 -->
    <div id="captcha-box" class="captchaBox"></div>

    <!-- 注册登录界面 -->
    <div class="loginAndRegist">
      <!--登录表单-->
      <div class="loginArea">
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!-- 标语 -->
          <div v-show="isShow" class="loginTitle2">LOGIN</div>
        </transition>
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!-- 密码框和登录名框 -->
          <div v-show="isShow" class="pwdArea">
            <div style="flex: 1; justify-content: center; display: flex; align-items: center">
              <el-input
                class="username"
                v-model="loginUser.username"
                style="width: 165px; padding-top: 25px"
                placeholder="登录名/手机号"
              ></el-input>
            </div>
            <div style="flex: 1; justify-content: center; display: flex; align-items: center">
              <el-input placeholder="密码" v-model="loginUser.password" style="width: 165px" show-password></el-input>
            </div>
            <!--        旧的文本验证框 已废弃
                        <div style="flex: 1; justify-content: center; display: flex; align-items: center">
                          <img :src="kaptchaUrl" @click="updateKaptcha"/>
                        </div>
                        <div style="flex: 1; justify-content: center; display: flex; align-items: center">
                          <el-input
                              placeholder="点击图片更换验证码"
                              maxLength="4"
                              v-model="loginUser.code"
                              style="width: 165px"
                              @keydown.enter.native="submitLogin"
                          ></el-input>
                        </div>-->
          </div>
        </transition>
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!-- 登录注册按钮 submitLogin -->
          <div v-show="isShow" class="btnArea">
            <el-button
              type="success"
              round
              :style="[submitLoginStyle]"
              style="background-color: #dbf1e1; width: 165px"
              @click.prevent="openCaptcha"
            >
              登&nbsp;&nbsp;录
            </el-button>
          </div>
        </transition>
      </div>

      <!-- 注册表单 -->
      <div class="registArea">
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!--注册表头-->
          <div v-show="!isShow" class="rigestTitle">
            {{ optionTitle }}
          </div>
        </transition>

        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!--注册/重置密码表单-->
          <div v-show="!isShow" class="registForm">
            <div
              v-if="optionType === LoginOptionType.REGIEST"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              登&nbsp;&nbsp;&nbsp;录&nbsp;&nbsp;&nbsp;名:
              <el-input
                placeholder="请输入登录名"
                v-model="regUser.username"
                style="width: 165px; margin-left: 10px"
                clearable
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.REGIEST"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:
              <el-input
                placeholder="请输入密码"
                style="width: 165px; margin-left: 10px"
                v-model="regUser.password"
                show-password
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.REGIEST"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              手&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;号:
              <el-input
                placeholder="请输入手机号"
                maxLength="11"
                style="width: 165px; margin-left: 10px"
                v-model="regUser.phone"
                clearable
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.REGIEST"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              <el-button
                type="success"
                round
                :style="[getCodeStyle]"
                style="background-color: #257b5e; width: 165px"
                @click="getCode"
              >
                获&nbsp;&nbsp;取&nbsp;&nbsp;验&nbsp;&nbsp;证&nbsp;&nbsp;码
              </el-button>
            </div>
            <div
              v-if="optionType === LoginOptionType.REGIEST"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;码:
              <el-input
                placeholder="请输入验证码"
                maxLength="6"
                style="width: 165px; margin-left: 10px"
                v-model="regUser.code"
                clearable
              ></el-input>
            </div>

            <!-- 重置密码相关 -->
            <div
              v-if="optionType === LoginOptionType.RESET"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              手&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;号:
              <el-input
                placeholder="请输入手机号"
                maxLength="11"
                style="width: 165px; margin-left: 10px"
                v-model="resetUser.phone"
                clearable
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.RESET"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              <el-button
                type="success"
                round
                :style="[getCodeStyle]"
                style="background-color: #257b5e; width: 165px"
                @click="getCode"
              >
                获&nbsp;&nbsp;取&nbsp;&nbsp;验&nbsp;&nbsp;证&nbsp;&nbsp;码
              </el-button>
            </div>
            <div
              v-if="optionType === LoginOptionType.RESET"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;码:
              <el-input
                placeholder="请输入验证码"
                maxLength="6"
                style="width: 165px; margin-left: 10px"
                v-model="resetUser.code"
                clearable
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.RESET"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:
              <el-input
                placeholder="请输入密码"
                style="width: 165px; margin-left: 10px"
                v-model="resetUser.password"
                show-password
              ></el-input>
            </div>
            <div
              v-if="optionType === LoginOptionType.RESET"
              style="flex: 1; display: flex; justify-content: center; align-items: center"
            >
              确&nbsp;认&nbsp;密&nbsp;码:
              <el-input
                placeholder="请输入密码"
                style="width: 165px; margin-left: 10px"
                v-model="resetUser.confirmPassword"
                show-password
              ></el-input>
            </div>
          </div>
        </transition>

        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!--注册/重置密码按钮-->
          <div v-show="!isShow" class="registBtn">
            <el-button
              v-if="optionType === LoginOptionType.REGIEST"
              type="success"
              round
              :style="[submitRegisterStyle]"
              style="background-color: #257b5e; width: 165px"
              @click="submitRegister"
            >
              注&nbsp;&nbsp;册
            </el-button>
            <el-button
              v-if="optionType === LoginOptionType.RESET"
              type="success"
              round
              :style="[submitResetStyle]"
              style="background-color: #257b5e; width: 165px"
              @click="submitReset"
            >
              重&nbsp;置&nbsp;密&nbsp;码
            </el-button>
          </div>
        </transition>
      </div>

      <!-- 信息展示界面 -->
      <div
        id="aaa"
        class="showInfo"
        :style="{
          borderTopRightRadius: styleObj.bordertoprightradius,
          borderBottomRightRadius: styleObj.borderbottomrightradius,
          borderTopLeftRadius: styleObj.bordertopleftradius,
          borderBottomLeftRadius: styleObj.borderbottomleftradius,
          right: styleObj.rightDis
        }"
        ref="showInfoView"
      >
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!-- 没有用户输入用户名或者找不到用户名的时候 -->
          <div
            v-show="isShow"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            "
          >
            <!-- 欢迎语 -->
            <div
              style="flex: 4; display: flex; align-items: center; font-size: 22px; color: #ffffff; font-weight: bold"
            >
              欢迎登入后台管理系统
            </div>
            <!-- 欢迎图片 -->
            <div style="flex: 2">
              <el-button
                type="success"
                style="background-color: #257b5e; border: 1px solid #ffffff"
                round
                @click="changeToRegiest"
              >
                还没有账户？点击注册
              </el-button>
            </div>
            <div style="flex: 4">
              <el-button
                type="success"
                style="background-color: #257b5e; border: 1px solid #ffffff"
                round
                @click="changeToReset"
              >
                忘记密码啦？点击重置
              </el-button>
            </div>
          </div>
        </transition>
        <!-- 用户输入用户名时展示头像以及姓名 -->
        <transition
          name="animate__animated animate__bounce"
          enter-active-class="animate__fadeInUp"
          leave-active-class="animate__zoomOut"
          appear
        >
          <!-- 用户注册的时候展示信息 -->
          <div
            v-show="!isShow"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            "
          >
            <!-- 欢迎语 -->
            <div
              style="flex: 2; display: flex; align-items: center; font-size: 22px; color: #ffffff; font-weight: bold"
            >
              {{ optionWelcome }}
            </div>
            <!-- 欢迎图片 -->
            <div style="flex: 2">
              <el-button
                type="success"
                style="background-color: #257b5e; border: 1px solid #ffffff"
                round
                @click="changeToLogin"
              >
                已有账户？点击登录
              </el-button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css'
import { login, register, resetPass, sendCode } from '@/api/login'
import { LoginOptionType } from '@/constant/LoginOptionType'
import { SendCodeType } from '@/constant/SendCodeType'
import logo from '@/assets/images/xrg-app.png'
import baseUrl from '@/utils/baseUrl'

export default {
  name: 'Login2',
  data() {
    return {
      loginUser: {
        username: '',
        password: '',
        code: ''
      },
      loading: false,
      regUser: {
        username: '',
        password: '',
        phone: '',
        code: ''
      },
      resetUser: {
        phone: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      styleObj: {
        bordertoprightradius: '15px',
        borderbottomrightradius: '15px',
        bordertopleftradius: '0px',
        borderbottomleftradius: '0px',
        rightDis: '0px'
      },
      isShow: true,
      kaptchaUrl: baseUrl.default + '/kaptcha?time=' + new Date(),
      optionTitle: 'REGISTRATION',
      optionWelcome: '欢迎注册',
      optionType: LoginOptionType.REGIEST
    }
  },
  computed: {
    LoginOptionType() {
      return LoginOptionType
    },
    submitLoginStyle() {
      let style = {}
      if (this.loginUser.username && this.loginUser.password) {
        style.color = '#fff'
        style.backgroundColor = '#257B5E'
      } else {
        style.color = '#000'
        style.backgroundColor = '#dbf1e1'
      }
      return style
    },
    getCodeStyle() {
      let style = {}
      // 注册
      if (this.optionType === LoginOptionType.REGIEST) {
        if (this.regUser.phone && this.isPhone(this.regUser.phone)) {
          style.color = '#fff'
          style.backgroundColor = '#257B5E'
        } else {
          style.color = '#000'
          style.backgroundColor = '#dbf1e1'
        }
      }
      // 重置密码
      if (this.optionType === LoginOptionType.RESET) {
        if (this.resetUser.phone && this.isPhone(this.resetUser.phone)) {
          style.color = '#fff'
          style.backgroundColor = '#257B5E'
        } else {
          style.color = '#000'
          style.backgroundColor = '#dbf1e1'
        }
      }
      return style
    },
    submitRegisterStyle() {
      let style = {}
      if (
        this.regUser.username &&
        this.regUser.password &&
        this.regUser.code &&
        this.regUser.phone &&
        this.isPhone(this.regUser.phone)
      ) {
        style.color = '#fff'
        style.backgroundColor = '#257B5E'
      } else {
        style.color = '#000'
        style.backgroundColor = '#dbf1e1'
      }
      return style
    },
    submitResetStyle() {
      let style = {}
      if (
        this.resetUser.password &&
        this.resetUser.confirmPassword &&
        this.resetUser.code &&
        this.resetUser.phone &&
        this.isPhone(this.resetUser.phone)
      ) {
        style.color = '#fff'
        style.backgroundColor = '#257B5E'
      } else {
        style.color = '#000'
        style.backgroundColor = '#dbf1e1'
      }
      return style
    }
  },
  methods: {
    changeToRegiest() {
      this.styleObj.bordertoprightradius = '0px'
      this.styleObj.borderbottomrightradius = '0px'
      this.styleObj.bordertopleftradius = '15px'
      this.styleObj.borderbottomleftradius = '15px'
      this.styleObj.rightDis = '50%'
      this.isShow = !this.isShow

      this.optionTitle = 'REGISTRATION'
      this.optionWelcome = '欢迎注册'
      this.optionType = LoginOptionType.REGIEST
    },
    changeToReset() {
      this.styleObj.bordertoprightradius = '0px'
      this.styleObj.borderbottomrightradius = '0px'
      this.styleObj.bordertopleftradius = '15px'
      this.styleObj.borderbottomleftradius = '15px'
      this.styleObj.rightDis = '50%'
      this.isShow = !this.isShow

      this.optionTitle = 'RESERT'
      this.optionWelcome = '重置密码'
      this.optionType = LoginOptionType.RESET
    },
    changeToLogin() {
      this.styleObj.bordertoprightradius = '15px'
      this.styleObj.borderbottomrightradius = '15px'
      this.styleObj.bordertopleftradius = '0px'
      this.styleObj.borderbottomleftradius = '0px'
      this.styleObj.rightDis = '0px'
      this.isShow = !this.isShow
    },
    isPhone(number) {
      const reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/
      if (number.length === 11) {
        return reg.test(number)
      } else {
        return false
      }
    },
    updateKaptcha() {
      this.kaptchaUrl = baseUrl.default + '/kaptcha?time=' + new Date()
    },
    submitLogin() {
      this.loading = true
      login(this.loginUser).then(resp => {
        this.loading = false
        if (resp) {
          // 存储token
          const tokenStr = resp.data.tokenHead + resp.data.token
          window.sessionStorage.setItem('tokenStr', tokenStr)

          let path = this.$route.query.redirect
          this.$router.replace(path == '/' || path == undefined ? '/home' : path)
        }
      })
    },
    getCode() {
      let params
      // 注册
      if (this.optionType === LoginOptionType.REGIEST) {
        if (!this.regUser.phone || !this.isPhone(this.regUser.phone)) {
          return false
        }
        params = {
          phone: this.regUser.phone,
          type: SendCodeType.REGIEST
        }
      }

      // 重置密码
      if (this.optionType === LoginOptionType.RESET) {
        if (!this.resetUser.phone || !this.isPhone(this.resetUser.phone)) {
          return false
        }
        params = {
          phone: this.resetUser.phone,
          type: SendCodeType.RESET
        }
      }

      sendCode(params).then(resp => {
        if (resp) {
        }
      })
    },
    submitRegister() {
      if (
        !this.regUser.username ||
        !this.regUser.password ||
        !this.regUser.code ||
        !this.regUser.phone ||
        !this.isPhone(this.regUser.phone)
      ) {
        return false
      }

      this.loading = true
      register(this.regUser).then(resp => {
        this.loading = false
        if (resp) {
          // 存储token
          const tokenStr = resp.data.tokenHead + resp.data.token
          window.sessionStorage.setItem('tokenStr', tokenStr)

          let path = this.$route.query.redirect
          this.$router.replace(path == '/' || path == undefined ? '/home' : path)
        }
      })
    },
    submitReset() {
      if (
        !this.resetUser.password ||
        !this.resetUser.confirmPassword ||
        !this.resetUser.code ||
        !this.resetUser.phone ||
        !this.isPhone(this.resetUser.phone)
      ) {
        return false
      }
      if (this.resetUser.password !== this.resetUser.confirmPassword) {
        this.$message.error('两次输入的密码不一致哦~')
        return false
      }

      resetPass(this.resetUser).then(resp => {
        if (resp) {
          this.resetUser.phone = ''
          this.resetUser.code = ''
          this.resetUser.password = ''
          this.resetUser.confirmPassword = ''
        }
      })
    },
    openCaptcha() {
      if (!this.loginUser.username || !this.loginUser.password) {
        return false
      }

      const config = {
        // 生成接口 (必选项,必须配置, 要符合tianai-captcha默认验证码生成接口规范)
        requestCaptchaDataUrl: baseUrl.default + '/genCaptcha',
        // 验证接口 (必选项,必须配置, 要符合tianai-captcha默认验证码校验接口规范)
        validCaptchaUrl: baseUrl.default + '/checkCaptcha',
        // 验证码绑定的div块 (必选项,必须配置)
        bindEl: '#captcha-box',
        // 验证成功回调函数(必选项,必须配置)
        validSuccess: (res, c, tac) => {
          // 销毁验证码服务
          tac.destroyWindow()
          // console.log("验证成功，后端返回的数据为", res);
          // 二次验证
          this.loginUser.code = res.data.id
          // 调用具体的login方法
          this.submitLogin()
        },
        // 验证失败的回调函数(可忽略，如果不自定义 validFail 方法时，会使用默认的)
        validFail: (res, c, tac) => {
          console.log('验证码验证失败回调...')
          // 验证失败后重新拉取验证码
          tac.reloadCaptcha()
        },
        // 刷新按钮回调事件
        btnRefreshFun: (el, tac) => {
          console.log('刷新按钮触发事件...')
          tac.reloadCaptcha()
        },
        // 关闭按钮回调事件
        btnCloseFun: (el, tac) => {
          console.log('关闭按钮触发事件...')
          tac.destroyWindow()
        }
      }
      let style = {
        logoUrl: logo
      }
      window
        .initTAC('./tac', config, style)
        .then(tac => {
          tac.init() // 调用init则显示验证码
        })
        .catch(e => {
          console.log('初始化tac失败', e)
        })
    }
  }
}
</script>

<style>
/* @import '../assets/css/Login.css' */
.base {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('~@/assets/images/background.jpg') center center no-repeat;
  background-size: 100% 100%;
}

.loginAndRegist {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loginArea {
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 400px;
  width: 350px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.registArea {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 400px;
  width: 350px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showInfo {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  height: 400px;
  width: 350px;
  z-index: 2;
  top: 0;
  right: 0;
  background-image: url('~@/assets/images/welcome.jpg');
  background-size: 90%;
}

.showInfo:hover {
  background-size: 100%;
  background-position: -50px -50px;
}

.loginTitle2 {
  width: 70%;
  flex: 1;
  border-bottom: 1px solid #257b5e;
  display: flex;
  align-items: center;
  color: #257b5e;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
}

#aaa {
  transition: 0.3s linear;
}

.pwdArea {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
}

.pwdArea input {
  outline: none;
  height: 30%;
  border-radius: 13px;
  padding-left: 10px;
  font-size: 12px;
  border: 1px solid gray;
}

.pwdArea input:focus {
  border: 2px solid #257b5e;
}

.btnArea {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rigestTitle {
  width: 70%;
  flex: 1;
  color: #257b5e;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #257b5e;
}

.registForm {
  flex: 2;
  display: flex;
  flex-direction: column;
  color: #257b5e;
  font-size: 16px;
}

.registForm input {
  outline: none;
  height: 30%;
  border-radius: 13px;
  padding-left: 10px;
  font-size: 12px;
  border: 1px solid gray;
}

.registForm input:focus {
  border: 2px solid #257b5e;
}

#elselect:focus {
  border: 2px solid #257b5e;
}

.registBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.captchaBox {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
