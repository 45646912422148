<template>
  <div style="padding: 10px">
    <div style="margin-top: 15px">
      <el-select
        v-model="param.roleId"
        size="mini"
        style="width: 200px; margin-right: 20px"
        placeholder="角色"
        clearable
        filterable
        @change="
          expenditureDrawLine()
          incomeDrawLine()
        "
      >
        <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
      </el-select>

      <el-select
        v-model="param.accountBookId"
        size="mini"
        style="width: 200px; margin-right: 20px"
        placeholder="账本"
        clearable
        filterable
        @change="
          expenditureDrawLine()
          incomeDrawLine()
        "
      >
        <el-option v-for="item in accountBooks" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>

      <el-date-picker
        style="margin-right: 20px"
        size="mini"
        :picker-options="datePickerConfig"
        v-model="time"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="
          expenditureDrawLine()
          incomeDrawLine()
        "
      ></el-date-picker>
    </div>

    <el-card style="margin-top: 15px">
      <div id="expenditureChart" :style="{ width: '400px', height: '600px', display: 'inline-block' }"></div>
      <div id="incomeChart" :style="{ width: '400px', height: '600px', display: 'inline-block' }"></div>
    </el-card>
  </div>
</template>

<script>
import { roles } from '@/api/sys/role'
import {
  accountBooks,
  accountExpenditureCount,
  accountIncomeCount,
  accountTypesByCategory
} from '@/api/person/accountRecord'
import { AccountRecordCategory } from '@/constant/AccountRecord'

export default {
  name: 'AccountEcharts',
  data() {
    return {
      roles: [],
      accountBooks: [],
      time: [],
      param: {
        roleId: null,
        accountBookId: null,
        startTime: null,
        endTime: null
      },
      accountTypes: []
    }
  },
  mounted() {
    this.initRoles()
    this.initAccountBooks()
    this.initAccountTypes()
    this.expenditureDrawLine()
    this.incomeDrawLine()
  },
  methods: {
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    initAccountBooks() {
      accountBooks().then(resp => {
        if (resp) {
          this.accountBooks = resp
        }
      })
    },
    async initAccountTypes() {
      // 支出类型
      let expenditureTypes = await accountTypesByCategory(1)
      // 收入类型
      let incomeTypes = await accountTypesByCategory(2)
      if (expenditureTypes) {
        expenditureTypes.forEach(expenditureType => {
          this.accountTypes.push(expenditureType)
        })
      }
      if (incomeTypes) {
        incomeTypes.forEach(incomeType => {
          this.accountTypes.push(incomeType)
        })
      }
    },
    expenditureDrawLine() {
      // 基于准备好的dom，初始化echarts实例
      let expenditureChart = this.$root.echarts.init(document.getElementById('expenditureChart'))
      let option = {
        title: {
          text: '支出金额比例统计图',
          subtext: '金额数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: 'bottom',
          trigger: 'item'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '支出金额',
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      }

      if (this.time !== null && this.time.length === 2) {
        this.param.startTime = this.time[0]
        this.param.endTime = this.time[1]
      } else {
        this.param.startTime = null
        this.param.endTime = null
      }

      accountExpenditureCount(this.param).then(res => {
        res.forEach(item => {
          option.series[0].data.push({ name: item.name, value: item.amt })
        })
        // 绘制图表
        expenditureChart.setOption(option)
        expenditureChart.on('click', this.expenditureChartClickFunc)
      })
    },
    incomeDrawLine() {
      // 基于准备好的dom，初始化echarts实例
      let incomeChart = this.$root.echarts.init(document.getElementById('incomeChart'))
      let option = {
        title: {
          text: '收入金额比例统计图',
          subtext: '金额数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: 'bottom',
          trigger: 'item'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '收入金额',
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      }

      if (this.time !== null && this.time.length === 2) {
        this.param.startTime = this.time[0]
        this.param.endTime = this.time[1]
      } else {
        this.param.startTime = null
        this.param.endTime = null
      }

      accountIncomeCount(this.param).then(res => {
        res.forEach(item => {
          option.series[0].data.push({ name: item.name, value: item.amt })
        })
        // 绘制图表
        incomeChart.setOption(option)
        incomeChart.on('click', this.incomeChartClickFunc)
      })
    },
    expenditureChartClickFunc(param) {
      let name = param.data.name
      let accountCategoryId
      this.accountTypes.forEach(item => {
        if (name === item.typeName && AccountRecordCategory.EXPENDITURE === item.category) {
          accountCategoryId = item.id
        }
      })
      // 跳转记录页面并给过去查询参数
      this.$router.push({
        path: '/person/accountRecord',
        query: {
          accountCategoryId: accountCategoryId,
          accountCategory: AccountRecordCategory.EXPENDITURE,
          startTime: this.param.startTime,
          endTime: this.param.endTime,
          roleId: this.param.roleId,
          bookId: this.param.bookId
        }
      })
    },
    incomeChartClickFunc(param) {
      let name = param.data.name
      let accountCategoryId
      this.accountTypes.forEach(item => {
        if (name === item.typeName && AccountRecordCategory.INCOME === item.category) {
          accountCategoryId = item.id
        }
      })
      // 跳转记录页面并给过去查询参数
      this.$router.push({
        path: '/person/accountRecord',
        query: {
          accountCategoryId: accountCategoryId,
          accountCategory: AccountRecordCategory.INCOME,
          startTime: this.param.startTime,
          endTime: this.param.endTime,
          roleId: this.param.roleId,
          bookId: this.param.bookId
        }
      })
    }
  }
}
</script>

<style></style>
