<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="意志名称"
          v-model="searchHottaWill.name"
          clearable
          @keydown.enter.native="
            searchHottaWill.currentPage = 1
            searchHottaWill.size = 10
            initHottaWill()
          "
        ></el-input>
        <el-select
          v-model="searchHottaWill.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="意志类型"
          clearable
          filterable
          @change="
            searchHottaWill.currentPage = 1
            searchHottaWill.size = 10
            initHottaWill()
          "
        >
          <el-option v-for="item in hottaTypes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          @click="
            searchHottaWill.name = ''
            searchHottaWill.type = ''
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="
            searchHottaWill.currentPage = 1
            searchHottaWill.size = 10
            initHottaWill()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddHottaWillView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaWill') !== -1
          "
        >
          添加意志
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="hottaWillData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="意志名称" align="center" prop="name"></el-table-column>
        <el-table-column label="意志类型" align="center" prop="type"></el-table-column>
        <el-table-column align="center" width="200" label="两件套效果">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.descriptionsTwo" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.descriptionsTwo.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptionsTwo }}
              </span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="三件套效果">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.descriptionsThree" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.descriptionsThree.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptionsThree }}
              </span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="四件套效果">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.descriptionsFour" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.descriptionsFour.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptionsFour }}
              </span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="180" prop="createTime"></el-table-column>
        <el-table-column label="更新时间" align="center" width="180" prop="updateTime"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HottaWill') !== -1
              "
              size="mini"
              type="primary"
              @click="showUpdateHottaWillView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HottaWill') !== -1
              "
              size="mini"
              type="danger"
              @click="deleteHottaWill(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchHottaWill.currentPage"
        :page-size="searchHottaWill.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="hottaWillForm" :model="hottaWill" :rules="rules">
        <table>
          <tr>
            <el-form-item label="意志名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="hottaWill.name"
                placeholder="请输入意志名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="意志类型" prop="type">
              <el-select
                v-model="hottaWill.type"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="意志类型"
                clearable
                filterable
              >
                <el-option v-for="item in hottaTypes" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="二件套效果">
              <div id="richText1"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="三件套效果">
              <div id="richText2"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="四件套效果">
              <div id="richText3"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doHottaWill">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { addHottaWill, delHottaWill, hottaWillPageData, upHottaWill } from '@/api/hotta/will'
// wangeditor富文本
import E from 'wangeditor'
let editor1
let editor2
let editor3

export default {
  name: 'HottaWill',
  data() {
    return {
      loading: false,
      searchHottaWill: {
        name: '',
        type: '',
        currentPage: 1,
        size: 10
      },
      hottaWill: {
        id: null,
        name: '',
        type: '',
        descriptionsTwo: '',
        descriptionsThree: '',
        descriptionsFour: ''
      },
      total: 0,
      hottaWillData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入意志名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择意志类型', trigger: 'blur' }]
      },
      hottaTypes: store.getters.hottaTypes,
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initHottaWill()
  },
  created() {
    // 富文本 解决页面切换失效问题
    ;(editor1 = ''), (editor2 = ''), (editor3 = '')
  },
  methods: {
    initHottaWill() {
      this.loading = true
      hottaWillPageData(this.searchHottaWill).then(resp => {
        this.loading = false
        if (resp) {
          this.hottaWillData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchHottaWill.currentPage = page
      this.initHottaWill()
    },
    sizeChange(size) {
      this.searchHottaWill.size = size
      this.initHottaWill()
    },
    deleteHottaWill(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']该意志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delHottaWill(data.id).then(resp => {
            if (resp) {
              this.initHottaWill()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddHottaWillView() {
      this.hottaWill = {
        id: null,
        name: '',
        type: '',
        descriptionsTwo: '',
        descriptionsThree: '',
        descriptionsFour: ''
      }
      this.dialogVisible = true
      this.title = '添加意志'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        if (!editor3) {
          editor3 = new E('#richText3')
          // 配置上传图片地址
          editor3.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor3.config.uploadFileName = 'file'
          editor3.config.height = 200
          editor3.create()
        }
        // 清空内容
        editor1.txt.html('')
        editor2.txt.html('')
        editor3.txt.html('')
      })
    },
    showUpdateHottaWillView(data) {
      // 拷贝
      this.hottaWill = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑意志'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        if (!editor3) {
          editor3 = new E('#richText3')
          // 配置上传图片地址
          editor3.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor3.config.uploadFileName = 'file'
          editor3.config.height = 200
          editor3.create()
        }
        // 内容赋值
        editor1.txt.html('')
        editor2.txt.html('')
        editor3.txt.html('')
        editor1.txt.html(this.hottaWill.descriptionsTwo)
        editor2.txt.html(this.hottaWill.descriptionsThree)
        editor3.txt.html(this.hottaWill.descriptionsFour)
      })
    },
    doHottaWill() {
      if (this.hottaWill.id) {
        // 编辑
        this.$refs['hottaWillForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaWill.descriptionsTwo = editor1.txt.html()
            this.hottaWill.descriptionsThree = editor2.txt.html()
            this.hottaWill.descriptionsFour = editor3.txt.html()
            upHottaWill(this.hottaWill).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHottaWill()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['hottaWillForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaWill.descriptionsTwo = editor1.txt.html()
            this.hottaWill.descriptionsThree = editor2.txt.html()
            this.hottaWill.descriptionsFour = editor3.txt.html()
            addHottaWill(this.hottaWill).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHottaWill()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['hottaWillForm'].resetFields()
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
