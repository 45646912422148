<template>
  <div>
    <div>
      <div>
        <el-date-picker
          style="width: 400px; margin-right: 20px"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          v-model="searchMail.time"
          value-format="yyyy-MM-dd HH:mm:ss"
          clearable
          @change="
            searchMail.currentPage = 1
            searchMail.size = 10
            initMail()
          "
        ></el-date-picker>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="邮件标题"
          v-model="searchMail.title"
          clearable
          @keydown.enter.native="
            searchMail.currentPage = 1
            searchMail.size = 10
            initMail()
          "
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="发送人邮箱"
          v-model="searchMail.sendMail"
          clearable
          @keydown.enter.native="
            searchMail.currentPage = 1
            searchMail.size = 10
            initMail()
          "
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="接收人邮箱"
          v-model="searchMail.acceptMail"
          clearable
          @keydown.enter.native="
            searchMail.currentPage = 1
            searchMail.size = 10
            initMail()
          "
        ></el-input>
      </div>
      <div style="margin-top: 10px">
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetMail">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchMail.currentPage = 1
            searchMail.size = 10
            initMail()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddMailView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Mail') !== -1"
        >
          添加邮件
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="mailData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="邮件标题" align="center" width="200" prop="title"></el-table-column>
        <el-table-column label="邮件内容" width="200px" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.content" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.content.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.content }}</span>
              <!--<span>{{scope.row.content | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="发送人邮箱" align="center" width="200" prop="sendMail"></el-table-column>
        <el-table-column label="接收人邮箱" align="center" width="200" prop="acceptMail"></el-table-column>
        <el-table-column label="是否发送" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.send === 0">未发送</el-tag>
            <el-tag type="success" v-else-if="scope.row.send === 1">已发送</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" align="center" prop="roleName"></el-table-column>
        <el-table-column label="创建时间" align="center" width="200" sortable prop="createTime"></el-table-column>
        <el-table-column label="更新时间" align="center" width="200" sortable prop="updateTime"></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('Mail') !== -1 &&
                scope.row.send === 0
              "
              size="mini"
              style="padding: 3px"
              type="warning"
              @click="sendMail(scope.row)"
            >
              发送
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Mail') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateMailView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Mail') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteMail(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchMail.currentPage"
        :page-size="searchMail.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="mailForm" :model="mail" :rules="rules">
        <table>
          <tr>
            <el-form-item label="邮件标题" prop="title">
              <el-input size="mini" style="width: 200px" v-model="mail.title" placeholder="请输入邮件标题"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="发送人邮箱" prop="sendMail">
              <el-input
                disabled
                size="mini"
                style="width: 300px"
                v-model="mail.sendMail"
                class="input-with-select"
                placeholder="请输入发送人邮箱"
              >
                <el-select
                  disabled
                  v-model="mail.sendMailType"
                  placeholder="请选择"
                  size="mini"
                  slot="prepend"
                  style="width: 100px"
                >
                  <el-option label="qq.com" value="@qq.com"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="接收人邮箱" prop="acceptMail">
              <el-input
                size="mini"
                style="width: 300px"
                v-model="mail.acceptMail"
                class="input-with-select"
                placeholder="请输入发送人邮箱"
              >
                <el-select
                  v-model="mail.acceptMailType"
                  placeholder="请选择"
                  size="mini"
                  slot="prepend"
                  style="width: 100px"
                >
                  <el-option label="qq.com" value="@qq.com"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="邮件内容" prop="content">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import { addMail, delMail, mailPageData, sendMail, upMail } from '@/api/work/mail'
let editor

export default {
  name: 'Mail',
  data() {
    var validateSendMail = (rule, value, callback) => {
      if (this.mail.sendMailType === '') {
        callback(new Error('请选择'))
      } else if (this.mail.sendMail === '') {
        callback(new Error('请输入发送人邮箱'))
      } else {
        callback()
      }
    }
    var validateAccpectMail = (rule, value, callback) => {
      if (this.mail.acceptMailType === '') {
        callback(new Error('请选择'))
      } else if (this.mail.acceptMail === '') {
        callback(new Error('请输入收件人邮箱'))
      } else {
        callback()
      }
    }
    var validateContent = (rule, value, callback) => {
      if (editor.txt.html() === undefined || editor.txt.html() === '') {
        callback(new Error('请输入邮件内容'))
      } else {
        callback()
      }
    }
    return {
      mailData: [],
      loading: false,
      searchMail: {
        title: '',
        sendMail: '',
        acceptMail: '',
        time: null,
        startTime: null,
        endTime: null,
        currentPage: 1,
        size: 10
      },
      mail: {
        id: null,
        title: '',
        content: '',
        sendMail: '2841229245',
        sendMailType: '@qq.com',
        acceptMail: '',
        acceptMailType: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        title: [{ required: true, message: '请输入邮件标题', trigger: 'blur' }],
        // content: [{required: true, message: "请输入邮件内容", trigger: "blur"}],
        content: [{ validator: validateContent, trigger: 'blur' }],
        sendMail: [{ validator: validateSendMail, trigger: 'blur' }],
        acceptMail: [{ validator: validateAccpectMail, trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initMail()
  },
  methods: {
    initMail() {
      this.loading = true
      mailPageData(this.searchMail).then(resp => {
        this.loading = false
        if (resp) {
          this.mailData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchMail.currentPage = page
      this.initMail()
    },
    sizeChange(size) {
      this.searchMail.size = size
      this.initMail()
    },
    deleteMail(data) {
      this.$confirm('此操作将永久删除[' + data.title + ']邮件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMail(data.id).then(resp => {
            if (resp) {
              this.initMail()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddMailView() {
      this.mail = {
        id: null,
        title: '',
        content: '',
        sendMail: '2841229245',
        sendMailType: '@qq.com',
        acceptMail: '',
        acceptMailType: ''
      }
      this.dialogVisible = true
      this.title = '添加邮件'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateMailView(data) {
      // 拷贝
      this.mail = Object.assign({}, data)
      // 处理参数
      if (this.mail.sendMail !== null && this.mail.sendMail !== '') {
        this.mail.sendMailType = '@' + this.mail.sendMail.split('@')[1]
        this.mail.sendMail = this.mail.sendMail.split('@')[0]
      }
      if (this.mail.acceptMail !== null && this.mail.acceptMail !== '') {
        this.mail.acceptMailType = '@' + this.mail.acceptMail.split('@')[1]
        this.mail.acceptMail = this.mail.acceptMail.split('@')[0]
      }
      this.dialogVisible = true
      this.title = '编辑邮件'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.mail.content)
      })
    },
    closeDialog() {
      this.$refs['mailForm'].resetFields()
    },
    doMail() {
      if (this.mail.id) {
        this.$refs['mailForm'].validate(valid => {
          if (valid) {
            // 拼接参数
            this.mail.sendMail = this.mail.sendMail + this.mail.sendMailType
            this.mail.acceptMail = this.mail.acceptMail + this.mail.acceptMailType
            this.mail.content = editor.txt.html()
            upMail(this.mail).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMail()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['mailForm'].validate(valid => {
          if (valid) {
            // 拼接参数
            this.mail.sendMail = this.mail.sendMail + this.mail.sendMailType
            this.mail.acceptMail = this.mail.acceptMail + this.mail.acceptMailType
            this.mail.content = editor.txt.html()
            addMail(this.mail).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMail()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    resetMail() {
      this.searchMail.time = null
      this.searchMail.startTime = null
      this.searchMail.endTime = null
      this.searchMail.title = ''
      this.searchMail.sendMail = ''
      this.searchMail.acceptMail = ''
    },
    sendMail(data) {
      this.$confirm('此操作将发送[' + data.title + ']邮件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sendMail(data.id).then(resp => {
            if (resp) {
              this.initMail()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发送'
          })
        })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
