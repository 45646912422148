<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="菜单名称"
          v-model="searchMenu.name"
          clearable
          @keydown.enter.native="
            searchMenu.currentPage = 1
            searchMenu.size = 10
            initMenu()
          "
        ></el-input>
        <el-select
          v-model="searchMenu.parentId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="父级菜单"
          clearable
          filterable
          @change="
            searchMenu.currentPage = 1
            searchMenu.size = 10
            initMenu()
          "
        >
          <el-option v-for="item in parentMenu" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchMenu.name = ''
            searchMenu.parentId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchMenu.currentPage = 1
            searchMenu.size = 10
            initMenu()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddMenuView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Menu') !== -1"
        >
          添加菜单
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="menuData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column prop="id" align="center" label="菜单编号"></el-table-column>
        <el-table-column prop="name" align="center" width="200" label="菜单名称"></el-table-column>
        <el-table-column prop="parent.name" width="150" align="center" label="父级菜单"></el-table-column>
        <el-table-column align="center" width="250" label="后端路径">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.url">{{ scope.row.url }}</el-tag>
            <el-tag type="info" v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="前端路径">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.path">{{ scope.row.path }}</el-tag>
            <el-tag type="success" v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="组件">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.component">{{ scope.row.component }}</el-tag>
            <el-tag type="warning" v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="icon" width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.iconCls">{{ scope.row.iconCls }}</el-tag>
            <el-tag type="danger" v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否启用">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" label="菜单排序" sortable prop="sort">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sort">{{ scope.row.sort }}</el-tag>
            <el-tag v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Menu') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateMenuView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Menu') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteMenu(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchMenu.currentPage"
        :page-size="searchMenu.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <el-form ref="menuForm" :model="menu" :rules="rules">
        <table>
          <tr>
            <el-form-item label="菜单编号" prop="id">
              <el-input-number
                :min="0"
                size="mini"
                style="width: 200px"
                v-model="menu.id"
                :disabled="editFlag"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="菜单名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="menu.name" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="后端路径" prop="url">
              <el-input size="mini" style="width: 200px" v-model="menu.url" placeholder="请输入后端路径"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="前端路径" prop="path">
              <el-input size="mini" style="width: 200px" v-model="menu.path" placeholder="请输入前端路径"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="组件名称" prop="component">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="menu.component"
                placeholder="请输入组件名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <!--<el-form-item label="icon样式">
              <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="menu.iconCls"
                  placeholder="请输入icon样式"
              ></el-input>
            </el-form-item>-->
            <el-form-item label="icon样式">
              <i v-if="menu.iconCls" :class="menu.iconCls" style="color: #1accff; margin-right: 5px"></i>
              <el-select
                v-model="menu.iconCls"
                size="mini"
                style="width: 180px; margin-right: 20px"
                placeholder="icon样式"
                clearable
                filterable
              >
                <el-option v-for="item in icons" :key="item.name" :label="item.name" :value="item.name">
                  <i :class="item.name" style="color: #1accff; margin-right: 5px"></i>
                </el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="父级菜单" prop="parentId">
              <el-select
                v-model="menu.parentId"
                size="mini"
                style="width: 200px"
                placeholder="请选择父级菜单"
                clearable
                filterable
              >
                <el-option v-for="item in parentMenu" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="菜单排序">
              <el-input-number
                :min="0"
                :max="100"
                size="mini"
                style="width: 200px"
                v-model="menu.sort"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否启用">
              <el-switch
                v-model="menu.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="padding-top: 6px"
              ></el-switch>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { addMenu, delMenu, menuIcons, menuPageData, parentMenus, upMenu } from '@/api/sys/menu'

export default {
  name: 'Menu',
  data() {
    return {
      menuData: [],
      parentMenu: [],
      icons: [],
      loading: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchMenu: {
        name: '',
        parentId: null,
        currentPage: 1,
        size: 10
      },
      menu: {
        id: null,
        name: '',
        url: '',
        path: '',
        component: '',
        iconCls: '',
        keepAlive: true,
        requireAuth: true,
        parentId: null,
        enabled: true,
        sort: null
      },
      editFlag: false,
      dialogVisible: false,
      title: '',
      rules: {
        id: [{ required: true, message: '请输入菜单编号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入后端路径', trigger: 'blur' }],
        path: [{ required: true, message: '请输入前端路径', trigger: 'blur' }],
        component: [{ required: true, message: '请输入组件名称', trigger: 'blur' }],
        parentId: [{ required: true, message: '请选择父级菜单', trigger: 'change' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initMenu()
    this.initParentMenu()
    this.initIcons()
  },
  methods: {
    initMenu() {
      this.loading = true
      menuPageData(this.searchMenu).then(resp => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.menuData = resp.data
        }
      })
    },
    initParentMenu() {
      parentMenus().then(resp => {
        if (resp) {
          this.parentMenu = resp
        }
      })
    },
    initIcons() {
      menuIcons().then(resp => {
        if (resp) {
          this.icons = resp
        }
      })
    },
    currentChange(page) {
      this.searchMenu.currentPage = page
      this.initMenu()
    },
    sizeChange(page) {
      this.searchMenu.size = page
      this.initMenu()
    },
    deleteMenu(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMenu(data.id).then(resp => {
            if (resp) {
              this.initMenu()
              this.initParentMenu()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddMenuView(data) {
      this.menu = {
        id: null,
        name: '',
        url: '',
        path: '',
        component: '',
        iconCls: '',
        keepAlive: true,
        requireAuth: true,
        parentId: null,
        enabled: true,
        sort: null
      }
      this.title = '添加菜单'
      this.dialogVisible = true
      this.editFlag = false
    },
    showUpdateMenuView(data) {
      // 拷贝
      this.menu = Object.assign({}, data)
      this.menu.keepAlive = true
      this.menu.requireAuth = true
      this.title = '编辑菜单'
      this.dialogVisible = true
      this.editFlag = true
    },
    closeDialog() {
      this.$refs['menuForm'].resetFields()
    },
    doMenu() {
      if (this.editFlag) {
        // 编辑
        this.$refs['menuForm'].validate(valid => {
          if (valid) {
            upMenu(this.menu).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMenu()
                this.initParentMenu()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['menuForm'].validate(valid => {
          if (valid) {
            addMenu(this.menu).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMenu()
                this.initParentMenu()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
