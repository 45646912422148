<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="角色名称"
          v-model="searchGenshinRole.name"
          clearable
          @keydown.enter.native="
            searchGenshinRole.currentPage = 1
            searchGenshinRole.size = 10
            initGenshinRole()
          "
        ></el-input>
        <el-select
          v-model="searchGenshinRole.address"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色所属地区"
          clearable
          filterable
          @change="
            searchGenshinRole.currentPage = 1
            searchGenshinRole.size = 10
            initGenshinRole()
          "
        >
          <el-option v-for="item in genshinAddresses" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchGenshinRole.star"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色星级"
          clearable
          filterable
          @change="
            searchGenshinRole.currentPage = 1
            searchGenshinRole.size = 10
            initGenshinRole()
          "
        >
          <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchGenshinRole.attributes"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色属性"
          clearable
          filterable
          @change="
            searchGenshinRole.currentPage = 1
            searchGenshinRole.size = 10
            initGenshinRole()
          "
        >
          <el-option v-for="item in attributes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetGenshinRole">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchGenshinRole.currentPage = 1
            searchGenshinRole.size = 10
            initGenshinRole()
          "
        >
          搜索
        </el-button>
        <el-button type="success" size="mini" icon="el-icon-download" @click="exportData">导出数据</el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddGenshinRoleView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('GenshinRole') !== -1
          "
        >
          添加角色
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="genshinRoleData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="角色名称" align="center" prop="name" width="100"></el-table-column>
        <el-table-column label="角色所属地区" align="center" prop="address" width="100"></el-table-column>
        <el-table-column label="角色星级" align="center" width="70">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.star }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="角色属性" align="center" width="70">
          <template slot-scope="scope">
            <el-tag type="danger">{{ scope.row.attributes }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="重要加成名称" align="center" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.importantAddition" type="warning">{{ scope.row.importantAddition }}</el-tag>
            <el-tag v-else type="danger">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="武器" align="center" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.ysarms" type="success">{{ scope.row.ysarms.name }}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="圣遗物" align="center" width="250">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.ysholyRelic" type="info">{{ scope.row.ysholyRelic.name }}</el-tag>
            <span v-if="scope.row.ysholyRelic && scope.row.ysholyRelicTwo">
              +
              <el-tag type="info">{{ scope.row.ysholyRelicTwo.name }}</el-tag>
            </span>
            <span v-if="!scope.row.ysholyRelic">-</span>
          </template>
        </el-table-column>
        <el-table-column label="角色描述" align="center">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsisHtml(scope.row.remark, 15)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('GenshinRole') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateGenshinRoleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('GenshinRole') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteGenshinRole(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchGenshinRole.currentPage"
        :page-size="searchGenshinRole.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="1000px">
      <el-form ref="genshinRoleForm" :model="genshinRole" :rules="rules" label-width="135px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="角色名称" prop="name">
              <el-input
                size="mini"
                style="width: 150px"
                v-model="genshinRole.name"
                placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="角色星级" prop="star">
              <el-select
                v-model="genshinRole.star"
                size="mini"
                style="width: 150px"
                placeholder="角色星级"
                clearable
                filterable
              >
                <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="角色属性" prop="attributes">
              <el-select
                v-model="genshinRole.attributes"
                size="mini"
                style="width: 150px"
                placeholder="角色属性"
                clearable
                filterable
              >
                <el-option v-for="item in attributes" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="角色所属地区" prop="address">
              <el-select
                v-model="genshinRole.address"
                size="mini"
                style="width: 150px"
                placeholder="角色所属地区"
                clearable
                filterable
              >
                <el-option v-for="item in genshinAddresses" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="重要加成名称">
              <el-select
                v-model="genshinRole.importantAddition"
                size="mini"
                style="width: 150px"
                placeholder="重要加成名称"
                clearable
                filterable
              >
                <el-option v-for="item in entries" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="武器">
              <el-select
                v-model="genshinRole.armsId"
                size="mini"
                style="width: 150px"
                placeholder="武器"
                clearable
                filterable
              >
                <el-option v-for="item in arms" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="圣遗物">
              <el-select
                v-model="genshinRole.holyRelicId"
                size="mini"
                style="width: 150px"
                placeholder="圣遗物"
                clearable
                filterable
                @change="changeUpTwo"
              >
                <el-option v-for="item in holyRelics" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="genshinRole.holyRelicId">
            <el-form-item label="二件套">
              <el-select
                v-model="genshinRole.holyRelicTwoId"
                size="mini"
                style="width: 150px"
                placeholder="二件套"
                clearable
                filterable
              >
                <el-option v-for="item in twoHolyRelics" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="角色描述">
            <div id="richText"></div>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doGenshinRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  addGenshinRole,
  delGenshinRole,
  upGenshinRole,
  genshinRoleExport,
  genshinRolePageData
} from '@/api/genshin/role'
import { genshinArms } from '@/api/genshin/arms'
import { genshinHolyRelics } from '@/api/genshin/holyRelic'
let editor

export default {
  name: 'GenshinRole',
  data() {
    return {
      genshinRoleData: [],
      loading: false,
      searchGenshinRole: {
        name: '',
        address: '',
        star: null,
        attributes: '',
        currentPage: 1,
        size: 10
      },
      genshinRole: {
        id: null,
        name: '',
        address: '',
        star: '',
        attributes: '',
        armsId: null,
        holyRelicId: null,
        holyRelicTwoId: null,
        importantAddition: '',
        remark: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入角色所属地区', trigger: 'blur' }],
        star: [{ required: true, message: '请选择角色星级', trigger: 'blur' }],
        attributes: [{ required: true, message: '请选择角色属性', trigger: 'blur' }]
      },
      arms: [],
      holyRelics: [],
      twoHolyRelics: [],
      stars: store.getters.stars,
      entries: store.getters.entries,
      attributes: store.getters.attributes,
      genshinAddresses: store.getters.genshinAddresses,
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initGenshinRole()
    this.initArms()
    this.initYsholyRelic()
  },
  methods: {
    initGenshinRole() {
      this.loading = true
      genshinRolePageData(this.searchGenshinRole).then(resp => {
        this.loading = false
        if (resp) {
          this.genshinRoleData = resp.data
          this.total = resp.total
        }
      })
    },
    initArms() {
      genshinArms().then(resp => {
        if (resp) {
          this.arms = resp
        }
      })
    },
    initYsholyRelic() {
      genshinHolyRelics().then(resp => {
        if (resp) {
          this.holyRelics = resp
        }
      })
    },
    currentChange(page) {
      this.searchGenshinRole.currentPage = page
      this.initGenshinRole()
    },
    sizeChange(size) {
      this.searchGenshinRole.size = size
      this.initGenshinRole()
    },
    deleteGenshinRole(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delGenshinRole(data.id).then(resp => {
            if (resp) {
              this.initGenshinRole()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddGenshinRoleView() {
      this.genshinRole = {
        id: null,
        name: '',
        address: '',
        star: '',
        attributes: '',
        armsId: null,
        holyRelicId: null,
        holyRelicTwoId: null,
        importantAddition: '',
        remark: ''
      }
      this.title = '添加角色'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateGenshinRoleView(data) {
      // 拷贝
      this.genshinRole = Object.assign({}, data)
      this.title = '编辑角色'
      this.changeUpTwo()
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.genshinRole.remark)
      })
    },
    doGenshinRole() {
      if (this.genshinRole.id) {
        this.$refs['genshinRoleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.genshinRole.remark = editor.txt.html()
            upGenshinRole(this.genshinRole).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initGenshinRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs['genshinRoleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.genshinRole.remark = editor.txt.html()
            addGenshinRole(this.genshinRole).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initGenshinRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    exportData() {
      genshinRoleExport()
    },
    resetGenshinRole() {
      this.searchGenshinRole.name = ''
      this.searchGenshinRole.address = ''
      this.searchGenshinRole.star = null
      this.searchGenshinRole.attributes = ''
    },
    changeUpTwo() {
      if (this.genshinRole.holyRelicId) {
        // 先为空 然后剔除已选择
        this.twoHolyRelics = []
        this.holyRelics.forEach(item => {
          if (item.id !== this.genshinRole.holyRelicId) {
            let data = {
              id: item.id,
              name: item.name
            }
            this.twoHolyRelics.push(data)
          }
        })
      } else {
        this.genshinRole.holyRelicTwoId = null
        this.twoHolyRelics = []
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 150px;
  text-align: right;
}
</style>
