const person = {
	state: {
		accountCategories: [
			{ code: 1, desc: "支出" },
			{ code: 2, desc: "收入" },
		],
	},
	mutations: {},
	actions: {},
};

export default person;