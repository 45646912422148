<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="模组名称"
          v-model="searchModule.name"
          clearable
          @keydown.enter.native="
            searchModule.currentPage = 1
            searchModule.size = 10
            initModule()
          "
        ></el-input>
        <el-select
          v-model="searchModule.positionId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="所属定位"
          clearable
          filterable
          @change="
            searchModule.currentPage = 1
            searchModule.size = 10
            initModule()
          "
        >
          <el-option v-for="item in positions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select
          v-model="searchModule.roleId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="所属角色"
          clearable
          filterable
          @change="
            searchModule.currentPage = 1
            searchModule.size = 10
            initModule()
          "
        >
          <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetArknightsModule">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchModule.currentPage = 1
            searchModule.size = 10
            initModule()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddModuleView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('ArknightsModule') !== -1
          "
        >
          添加模组
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="moduleData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column prop="name" width="150" align="center" label="模组名称"></el-table-column>
        <el-table-column prop="level" sortable width="100" align="center" label="模组等级"></el-table-column>
        <el-table-column label="所属定位" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.position.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属角色" align="center">
          <template slot-scope="scope">
            <el-tag type="danger">{{ scope.row.role.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="模组属性加成">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.attributeAddition && toText(scope.row.attributeAddition).length > 10"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.attributeAddition.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.attributeAddition }}
              </span>
              <span
                v-if="scope.row.attributeAddition"
                v-html="$options.filters.ellipsisHtml(scope.row.attributeAddition)"
              ></span>
            </el-tooltip>
            <span
              v-else-if="scope.row.attributeAddition"
              v-html="toText(scope.row.attributeAddition)"
              slot="content"
            ></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="模组天赋加成">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.talentAddition && toText(scope.row.talentAddition).length > 10"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.talentAddition.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.talentAddition }}
              </span>
              <span
                v-if="scope.row.talentAddition"
                v-html="$options.filters.ellipsisHtml(scope.row.talentAddition)"
              ></span>
            </el-tooltip>
            <span v-else-if="scope.row.talentAddition" v-html="toText(scope.row.talentAddition)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="模组特性加成">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.characteristicAddition && toText(scope.row.characteristicAddition).length > 10"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.characteristicAddition.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.characteristicAddition }}
              </span>
              <span
                v-if="scope.row.characteristicAddition"
                v-html="$options.filters.ellipsisHtml(scope.row.characteristicAddition)"
              ></span>
            </el-tooltip>
            <span
              v-else-if="scope.row.characteristicAddition"
              v-html="toText(scope.row.characteristicAddition)"
              slot="content"
            ></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" label="是否在使用">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isUse">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsModule') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateModuleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsModule') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteModule(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchModule.currentPage"
        :page-size="searchModule.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="moduleForm" :model="module" :rules="rules" label-width="125px">
        <table>
          <tr>
            <el-form-item label="模组名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="module.name" placeholder="请输入模组名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模组等级" prop="level">
              <el-input-number
                :min="1"
                :max="3"
                size="mini"
                style="width: 200px"
                v-model="module.level"
                placeholder="请输入模组等级"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="所属定位" prop="positionId">
              <el-select
                v-model="module.positionId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="所属定位"
                clearable
                filterable
              >
                <el-option v-for="item in positions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="所属角色" prop="roleId">
              <el-select
                v-model="module.roleId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="所属角色"
                clearable
                filterable
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模组属性加成" prop="attributeAddition">
              <div id="richText1"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模组天赋加成" prop="talentAddition">
              <div id="richText2"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模组特性加成" prop="characteristicAddition">
              <div id="richText3"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doModule">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  addArknightsModule,
  delArknightsModule,
  arknightsModulePageData,
  upArknightsModule
} from '@/api/arknights/module'
import { arknightsPositions } from '@/api/arknights/position'
import { arknightsRoles } from '@/api/arknights/role'
let editor1
let editor2
let editor3

export default {
  name: 'ArknightsModule',
  data() {
    return {
      moduleData: [],
      positions: [],
      roles: [],
      loading: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchModule: {
        name: '',
        positionId: null,
        roleId: null,
        currentPage: 1,
        size: 10
      },
      module: {
        id: null,
        name: '',
        level: null,
        positionId: null,
        roleId: null,
        attributeAddition: '',
        talentAddition: '',
        characteristicAddition: ''
      },
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入模组名称', trigger: 'blur' }],
        level: [{ required: true, message: '请输入模组等级', trigger: 'blur' }],
        positionId: [{ required: true, message: '请选择所属定位', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择所属角色', trigger: 'change' }],
        attributeAddition: [{ required: true, message: '请输入模组属性加成', trigger: 'blur' }],
        talentAddition: [{ required: true, message: '请输入模组天赋加成', trigger: 'blur' }],
        characteristicAddition: [{ required: true, message: '请输入模组特性加成', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor1 = ''
    editor2 = ''
    editor3 = ''
  },
  computed: {},
  mounted() {
    this.initModule()
    this.initAllPositions()
    this.initAllRoles()
  },
  methods: {
    initModule() {
      this.loading = true
      arknightsModulePageData(this.searchModule).then(resp => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.moduleData = resp.data
        }
      })
    },
    initAllPositions() {
      arknightsPositions().then(resp => {
        if (resp) {
          this.positions = resp
        }
      })
    },
    initAllRoles() {
      arknightsRoles().then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    currentChange(page) {
      this.searchModule.currentPage = page
      this.initModule()
    },
    sizeChange(page) {
      this.searchModule.size = page
      this.initModule()
    },
    deleteModule(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']模组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delArknightsModule(data.id).then(resp => {
            if (resp) {
              this.initModule()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddModuleView(data) {
      this.module = {
        id: null,
        name: '',
        level: null,
        positionId: null,
        roleId: null,
        attributeAddition: '',
        talentAddition: '',
        characteristicAddition: ''
      }
      this.title = '添加模组'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        if (!editor3) {
          editor3 = new E('#richText3')
          // 配置上传图片地址
          editor3.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor3.config.uploadFileName = 'file'
          editor3.config.height = 200
          editor3.create()
        }
        // 清空内容
        editor1.txt.html('')
        editor2.txt.html('')
        editor3.txt.html('')
      })
    },
    showUpdateModuleView(data) {
      // 拷贝
      this.module = Object.assign({}, data)
      this.title = '编辑模组'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        if (!editor3) {
          editor3 = new E('#richText3')
          // 配置上传图片地址
          editor3.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor3.config.uploadFileName = 'file'
          editor3.config.height = 200
          editor3.create()
        }
        // 内容赋值
        editor1.txt.html('')
        editor2.txt.html('')
        editor3.txt.html('')
        editor1.txt.html(this.module.attributeAddition)
        editor2.txt.html(this.module.talentAddition)
        editor3.txt.html(this.module.characteristicAddition)
      })
    },
    closeDialog() {
      this.$refs['moduleForm'].resetFields()
    },
    doModule() {
      if (this.module.id) {
        // 编辑
        this.$refs['moduleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.module.attributeAddition = editor1.txt.html()
            this.module.talentAddition = editor2.txt.html()
            this.module.characteristicAddition = editor3.txt.html()
            upArknightsModule(this.module).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initModule()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['moduleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.module.attributeAddition = editor1.txt.html()
            this.module.talentAddition = editor2.txt.html()
            this.module.characteristicAddition = editor3.txt.html()
            addArknightsModule(this.module).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initModule()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    resetArknightsModule() {
      this.searchModule.name = ''
      this.searchModule.positionId = null
      this.searchModule.roleId = null
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
