<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="拟态名称"
          v-model="searchHottaRole.name"
          clearable
          @keydown.enter.native="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        ></el-input>
        <el-select
          v-model="searchHottaRole.sex"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="拟态性别"
          clearable
          filterable
          @change="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          <el-option v-for="item in sexs" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchHottaRole.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="等级类型"
          clearable
          filterable
          @change="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          <el-option v-for="item in hottaTypes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchHottaRole.star"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="拟态星级"
          clearable
          filterable
          @change="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          <el-option v-for="item in hottaStars" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="武器名称"
          v-model="searchHottaRole.armsName"
          clearable
          @keydown.enter.native="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        ></el-input>
        <el-select
          v-model="searchHottaRole.armsAttributes"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器属性"
          clearable
          filterable
          @change="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          <el-option
            v-for="item in armsAttributesData"
            :key="item.code"
            :label="item.desc"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select
          v-model="searchHottaRole.armsFunction"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器性能"
          clearable
          filterable
          @change="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          <el-option
            v-for="item in armsFunctionData"
            :key="item.code"
            :label="item.desc"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" @click="resetHottaRole">重置</el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="
            searchHottaRole.currentPage = 1
            searchHottaRole.size = 10
            initHottaRole()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddHottaRoleView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HottaRole') !== -1
          "
        >
          添加拟态
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="hottaRoleData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="拟态名称" align="center" prop="name"></el-table-column>
        <el-table-column label="拟态性别" align="center" prop="sex"></el-table-column>
        <el-table-column label="拟态等级" align="center" prop="level"></el-table-column>
        <el-table-column label="等级类型" align="center" prop="type"></el-table-column>
        <el-table-column label="拟态星级" align="center" prop="star"></el-table-column>
        <el-table-column label="武器名称" align="center" prop="armsName"></el-table-column>
        <el-table-column label="武器属性" align="center" prop="armsAttributesName"></el-table-column>
        <el-table-column label="武器性能" align="center" prop="armsFunctionName"></el-table-column>
        <el-table-column label="破防能力值" align="center" width="100" prop="armsBreak"></el-table-column>
        <el-table-column label="充能能力值" align="center" width="100" prop="armsCharging"></el-table-column>
        <el-table-column align="center" width="200" label="备注">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="180" prop="createTime"></el-table-column>
        <el-table-column label="更新时间" align="center" width="180" prop="updateTime"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HottaRole') !== -1
              "
              size="mini"
              type="primary"
              @click="showUpdateHottaRoleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HottaRole') !== -1
              "
              size="mini"
              type="danger"
              @click="deleteHottaRole(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchHottaRole.currentPage"
        :page-size="searchHottaRole.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="hottaRoleForm" :model="hottaRole" :rules="rules">
        <table>
          <tr>
            <el-form-item label="拟态名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="hottaRole.name"
                placeholder="请输入拟态名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="拟态性别" prop="sex">
              <el-select
                v-model="hottaRole.sex"
                size="mini"
                style="width: 200px"
                placeholder="拟态性别"
                clearable
                filterable
              >
                <el-option v-for="item in sexs" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="拟态等级" prop="level">
              <el-input-number
                size="mini"
                :min="1"
                style="width: 200px"
                v-model="hottaRole.level"
                placeholder="请输入拟态等级"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="拟态类型" prop="type">
              <el-select
                v-model="hottaRole.type"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="拟态类型"
                clearable
                filterable
              >
                <el-option v-for="item in hottaTypes" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="拟态星级" prop="star">
              <el-select
                v-model="hottaRole.star"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="拟态星级"
                clearable
                filterable
              >
                <el-option v-for="item in hottaStars" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器名称" prop="armsName">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="hottaRole.armsName"
                placeholder="请输入武器名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器属性" prop="armsAttributes">
              <el-select
                v-model="hottaRole.armsAttributes"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器属性"
                clearable
                filterable
              >
                <el-option
                  v-for="item in armsAttributesData"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器性能" prop="armsFunction">
              <el-select
                v-model="hottaRole.armsFunction"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器性能"
                clearable
                filterable
              >
                <el-option
                  v-for="item in armsFunctionData"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="破防能力值" prop="armsBreak">
              <el-input-number
                size="mini"
                :min="0"
                style="width: 200px"
                v-model="hottaRole.armsBreak"
                placeholder="请输入破防能力值"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="充能能力值" prop="armsCharging">
              <el-input-number
                size="mini"
                :min="0"
                style="width: 200px"
                v-model="hottaRole.armsCharging"
                placeholder="请输入充能能力值"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="备注">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doHottaRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import {
  addHottaRole,
  delHottaRole,
  htArmsAttributes,
  htArmsFunction,
  hottaRolePageData,
  upHottaRole
} from '@/api/hotta/role'
import E from 'wangeditor'
let editor

export default {
  name: 'HottaRole',
  data() {
    return {
      loading: false,
      searchHottaRole: {
        name: '',
        sex: '',
        type: '',
        star: null,
        armsName: '',
        armsAttributes: null,
        armsFunction: null,
        currentPage: 1,
        size: 10
      },
      hottaRole: {
        id: null,
        name: '',
        sex: '',
        type: '',
        level: null,
        star: null,
        armsName: '',
        armsAttributes: null,
        armsFunction: null,
        armsBreak: '',
        armsCharging: '',
        remark: ''
      },
      total: 0,
      hottaRoleData: [],
      armsAttributesData: [],
      armsFunctionData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入拟态名称', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择拟态性别', trigger: 'blur' }],
        level: [{ required: true, message: '请输入拟态等级', trigger: 'blur' }],
        type: [{ required: true, message: '请选择拟态类型', trigger: 'blur' }],
        star: [{ required: true, message: '请选择拟态星级', trigger: 'blur' }],
        armsName: [{ required: true, message: '请输入武器名称', trigger: 'blur' }],
        armsAttributes: [{ required: true, message: '请选择武器属性', trigger: 'blur' }],
        armsFunction: [{ required: true, message: '请选择武器性能', trigger: 'blur' }],
        armsBreak: [{ required: true, message: '请输入破防能力值', trigger: 'blur' }],
        armsCharging: [{ required: true, message: '请输入充能能力值', trigger: 'blur' }]
      },
      sexs: store.getters.sexs,
      roleButton: store.getters.currentRole,
      hottaTypes: store.getters.hottaTypes,
      hottaStars: store.getters.hottaStars
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initHottaRole()
    this.initArmsAttributes()
    this.initArmsFunction()
  },
  methods: {
    initArmsAttributes() {
      this.loading = true
      htArmsAttributes().then(resp => {
        this.loading = false
        if (resp) {
          this.armsAttributesData = resp
        }
      })
    },
    initArmsFunction() {
      this.loading = true
      htArmsFunction().then(resp => {
        this.loading = false
        if (resp) {
          this.armsFunctionData = resp
        }
      })
    },
    initHottaRole() {
      this.loading = true
      hottaRolePageData(this.searchHottaRole).then(resp => {
        this.loading = false
        if (resp) {
          this.hottaRoleData = resp.data
          this.total = resp.total
        }
      })
    },
    resetHottaRole() {
      this.searchHottaRole = {
        name: '',
        sex: '',
        type: '',
        star: null,
        armsName: '',
        armsAttributes: null,
        armsFunction: null
      }
    },
    currentChange(page) {
      this.searchHottaRole.currentPage = page
      this.initHottaRole()
    },
    sizeChange(size) {
      this.searchHottaRole.size = size
      this.initHottaRole()
    },
    deleteHottaRole(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']该拟态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delHottaRole(data.id).then(resp => {
            if (resp) {
              this.initHottaRole()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    closeDialog() {
      this.$refs['hottaRoleForm'].resetFields()
    },
    showAddHottaRoleView() {
      this.hottaRole = {
        id: null,
        name: '',
        sex: '',
        type: '',
        level: null,
        star: null,
        armsName: '',
        armsAttributes: null,
        armsFunction: null,
        armsBreak: '',
        armsCharging: '',
        remark: ''
      }
      this.dialogVisible = true
      this.title = '添加拟态武器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.config.height = 200
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateHottaRoleView(data) {
      // 拷贝
      this.hottaRole = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑拟态武器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.config.height = 200
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.hottaRole.remark)
      })
    },
    doHottaRole() {
      if (this.hottaRole.id) {
        // 编辑
        this.$refs['hottaRoleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaRole.remark = editor.txt.html()
            upHottaRole(this.hottaRole).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHottaRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['hottaRoleForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.hottaRole.remark = editor.txt.html()
            addHottaRole(this.hottaRole).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHottaRole()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
