import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取角色对应的模组
export function arknightsModuleByRoleId(id) {
	return getRequest("/arknights/module/getByRole/" + id);
}

// 明日方舟模组记录列表
export function arknightsModulePageData(params = {}) {
	return postRequest("/arknights/module/getPageData", params);
}

// 删除明日方舟模组
export function delArknightsModule(id) {
	return deleteRequest("/arknights/module/" + id);
}

// 添加明日方舟模组
export function addArknightsModule(params = {}) {
	return postRequest("/arknights/module", params);
}

// 更新明日方舟模组
export function upArknightsModule(params = {}) {
	return putRequest("/arknights/module", params);
}