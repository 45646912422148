<template>
  <div>
    <div class="container">
      <el-row :gutter="12">
        <el-col :span="8">
          <el-card v-if="appDownloadUrl" shadow="hover">
            <p>
              <span style="font-weight: bolder">当前版本:</span>
              <span style="color: #db6fd2">{{ version }}</span>
            </p>
            <p>
              <span style="font-weight: bolder">版本描述:</span>
              <span style="color: #db6fd2; font-size: 16px" v-html="remark"></span>
            </p>
            <el-collapse>
              <el-collapse-item title="App二维码（点击打开查看）" name="1">
                <vue-qr :logoSrc="imageUrl" :text="appDownloadUrl" :size="250"></vue-qr>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { appVersion } from '@/api/login'

export default {
  name: 'AppQr',
  data() {
    return {
      imageUrl: require('../../assets/images/xrg-app.png'),
      appDownloadUrl: '',
      version: '',
      remark: ''
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getAppDownloadUrl()
  },
  methods: {
    getAppDownloadUrl() {
      appVersion({ source: 1 }).then((resp) => {
        if (resp) {
          this.version = resp.version
          this.remark = resp.remark
          this.appDownloadUrl = resp.url
        }
      })
    }
  }
}
</script>

<style>
.container {
  margin-top: 60px;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
