const general = {
	state: {
		stars: [3, 4, 5],
		steps: [1, 2, 3, 4, 5],
		sexs: ["男", "女"],
	},
	mutations: {},
	actions: {},
};

export default general;