import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取全部语言类型
export function languages() {
	return getRequest("/work/translate/getLanguages");
}

// 文本翻译
export function text(params = {}) {
	return postRequest("/work/translate/text", params);
}

// 翻译记录列表
export function translateRecords(params = {}) {
	return postRequest("/work/translate/getRecordList", params);
}

// 删除记录
export function delTranslate(id) {
	return deleteRequest("/work/translate/" + id);
}