import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import Role from "@/views/sys/Role";
import Level from "@/views/sys/Level";
import XrgRole from "../views/xrg/Role";
import XrgLevel from "../views/xrg/Level";
import XrgCountry from "../views/xrg/Country";
import XrgArea from "../views/xrg/Area";
import XrgEcharts from "../views/xrg/XrgEcharts";
import XrgMap from "../views/xrg/Map";
import GenshinRole from "../views/genshin/GenshinRole";
import GenshinArms from "../views/genshin/GenshinArms";
import GenshinHolyRelic from "../views/genshin/GenshinHolyRelic";
import GenshinEcharts from "../views/genshin/GenshinEcharts.vue";
import GenshinMaterials from "../views/genshin/GenshinMaterials";
import FriendEcharts from "../views/record/FriendEcharts";
import Friend from "../views/record/Friend";
import Novel from "../views/record/Novel";
import Anime from "../views/record/Anime";
import DayRecord from "../views/work/DayRecord";
import Game from "../views/record/Game";
import Note from "../views/work/Note";
import Luckdraw from "../views/activity/Luckdraw";
import Permiss from "../views/sys/Permiss";
import Menu from "../views/sys/Menu";
import Version from "../views/sys/Version";
import MessageNotification from "../views/sys/MessageNotification";
import ArknightsEcharts from "@/views/arknights/ArknightsEcharts";
import ArknightsRole from "@/views/arknights/ArknightsRole";
import ArknightsPosition from "@/views/arknights/ArknightsPosition";
import ArknightsModule from "@/views/arknights/ArknightsModule";
import ArknightsMaterials from "@/views/arknights/ArknightsMaterials";
import ArknightsArea from "@/views/arknights/ArknightsArea";
import UserInfo from "@/views/UserInfo";
import Files from "@/views/other/Files";
import Mail from "@/views/work/Mail.vue";
import AccountEcharts from "@/views/person/AccountEcharts.vue";
import AccountPlan from "@/views/person/AccountPlan.vue";
import AccountRecord from "@/views/person/AccountRecord.vue";
import PersonNote from "@/views/person/PersonNote.vue";
import PersonPass from "@/views/person/PersonPass.vue";
import PersonMessageNotification from "@/views/person/PersonMessageNotification.vue";
import Translate from "@/views/work/Translate.vue";
import Music from "@/views/record/Music.vue";
import Query from "@/views/other/Query.vue";
import Im from "@/views/other/Im.vue";
import Weather from "@/views/other/Weather.vue";
import HottaEquipped from "@/views/hotta/HottaEquipped.vue";
import HottaRole from "@/views/hotta/HottaRole.vue";
import HottaWill from "@/views/hotta/HottaWill.vue";
import StarRailDestiny from "@/views/star/StarRailDestiny.vue";
import StarRailLightAwl from "@/views/star/StarRailLightAwl.vue";
import StarRailMaterials from "@/views/star/StarRailMaterials.vue";
import StarRailRelic from "@/views/star/StarRailRelic.vue";
import StarRailRole from "@/views/star/StarRailRole.vue";
import StarRailTrack from "@/views/star/StarRailTrack.vue";
import StarRailEcharts from "@/views/star/StarRailEcharts.vue";
import McRecord from "@/views/mc/McRecord.vue";
import PermissionRelevance from "@/views/permission/PermissionRelevance.vue";
import PermissionRecord from "@/views/permission/PermissionRecord.vue";
import Palu from "@/views/pl/Palu.vue";
import HomeModule from "@/views/sys/HomeModule.vue";
import ModulePermiss from "@/views/sys/ModulePermiss.vue";
import Login2 from "../views/Login2";
import AppQr from "@/views/other/AppQr.vue";
import AuditLog from "@/views/audit/AuditLog.vue";

Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalReplace.call(this, location, onResolve, onReject);
	return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
	/*{
	  path: "/",
	  name: "Login",
	  component: Login,
	  hidden: true
	},*/
	{
		path: "/",
		name: "Login2",
		component: Login2,
		hidden: true,
	},
	{
		path: "/home",
		name: "用户管理",
		component: Home,
		children: [{
				path: "/sys/role",
				name: "角色列表",
				component: Role,
			},
			{
				path: "/sys/level",
				name: "等级列表",
				component: Level,
			},
		],
	},
	{
		path: "/home",
		name: "个人管理",
		component: Home,
		children: [{
				path: "/person/accountRecord",
				name: "账单列表",
				component: AccountRecord,
			},
			{
				path: "/person/accountEcharts",
				name: "账单图表",
				component: AccountEcharts,
			},
			{
				path: "/person/accountPlan",
				name: "月计划配置",
				component: AccountPlan,
			},
			{
				path: "/person/note",
				name: "笔记列表",
				component: PersonNote,
			},
			{
				path: "/person/pass",
				name: "密码记录",
				component: PersonPass,
			},
			{
				path: "/person/messageNotification",
				name: "消息记录",
				component: PersonMessageNotification,
			},
		],
	},
	{
		path: "/home",
		name: "小人国",
		component: Home,
		children: [{
				path: "/xrg/role",
				name: "角色列表",
				component: XrgRole,
			},
			{
				path: "/xrg/level",
				name: "等级列表",
				component: XrgLevel,
			},
			{
				path: "/xrg/country",
				name: "国家列表",
				component: XrgCountry,
			},
			{
				path: "/xrg/area",
				name: "地区列表",
				component: XrgArea,
			},
			{
				path: "/xrg/xrgEcharts",
				name: "xrg图表",
				component: XrgEcharts,
			},
			{
				path: "/xrg/map",
				name: "地图",
				component: XrgMap,
			},
		],
	},
	{
		path: "/home",
		name: "记录管理",
		component: Home,
		children: [{
				path: "/record/friendEcharts",
				name: "朋友图表",
				component: FriendEcharts,
			},
			{
				path: "/record/friend",
				name: "朋友列表",
				component: Friend,
			},
			{
				path: "/record/novel",
				name: "书籍列表",
				component: Novel,
			},
			{
				path: "/record/anime",
				name: "番剧列表",
				component: Anime,
			},
			{
				path: "/record/game",
				name: "游戏列表",
				component: Game,
			},
			{
				path: "/record/music",
				name: "音乐列表",
				component: Music,
			},
		],
	},
	{
		path: "/home",
		name: "常用管理",
		component: Home,
		children: [{
				path: "/work/dayRecord",
				name: "日记列表",
				component: DayRecord,
			},
			{
				path: "/work/note",
				name: "笔记列表",
				component: Note,
			},
			{
				path: "/work/mail",
				name: "邮件列表",
				component: Mail,
			},
			{
				path: "/work/translate",
				name: "文本翻译",
				component: Translate,
			},
		],
	},
	{
		path: "/home",
		name: "活动管理",
		component: Home,
		children: [{
			path: "/activity/luckdraw",
			name: "抽奖活动",
			component: Luckdraw,
		}, ],
	},
	{
		path: "/home",
		name: "审批管理",
		component: Home,
		children: [{
				path: "/permission/permissionRelevance",
				name: "审批关联",
				component: PermissionRelevance,
			},
			{
				path: "/permission/permissionRecord",
				name: "审批记录",
				component: PermissionRecord,
			},
		],
	},
	{
		path: "/home",
		name: "系统管理",
		component: Home,
		children: [{
				path: "/sys/permiss",
				name: "权限组管理",
				component: Permiss,
			},
			{
				path: "/sys/menu",
				name: "菜单管理",
				component: Menu,
			},
			{
				path: "/sys/version",
				name: "版本管理",
				component: Version,
			},
			{
				path: "/sys/messageNotification",
				name: "消息推送",
				component: MessageNotification,
			},
			{
				path: "/sys/homeModule",
				name: "模块管理",
				component: HomeModule,
			},
			{
				path: "/sys/modulePermiss",
				name: "模块权限",
				component: ModulePermiss,
			},
		],
	},
	{
		path: "/home",
		name: "原神管理",
		component: Home,
		children: [{
				path: "/genshin/genshinEcharts",
				name: "角色图表",
				component: GenshinEcharts,
			},
			{
				path: "/genshin/genshinRole",
				name: "角色列表",
				component: GenshinRole,
			},
			{
				path: "/genshin/genshinArms",
				name: "武器列表",
				component: GenshinArms,
			},
			{
				path: "/genshin/genshinHolyRelic",
				name: "圣遗物列表",
				component: GenshinHolyRelic,
			},
			{
				path: "/genshin/genshinMaterials",
				name: "材料列表",
				component: GenshinMaterials,
			},
		],
	},
	{
		path: "/home",
		name: "方舟管理",
		component: Home,
		children: [{
				path: "/arknights/arknightsEcharts",
				name: "角色图表",
				component: ArknightsEcharts,
			},
			{
				path: "/arknights/arknightsRole",
				name: "角色列表",
				component: ArknightsRole,
			},
			{
				path: "/arknights/arknightsPosition",
				name: "定位列表",
				component: ArknightsPosition,
			},
			{
				path: "/arknights/arknightsModule",
				name: "模组列表",
				component: ArknightsModule,
			},
			{
				path: "/arknights/arknightsMaterials",
				name: "材料列表",
				component: ArknightsMaterials,
			},
			{
				path: "/arknights/arknightsArea",
				name: "地区列表",
				component: ArknightsArea,
			},
		],
	},
	{
		path: "/home",
		name: "崩铁管理",
		component: Home,
		children: [{
				path: "/star/starRailDestiny",
				name: "命途列表",
				component: StarRailDestiny,
			},
			{
				path: "/star/starRailLightAwl",
				name: "光锥列表",
				component: StarRailLightAwl,
			},
			{
				path: "/star/starRailMaterials",
				name: "材料列表",
				component: StarRailMaterials,
			},
			{
				path: "/star/starRailRelic",
				name: "遗器列表",
				component: StarRailRelic,
			},
			{
				path: "/star/starRailRole",
				name: "角色列表",
				component: StarRailRole,
			},
			{
				path: "/star/starRailTrack",
				name: "行迹列表",
				component: StarRailTrack,
			},
			{
				path: "/star/starRailEcharts",
				name: "角色图表",
				component: StarRailEcharts,
			},
		],
	},
	{
		path: "/home",
		name: "MC管理",
		component: Home,
		children: [{
			path: "/mc/mcRecord",
			name: "MC记录",
			component: McRecord,
		}, ],
	},
	{
		path: "/home",
		name: "通用管理",
		component: Home,
		children: [{
				path: "/other/files",
				name: "文件列表",
				component: Files,
			},
			{
				path: "/other/query",
				name: "openai查询",
				component: Query,
			},
			{
				path: "/other/im",
				name: "聊天社区",
				component: Im,
			},
			{
				path: "/other/weather",
				name: "天气服务",
				component: Weather,
			},
			{
				path: "/other/appQr",
				name: "App下载",
				component: AppQr,
			},
		],
	},
	{
		path: "/home",
		name: "幻塔管理",
		component: Home,
		children: [{
				path: "/hotta/hottaEquipped",
				name: "装备列表",
				component: HottaEquipped,
			},
			{
				path: "/hotta/hottaRole",
				name: "拟态武器",
				component: HottaRole,
			},
			{
				path: "/hotta/hottaWill",
				name: "意志列表",
				component: HottaWill,
			},
		],
	},
	{
		path: "/home",
		name: "幻兽帕鲁",
		component: Home,
		children: [{
			path: "/pl/palu",
			name: "帕鲁列表",
			component: Palu,
		}, ],
	},
	{
		path: "/home",
		name: "审计管理",
		component: Home,
		children: [{
			path: "/audit/auditLog",
			name: "审计日志",
			component: AuditLog,
		}, ],
	},
	{
		path: "/home",
		name: "个人中心",
		component: Home,
		children: [{
			path: "/userInfo",
			name: "个人中心",
			component: UserInfo,
		}, ],
	},
];

const router = new VueRouter({
	routes,
});

export default router;