import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import star from "./modules/star";
import general from "./modules/general";
import hotta from "./modules/hotta";
import arknights from "./modules/arknights";
import permission from "./modules/permission";
import person from "./modules/person";
import pl from "./modules/pl";
import record from "./modules/record";
import sys from "./modules/sys";
import getters from "./getters";
import genshin from "./modules/genshin";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		user,
		star,
		general,
		hotta,
		arknights,
		permission,
		person,
		pl,
		record,
		sys,
		genshin,
	},
	getters,
});

export default store;