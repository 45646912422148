const star = {
	state: {
		starRailStars: [3, 4, 5],
		starRailSteps: [1, 2, 3, 4, 5],
		starRailAttributes: ["风", "火", "雷", "冰", "量子", "虚数", "物理"],
		starRailRelicTypes: [
			{ code: 1, desc: "外" },
			{ code: 2, desc: "内" },
		],
		starRailDirections: [
			"普攻",
			"秘技",
			"战技",
			"天赋",
			"终结技",
			"额外能力",
			"属性加成",
		],
	},
	mutations: {},
	actions: {},
};

export default star;