import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有书籍类型
export function novelTypes() {
	return getRequest("/record/novel/getNovelTypeList");
}

// 书籍列表
export function novelPageData(params = {}) {
	return postRequest("/record/novel/getPageData", params);
}

// 删除书籍
export function delNovel(id) {
	return deleteRequest("/record/novel/" + id);
}

// 添加书籍
export function addNovel(params = {}) {
	return postRequest("/record/novel", params);
}

// 更新书籍
export function upNovel(params = {}) {
	return putRequest("/record/novel", params);
}

// 删除书籍类型
export function delNovelType(id) {
	return deleteRequest("/record/novel/deleteNovelType/" + id);
}

// 更新书籍类型
export function upNovelType(params = {}) {
	return putRequest("/record/novel/updateNovelType", params);
}

// 新增书籍类型
export function addNovelType(params = {}) {
	return postRequest("/record/novel/addNovelType", params);
}