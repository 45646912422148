<template>
  <div>
    <div>
      <div style="margin-top: 10px">
        <el-select
          v-model="searchNote.typeId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="笔记类型"
          clearable
          filterable
          @change="
            searchNote.currentPage = 1
            searchNote.size = 5
            initNote()
          "
        >
          <el-option v-for="item in noteTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="笔记标题"
          v-model="searchNote.title"
          clearable
          @keydown.enter.native="
            searchNote.currentPage = 1
            searchNote.size = 5
            initNote()
          "
        ></el-input>
        <el-date-picker
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="时间"
          type="date"
          v-model="searchNote.createTime"
          value-format="yyyy-MM-dd"
          clearable
          @change="
            searchNote.currentPage = 1
            searchNote.size = 5
            initNote()
          "
        ></el-date-picker>
      </div>
      <div style="margin-top: 10px">
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchNote.createTime = null
            searchNote.title = ''
            searchNote.typeId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchNote.currentPage = 1
            searchNote.size = 5
            initNote()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddNoteView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Note') !== -1"
        >
          添加笔记
        </el-button>
        <el-button type="danger" size="mini" @click="showTypeView">笔记类型配置</el-button>
        <el-button type="warning" size="mini" @click="showLearnView">辅助记忆</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="noteData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="笔记类型" width="200" prop="typeName"></el-table-column>
        <el-table-column label="笔记标题" prop="title"></el-table-column>
        <el-table-column label="笔记时间" align="center" width="140" sortable prop="createTime"></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="warning" @click="showRecordDetail(scope.row)">
              查看
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateNoteView(scope.row)"
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Note') !== -1
              "
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteNote(scope.row)"
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Note') !== -1
              "
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :page-sizes="[5, 10, 15]"
        :total="total"
        :current-page="searchNote.currentPage"
        :page-size="searchNote.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="noteForm" :model="note" :rules="rules">
        <table>
          <tr>
            <el-form-item label="笔记类型" prop="typeId">
              <el-select
                v-model="note.typeId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="笔记类型"
                clearable
                filterable
              >
                <el-option v-for="item in noteTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="笔记标题" prop="title">
              <el-input size="mini" style="width: 200px" v-model="note.title" placeholder="请输入笔记标题"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="笔记内容" prop="context">
              <div id="richText"></div>
            </el-form-item>

            <el-form-item label="笔记时间">
              <el-date-picker
                style="width: 200px"
                v-model="note.createTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doNote">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="查看笔记内容" :visible.sync="dialogVisible2" width="700px">
      <el-card>
        <div v-html="detail"></div>
      </el-card>
    </el-dialog>

    <!-- 笔记类型 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="typeTitle"
      @close="closeTypeDialog()"
      :visible.sync="typeDialogVisible"
      width="400px"
    >
      <el-form ref="typeForm" :model="typeData" :rules="typeRules">
        <table>
          <tr>
            <el-form-item label="类型编号">
              <el-select
                v-model="typeData.id"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="笔记类型编号"
                clearable
                filterable
                @change="initTypeInputData()"
              >
                <el-option v-for="item in noteTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="typeData.name"
                placeholder="请输入笔记类型名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型描述">
              <el-input
                type="textarea"
                :rows="5"
                size="mini"
                style="width: 200px"
                v-model="typeData.remark"
                placeholder="请输入笔记类型描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('Note') !== -1 &&
            typeData.id !== null
          "
          type="danger"
          @click="deleteType"
        >
          删 除
        </el-button>
        <el-button
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Note') !== -1"
          type="primary"
          @click="doType"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 辅助记忆 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="learnTitle"
      @close="closeLearnDialog()"
      :visible.sync="learnDialogVisible"
      width="1000px"
    >
      <div id="learnText"></div>
      <div style="margin-top: 40px; text-align: right">
        <el-button @click="clearLearnData">清 空</el-button>
        <el-button type="warning" @click="doLearn">生 成</el-button>
      </div>

      <!-- 循环文本 -->
      <div v-for="contentData in learnContent" style="margin-top: 40px">
        <hr style="border: 2px dashed #000000" />
        <div style="background: powderblue">
          <span style="font-size: 50px">{{ contentData.title }}</span>
        </div>
        <input v-model="contentData.anwser" style="height: 50px; width: 800px; font-size: 40px; margin-top: 10px" />
      </div>

      <div v-if="learnVisible" style="margin-top: 40px; text-align: right">
        <el-button type="warning" @click="doLearnFinish">最终生成</el-button>
      </div>

      <el-card style="margin-top: 40px" v-if="learnFinishVisble">
        <div style="font-size: 20px" v-html="learnFinishData"></div>
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            closeLearnDialog()
            learnDialogVisible = false
          "
        >
          取 消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  addNote,
  addNoteType,
  delNote,
  delNoteType,
  notePageData,
  noteTypes,
  upNote,
  upNoteType
} from '@/api/work/note'
let editor
let learnEditor

export default {
  name: 'Note',
  data() {
    var validateContext = (rule, value, callback) => {
      if (editor.txt.html() === undefined || editor.txt.html() === '') {
        callback(new Error('请输入笔记内容'))
      } else {
        callback()
      }
    }
    return {
      noteData: [],
      loading: false,
      noteTypes: [],
      learnContent: [],
      learnFinishData: '',
      searchNote: {
        createTime: null,
        title: '',
        typeId: null,
        roleId: 0,
        currentPage: 1,
        size: 5
      },
      note: {
        id: null,
        roleId: 0,
        typeId: null,
        title: '',
        context: '',
        createTime: ''
      },
      detail: '',
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        typeId: [{ required: true, message: '请选择笔记类型', trigger: 'blur' }],
        title: [{ required: true, message: '请输入笔记标题', trigger: 'blur' }],
        context: [{ validator: validateContext, trigger: 'blur' }]
      },
      typeRules: {
        name: [{ required: true, message: '请选择笔记类型名称', trigger: 'blur' }]
      },
      dialogVisible2: false,
      typeTitle: '',
      learnTitle: '',
      typeDialogVisible: false,
      learnVisible: false,
      learnDialogVisible: false,
      learnFinishVisble: false,
      typeData: {
        id: null,
        name: '',
        roleId: 0,
        remark: ''
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    ;(editor = ''), (learnEditor = '')
  },
  mounted() {
    this.initNote()
    this.initNoteType()
  },
  methods: {
    initNote() {
      this.loading = true
      notePageData(this.searchNote).then(resp => {
        this.loading = false
        if (resp) {
          this.noteData = resp.data
          this.total = resp.total
        }
      })
    },
    initNoteType() {
      noteTypes({ roleId: 0 }).then(resp => {
        if (resp) {
          this.noteTypes = resp
        }
      })
    },
    currentChange(page) {
      this.searchNote.currentPage = page
      this.initNote()
    },
    sizeChange(size) {
      this.searchNote.size = size
      this.initNote()
    },
    deleteNote(data) {
      this.$confirm('此操作将永久删除[' + data.createTime + ']时间的笔记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delNote(data.id).then(resp => {
            if (resp) {
              this.initNote()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddNoteView() {
      this.note = {
        id: null,
        roleId: 0,
        typeId: null,
        title: '',
        context: '',
        createTime: ''
      }
      this.dialogVisible = true
      this.title = '添加笔记'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateNoteView(data) {
      // 拷贝
      this.note = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑笔记'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.note.context)
      })
    },
    doNote() {
      if (this.note.id) {
        // 编辑
        this.$refs['noteForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.note.context = editor.txt.html()
            upNote(this.note).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initNote()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['noteForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.note.context = editor.txt.html()
            addNote(this.note).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initNote()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['noteForm'].resetFields()
    },
    showRecordDetail(data) {
      this.dialogVisible2 = true
      this.detail = data.context
    },
    showTypeView() {
      this.initNoteType()
      this.typeTitle = '笔记类型'
      this.typeDialogVisible = true
    },
    showLearnView() {
      this.learnTitle = '辅助记忆'
      this.learnDialogVisible = true
      // 清除生成内容
      this.learnContent = []
      // 渲染富文本
      this.$nextTick(() => {
        if (!learnEditor) {
          learnEditor = new E('#learnText')
          learnEditor.config.height = 200
          learnEditor.create()
        }
        // 清空内容
        learnEditor.txt.html('')
      })
    },
    closeTypeDialog() {
      this.typeData.id = null
      this.typeData.roleId = 0
      this.typeData.name = ''
      this.typeData.remark = ''
    },
    closeLearnDialog() {
      // 清除富文本消息
      learnEditor.txt.html('')
      this.learnContent = []
      // 清除最终生成的数据
      this.learnFinishData = ''
      // 关闭最终生成的开关
      this.learnVisible = false
      // 关闭最终生成card开关展示
      this.learnFinishVisble = false
    },
    initTypeInputData() {
      let id = this.typeData.id

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeTypeDialog()
        return
      }

      this.noteTypes.forEach(item => {
        if (String(item.id) === String(id)) {
          this.typeData.id = item.id
          this.typeData.roleId = 0
          this.typeData.name = item.name
          this.typeData.remark = item.remark
        }
      })
    },
    deleteType() {
      this.$confirm('此操作将永久删除[' + this.typeData.name + ']的笔记类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delNoteType(this.typeData.id).then(resp => {
            if (resp) {
              this.typeDialogVisible = false
              this.initNoteType()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doType() {
      if (this.typeData.id) {
        // 编辑
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            upNoteType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initNoteType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            addNoteType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initNoteType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    doLearn() {
      if (learnEditor.txt.html() === undefined || learnEditor.txt.html() === '') {
        this.$message.error('请输入记忆内容')
        return
      }
      let txt = learnEditor.txt.html()
      // 去除无用标签 首先去掉br换成# 然后把p标签换成# 然后去除/p标签结束 最后多的#转成一个#
      txt = txt
        .replace(/<[br/>]+?>/g, '#')
        .replace(/<[p>]+?>/g, '#')
        .replace(/<[/p>]+?>/g, '')
        .replace(/#+/g, '#')

      // 第一个元素
      let firstChar = txt.substring(0, 1)
      // 最后一个元素
      let lastChar = txt.substring(txt.length - 1, txt.length)

      // 如果是#则去除
      if ('#' === firstChar) {
        txt = txt.substring(1)
      }

      if ('#' === lastChar) {
        txt = txt.substring(0, txt.length - 1)
      }

      // 截取循环
      this.learnContent = []
      txt.split('#').forEach((val, index) => {
        let learnData = {
          title: val,
          anwser: ''
        }
        this.learnContent.push(learnData)
      })

      // 清除最终生成的数据
      this.learnFinishData = ''
      // 关闭card开关展示
      this.learnFinishVisble = false
      // 打开最终生成的开关
      this.learnVisible = true
    },
    doLearnFinish() {
      let txt = ''

      this.learnContent.forEach((val, index) => {
        txt += val.title + '<br/>' + val.anwser

        // 不是最后一个数据
        if (this.learnContent.size !== index) {
          txt += '<br/><br/>'
        }
      })
      this.learnFinishData = txt

      // 打开card开关展示
      this.learnFinishVisble = true
    },
    clearLearnData() {
      // 清空生成的所有数据信息
      this.learnContent = []
      // 清除最终生成的数据
      this.learnFinishData = ''
      // 关闭card开关展示
      this.learnFinishVisble = false
      // 关闭最终生成的开关
      this.learnVisible = false
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
