import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 游戏列表
export function gamePageData(params = {}) {
	return postRequest("/record/game/getPageData", params);
}

// 删除游戏
export function delGame(id) {
	return deleteRequest("/record/game/" + id);
}

// 添加游戏
export function addGame(params = {}) {
	return postRequest("/record/game", params);
}

// 更新游戏
export function upGame(params = {}) {
	return putRequest("/record/game", params);
}