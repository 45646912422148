import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取全部等级类型
export function levelTypes() {
	return getRequest("/sys/level/getLevelTypes");
}

// 等级列表
export function levelPageData(params = {}) {
	return postRequest("/sys/level/getPageData", params);
}

// 删除等级
export function delLevel(id) {
	return deleteRequest("/sys/level/" + id);
}

// 添加等级
export function addLevel(params = {}) {
	return postRequest("/sys/level", params);
}

// 更新等级
export function upLevel(params = {}) {
	return putRequest("/sys/level", params);
}

// 根据参数获取等级信息
export function levelsByParam(params = {}) {
	return postRequest("/sys/level/getLevels", params);
}