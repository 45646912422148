import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有模块
export function modulePermissModules() {
	return getRequest("/sys/module-permiss/modules");
}

// 根据等级id查询模块id
export function modulePermissModuleIdsByLid(id) {
	return getRequest("/sys/module-permiss/hmid/" + id);
}

// 更新模块权限
export function upModulePermiss(params) {
	return putRequest("/sys/module-permiss", params);
}