<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="武器名称"
          v-model="searchArms.name"
          clearable
          @keydown.enter.native="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        ></el-input>
        <el-select
          v-model="searchArms.star"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器星级"
          clearable
          filterable
          @change="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        >
          <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchArms.entry"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器主词条"
          clearable
          filterable
          @change="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        >
          <el-option v-for="item in entries" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchArms.step"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器阶数"
          clearable
          filterable
          @change="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        >
          <el-option v-for="item in steps" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
          v-model="searchArms.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="武器类型"
          clearable
          filterable
          @change="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        >
          <el-option v-for="item in armsTypes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetArms">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchArms.currentPage = 1
            searchArms.size = 10
            initArms()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddArmsView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('GenshinArms') !== -1
          "
        >
          添加武器
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="armsData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="武器名称" align="center" width="100" prop="name"></el-table-column>
        <el-table-column width="100" label="武器类型" align="center">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" label="武器星级" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.star }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="武器主词条" align="center" prop="entry" width="100"></el-table-column>
        <el-table-column width="100" label="主词条加成" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.correspondingElementBonus" type="primary">
              {{ scope.row.correspondingElementBonus }}
            </el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="武器阶数" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.step }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" label="武器攻击力" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.attackPower" type="primary">{{ scope.row.attackPower }}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="武器描述" align="center">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.descriptions && toText(scope.row.descriptions).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.descriptions.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptions }}
              </span>
              <span
                v-if="scope.row.descriptions"
                v-html="$options.filters.ellipsisHtml(scope.row.descriptions, 15)"
              ></span>
            </el-tooltip>
            <span v-else-if="scope.row.descriptions" v-html="toText(scope.row.descriptions)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('GenshinArms') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateArmsView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('GenshinArms') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteArms(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchArms.currentPage"
        :page-size="searchArms.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="800px">
      <el-form ref="armsForm" :model="arms" :rules="rules">
        <table>
          <tr>
            <el-form-item label="武器名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="arms.name" placeholder="请输入武器名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器类型" prop="type">
              <el-select
                v-model="arms.type"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器类型"
                clearable
                filterable
              >
                <el-option v-for="item in armsTypes" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器星级" prop="star">
              <el-select
                v-model="arms.star"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器星级"
                clearable
                filterable
              >
                <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器主词条" prop="entry">
              <el-select
                filterable
                v-model="arms.entry"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器主词条"
                clearable
              >
                <el-option v-for="item in entries" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="主词条加成">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="arms.correspondingElementBonus"
                placeholder="请输入主词条加成"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器攻击力">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="arms.attackPower"
                placeholder="请输入武器攻击力"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器阶数" prop="step">
              <el-select
                v-model="arms.step"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="武器阶数"
                clearable
                filterable
              >
                <el-option v-for="item in steps" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="武器描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doArms">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import { addGenshinArm, delGenshinArm, upGenshinArm, genshinArmPageData } from '@/api/genshin/arms'
let editor

export default {
  name: 'GenshinArms',
  data() {
    return {
      armsData: [],
      loading: false,
      searchArms: {
        name: '',
        star: null,
        entry: '',
        step: null,
        type: null,
        currentPage: 1,
        size: 10
      },
      arms: {
        id: null,
        name: '',
        star: null,
        entry: '',
        step: null,
        type: null,
        attackPower: '',
        correspondingElementBonus: '',
        descriptions: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入武器名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择武器类型', trigger: 'blur' }],
        star: [{ required: true, message: '请选择武器星级', trigger: 'blur' }],
        entry: [{ required: true, message: '请选择武器主词条', trigger: 'blur' }],
        step: [{ required: true, message: '请选择武器阶数', trigger: 'blur' }]
      },
      stars: store.getters.stars,
      entries: store.getters.entries,
      steps: store.getters.steps,
      armsTypes: store.getters.armsTypes,
      roleButton: store.getters.currentRole
    }
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  computed: {},
  mounted() {
    this.initArms()
  },
  methods: {
    initArms() {
      this.loading = true
      genshinArmPageData(this.searchArms).then(resp => {
        this.loading = false
        if (resp) {
          this.armsData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchArms.currentPage = page
      this.initArms()
    },
    sizeChange(size) {
      this.searchArms.size = size
      this.initArms()
    },
    deleteArms(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']武器, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delGenshinArm(data.id).then(resp => {
            if (resp) {
              this.initArms()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddArmsView() {
      this.arms = {
        id: null,
        name: '',
        star: null,
        entry: '',
        step: null,
        type: null,
        attackPower: '',
        correspondingElementBonus: '',
        descriptions: ''
      }
      this.dialogVisible = true
      this.title = '添加武器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateArmsView(data) {
      // 拷贝
      this.arms = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑武器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.arms.descriptions)
      })
    },
    doArms() {
      if (this.arms.id) {
        // 编辑
        this.$refs['armsForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.arms.descriptions = editor.txt.html()
            upGenshinArm(this.arms).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initArms()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['armsForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.arms.descriptions = editor.txt.html()
            addGenshinArm(this.arms).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initArms()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    resetArms() {
      this.searchArms.name = ''
      this.searchArms.star = null
      this.searchArms.entry = ''
      this.searchArms.step = null
      this.searchArms.type = null
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
