<template>
  <div class="pc">
    <el-container class="up">
      <!-- 侧边栏 -->
      <el-container class="down">
        <el-aside :width="isCollapse ? '65px' : '210px'" style="background-color: #d1dfe5; height: 100%">
          <div
            style="
              height: 60px;
              line-height: 60px;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div class="title">xrg</div>
          </div>

          <el-menu
            router
            unique-opened
            :collapse="isCollapse"
            :collapse-transition="false"
            :default-active="$route.path"
            background-color="#d1dfe5"
            active-text-color="#db6fd2"
            @select="handleSelect"
          >
            <el-submenu :index="index + ''" v-for="(item, index) in menuList" :key="index">
              <template slot="title">
                <i :class="item.iconCls" style="color: #1accff; margin-right: 5px"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                :index="children.path"
                v-for="(children, indexj) in item.children"
                :key="indexj"
                @click="addTab(children)"
              >
                {{ children.name }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>

        <!-- 主页 -->
        <el-main style="height: 100%; padding: 0">
          <!-- 导航栏 (面包屑 & 菜单路径  登录人信息) -->
          <div class="main-top-container">
            <!-- 居左 -->
            <div style="padding-left: 20px">
              <!-- 面包屑 -->
              <div class="hamburger-container" @click="toggleCollapse">
                <svg
                  :class="{ 'is-active': isActive }"
                  class="hamburger"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                >
                  <path
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
                  />
                </svg>
              </div>
              <!-- 菜单路径 -->
              <div style="padding-top: 2px; width: 400px">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item
                    v-if="this.$router.currentRoute.path !== '/home' && this.$router.currentRoute.matched[0]"
                  >
                    {{ this.$router.currentRoute.matched[0].name }}
                  </el-breadcrumb-item>
                  <el-breadcrumb-item v-if="this.$router.currentRoute.path !== '/home'">
                    {{ this.$router.currentRoute.name }}
                  </el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
            <!-- 居右 登录人信息 -->
            <div style="padding-right: 20px">
              <el-dropdown class="userInfo" @command="commandHandler">
                <span class="el-dropdown-link">
                  {{ role.username }}
                  <i><img :src="initUserFace(role.userFace)" /></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="userinfo">个人中心</el-dropdown-item>
                  <el-dropdown-item command="logout">注销登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

          <!-- tabs -->
          <div class="tabsClass">
            <el-button style="margin-top: 5px" v-if="editableTabs.length >= 1" @click="editableTabs = []">
              清除Tag
            </el-button>
            <el-tag
              closable
              size="medium"
              style="margin-left: 5px; margin-top: 5px"
              v-for="(item, index) in editableTabs"
              :key="index"
              :disable-transitions="true"
              :label="item.title"
              :name="item.name"
              :effect="$route.path === item.path ? 'dark' : 'plain'"
              @click="handleClick(item)"
              @close="handleClose(item, index)"
            >
              {{ item.title }}
            </el-tag>
          </div>

          <!-- 主页 & 路由页面 -->
          <div class="homeWelcome" v-if="this.$router.currentRoute.path === '/home'">
            欢迎来到duiduo管理系统
            <div>
              <img
                style="width: 400px; height: 400px; border-radius: 50px; margin-top: 25px"
                src="../assets/logo.png"
              />
            </div>
          </div>
          <router-view class="homeRouterWiew" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import baseUrl from '@/utils/baseUrl'
import store from '@/store'
import { logout } from '@/api/login'
import { menus } from '@/api/home'

export default {
  name: 'Home',
  data() {
    return {
      menuList: [],
      editableTabs: [],
      activeIndex: '',
      isCollapse: false,
      isActive: true,
      role: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    this.getMenuList()
  },
  // 监听路由跳转
  watch: {
    $route() {
      this.handleSelect(this.activeIndex)
    }
  },
  methods: {
    commandHandler(command) {
      if (command === 'logout') {
        this.$confirm('此操作将注销登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 注销登录
            logout().then((resp) => {
              if (resp) {
                // 清空用户信息
                window.sessionStorage.removeItem('tokenStr')
                window.sessionStorage.removeItem('role')
                // 跳转登录页
                this.$router.replace('/')
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
      if (command === 'userinfo') {
        this.$router.push('/userinfo')
      }
    },
    getMenuList() {
      menus().then((resp) => {
        this.menuList = resp
        // 初始化Tab
        this.initEditableTabs()
      })
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
      this.isActive = !this.isActive
    },
    initUserFace(image) {
      if (image.startsWith('http')) {
        return baseUrl.default + '/files?filePath=' + image + '&name='
      } else {
        return baseUrl.default + '/files/cosGet?filePath=' + image + '&name='
      }
    },
    addTab(val) {
      let pushFlag = true
      // 存在不新加
      this.editableTabs.forEach((tab, index) => {
        if (tab.name === val.component) {
          pushFlag = false
        }
      })
      if (pushFlag) {
        this.editableTabs.push({
          title: val.name,
          name: val.component,
          path: val.path
        })
      }
    },
    handleClick(val) {
      this.$router.push(val.path)
    },
    handleClose(val, index) {
      let tabs = this.editableTabs
      if (tabs.length === 1) {
        // 最后一页 去往首页
        this.$router.push('/home')
      }
      tabs.forEach((tab, index) => {
        if (tab.name === val.name) {
          let nextTab = tabs[index + 1] || tabs[index - 1]
          if (nextTab) {
            if (this.$router.currentRoute.path === val.path) {
              // 说明关闭的是当前页面
              this.$router.push(nextTab.path)
            }
          }
        }
      })
      this.editableTabs = this.editableTabs.filter((tab) => tab.name !== val.name)
    },
    // 菜单激活回调
    handleSelect(index) {
      this.activeIndex = index
    },
    initEditableTabs() {
      this.editableTabs = []
      let path = this.$router.currentRoute.path
      let name = this.$router.currentRoute.name

      // 如果不是主页 默认追加一条
      if (String(path) !== '/home') {
        // 获取component组件信息
        let component = ''
        this.menuList.forEach((val, index) => {
          val.children.forEach((rval, index) => {
            if (String(name) === String(rval.name)) {
              component = rval.component
            }
          })
        })

        this.editableTabs.push({
          title: name,
          name: component,
          path: path
        })
      }
    }
  }
}
</script>

<style>
/* 配合 global.css 调整最大边距*/
.pc,
.up {
  height: 100%;
}

.el-aside .el-menu {
  border-right: none;
}

.title {
  font-size: 20px;
  font-family: 华文琥珀;
  color: #db6fd2 !important;
}

.el-dropdown-link img {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-left: 8px;
  margin-top: 5px;
}

.main-top-container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
}

.hamburger-container {
  line-height: 10px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  margin-right: 20px;
}

.hamburger-container:hover {
  background: rgba(0, 0, 0, 0.025);
}

.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}

.tabsClass {
  padding: 0 20px 0 20px;
  cursor: pointer;
  margin-top: 10px;
}

.tabsClass .el-button {
  margin-right: 20px;
}

.homeWelcome {
  text-align: center;
  font-size: 30px;
  font-family: 华文琥珀;
  color: #ff69b4;
  padding: 5% 20px 0 20px;
}

.homeRouterWiew {
  padding: 0 20px 0 20px;
  margin-top: 10px;
}
</style>
