import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取菜单数据
export function menus() {
	return getRequest("/menu");
}