import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 账单月计划查询
export function accountPlanPageData(params = {}) {
	return postRequest("/person/account-plan/getPageData", params);
}

// 获取当前月的支出金额、计划金额和是否超出状态
export function accountPlanInfo() {
	return getRequest("/person/account-plan/getInfo");
}

// 删除账单月计划
export function delAccountPlan(id) {
	return deleteRequest("/person/account-plan/" + id);
}

// 添加账单月计划
export function addAccountPlan(params = {}) {
	return postRequest("/person/account-plan", params);
}

// 更新账单月计划
export function upAccountPlan(params = {}) {
	return putRequest("/person/account-plan", params);
}