<template>
  <div>
    <!-- 查询区域 -->
    <div>
      <div style="margin-top: 10px">
        <el-select
          v-model="searchPass.roleId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色"
          clearable
          filterable
          @change="
            searchPass.currentPage = 1
            searchPass.size = 10
            initPass()
          "
        >
          <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="密码名称"
          v-model="searchPass.name"
          clearable
          @keydown.enter.native="
            searchPass.currentPage = 1
            searchPass.size = 10
            initPass()
          "
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="登录名"
          v-model="searchPass.username"
          clearable
          @keydown.enter.native="
            searchPass.currentPage = 1
            searchPass.size = 10
            initPass()
          "
        ></el-input>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchPass.name = ''
            searchPass.username = ''
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchPass.currentPage = 1
            searchPass.size = 10
            initPass()
          "
        >
          搜索
        </el-button>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showAddPassView">添加记录</el-button>
      </div>
    </div>

    <!-- 列表信息展示 -->
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="passData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="密码名称" align="center" prop="name"></el-table-column>
        <el-table-column label="登录名" width="200" align="center" prop="username"></el-table-column>
        <el-table-column label="密码" align="center" width="200">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.password" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.password.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.password }}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="密码描述" align="center" width="250">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="200" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="更新时间" width="200" align="center" prop="updateTime" sortable></el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="primary" @click="showUpdatePassView(scope.row)">
              编辑
            </el-button>
            <el-button size="mini" style="padding: 3px" type="danger" @click="deletePass(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchPass.currentPage"
        :page-size="searchPass.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <!-- 新增编辑弹出框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="passForm" :model="pass" :rules="rules">
        <table>
          <tr>
            <el-form-item label="密码名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="pass.name" placeholder="请输入密码名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="登录名" prop="username">
              <el-input size="mini" style="width: 200px" v-model="pass.username" placeholder="请输入登录名"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="密码" prop="password">
              <el-input size="mini" style="width: 200px" v-model="pass.password" placeholder="请输入密码"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="密码描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doPass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
// wangeditor富文本
import E from 'wangeditor'
import { roles } from '@/api/sys/role'
import { addPass, delPass, passPageData, upPass } from '@/api/person/pass'
let editor

export default {
  name: 'PersonPass',
  data() {
    return {
      roles: [],
      loading: false,
      searchPass: {
        name: '',
        username: '',
        roleId: null,
        currentPage: 1,
        size: 10
      },
      passData: [],
      total: 0,
      pass: {
        id: null,
        roleId: null,
        name: '',
        username: '',
        password: ''
      },
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入密码名称', trigger: 'blur' }],
        username: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initRoles()
    this.initPass()
  },
  methods: {
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    initPass() {
      this.loading = true
      passPageData(this.searchPass).then(resp => {
        this.loading = false
        if (resp) {
          this.passData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchPass.currentPage = page
      this.initPass()
    },
    sizeChange(size) {
      this.searchPass.size = size
      this.initPass()
    },
    showAddPassView() {
      this.pass = {
        id: null,
        name: '',
        username: '',
        password: ''
      }
      this.dialogVisible = true
      this.title = '添加记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdatePassView(data) {
      // 拷贝
      this.pass = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.music.remark)
      })
    },
    doPass() {
      if (this.pass.id) {
        // 编辑
        this.$refs['passForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.pass.remark = editor.txt.html()
            upPass(this.pass).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initPass()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['passForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.pass.remark = editor.txt.html()
            addPass(this.pass).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initPass()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['passForm'].resetFields()
    },
    deletePass(data) {
      this.$confirm('此操作将永久删除[' + data.username + ']该登录名记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delPass(data.id).then(resp => {
            if (resp) {
              this.initPass()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
