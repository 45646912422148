<template>
  <div>
    <div style="margin-top: 15px">
      <div>
        <el-select
          v-model="searchNovel.typeId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="书籍类型"
          clearable
          filterable
          @change="
            searchNovel.currentPage = 1
            searchNovel.size = 10
            initNovel()
          "
        >
          <el-option v-for="item in novelTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="书籍名称"
          v-model="searchNovel.name"
          clearable
          @keydown.enter.native="
            searchNovel.currentPage = 1
            searchNovel.size = 10
            initNovel()
          "
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="作者"
          v-model="searchNovel.auther"
          clearable
          @keydown.enter.native="
            searchNovel.currentPage = 1
            searchNovel.size = 10
            initNovel()
          "
        ></el-input>
        <el-select
          v-model="searchNovel.isWall"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="是否翻墙"
          clearable
          filterable
          @change="
            searchNovel.currentPage = 1
            searchNovel.size = 10
            initNovel()
          "
        >
          <el-option label="需要翻墙" :value="true">需要翻墙</el-option>
          <el-option label="不需要翻墙" :value="false">不需要翻墙</el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchNovel.name = ''
            searchNovel.isWall = null
            searchNovel.typeId = null
            searchNovel.auther = ''
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchNovel.currentPage = 1
            searchNovel.size = 10
            initNovel()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddNovelView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Novel') !== -1"
        >
          添加书籍
        </el-button>
        <el-button type="danger" size="mini" @click="showTypeView">书籍类型配置</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="novelData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="书籍名称" align="center" width="200" prop="name"></el-table-column>
        <el-table-column label="作者" align="center" width="200" prop="auther"></el-table-column>
        <el-table-column label="书籍类型" align="center" prop="typeName"></el-table-column>
        <el-table-column label="书籍地址" align="center" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.url === null || scope.row.url === '' || scope.row.url === undefined">-</span>
            <a target="_blank" v-else :href="scope.row.url">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column label="是否需要翻墙" align="center" width="150">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isWall">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="书籍描述">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsisHtml(scope.row.remark, 15)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-if="!scope.row.remark">-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="200" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="更新时间" width="200" align="center" prop="updateTime" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Novel') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateNovelView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Novel') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteNovel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchNovel.currentPage"
        :page-size="searchNovel.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="novelForm" :model="novel" :rules="rules">
        <table>
          <tr>
            <el-form-item label="书籍类型">
              <el-select
                v-model="novel.typeId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="书籍类型"
                clearable
                filterable
              >
                <el-option v-for="item in novelTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="书籍名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="novel.name" placeholder="请输入书籍名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="作者" prop="auther">
              <el-input size="mini" style="width: 200px" v-model="novel.auther" placeholder="请输入作者"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="书籍地址" prop="url">
              <el-input size="mini" style="width: 400px" v-model="novel.url" placeholder="请输入书籍名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否需要翻墙">
              <el-switch
                v-model="novel.isWall"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="padding-top: 6px"
              ></el-switch>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="书籍描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doNovel">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 书籍类型 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="typeTitle"
      @close="closeTypeDialog()"
      :visible.sync="typeDialogVisible"
      width="400px"
    >
      <el-form ref="typeForm" :model="typeData" :rules="typeRules">
        <table>
          <tr>
            <el-form-item label="类型编号">
              <el-select
                v-model="typeData.id"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="书籍类型编号"
                clearable
                filterable
                @change="initTypeInputData()"
              >
                <el-option v-for="item in novelTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="typeData.name"
                placeholder="请输入书籍类型名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型描述">
              <el-input
                type="textarea"
                :rows="5"
                size="mini"
                style="width: 200px"
                v-model="typeData.remark"
                placeholder="请输入书籍类型描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('Novel') !== -1 &&
            typeData.id !== null
          "
          type="danger"
          @click="deleteNovelType"
        >
          删 除
        </el-button>
        <el-button
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Novel') !== -1"
          type="primary"
          @click="doNovelType"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  addNovel,
  addNovelType,
  delNovel,
  delNovelType,
  novelPageData,
  novelTypes,
  upNovel,
  upNovelType
} from '@/api/record/novel'
let editor

export default {
  name: 'Novel',
  data() {
    return {
      novelData: [],
      loading: false,
      searchNovel: {
        name: '',
        auther: '',
        isWall: null,
        typeId: null,
        currentPage: 1,
        size: 10
      },
      novel: {
        id: null,
        name: '',
        url: '',
        auther: '',
        isWall: false,
        typeId: null
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入书籍名称', trigger: 'blur' }],
        auther: [{ required: true, message: '请输入作者', trigger: 'blur' }]
      },
      novelTypes: [],
      typeTitle: '',
      typeDialogVisible: false,
      typeData: {
        id: null,
        name: '',
        remark: ''
      },
      typeRules: {
        name: [{ required: true, message: '请输入书籍类型名称', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initNovel()
    this.initNovelType()
  },
  methods: {
    initNovel() {
      this.loading = true
      novelPageData(this.searchNovel).then(resp => {
        this.loading = false
        if (resp) {
          this.novelData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchNovel.currentPage = page
      this.initNovel()
    },
    sizeChange(size) {
      this.searchNovel.size = size
      this.initNovel()
    },
    deleteNovel(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']书籍, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delNovel(data.id).then(resp => {
            if (resp) {
              this.initNovel()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddNovelView() {
      this.novel = {
        id: null,
        name: '',
        url: '',
        auther: '',
        isWall: false,
        typeId: null
      }
      this.dialogVisible = true
      this.title = '添加书籍'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateNovelView(data) {
      // 拷贝
      this.novel = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑书籍'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.novel.remark)
      })
    },
    closeDialog() {
      this.$refs['novelForm'].resetFields()
    },
    doNovel() {
      if (this.novel.id) {
        // 编辑
        this.$refs['novelForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.novel.remark = editor.txt.html()
            upNovel(this.novel).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initNovel()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['novelForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.novel.remark = editor.txt.html()
            addNovel(this.novel).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initNovel()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    initNovelType() {
      novelTypes().then(resp => {
        if (resp) {
          this.novelTypes = resp
        }
      })
    },
    showTypeView() {
      this.initNovelType()
      this.typeTitle = '书籍类型配置'
      this.typeDialogVisible = true
    },
    closeTypeDialog() {
      this.typeData.id = null
      this.typeData.name = ''
      this.typeData.remark = ''
    },
    initTypeInputData() {
      let id = this.typeData.id

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeTypeDialog()
        return
      }

      this.novelTypes.forEach(item => {
        if (String(item.id) === String(id)) {
          this.typeData.id = item.id
          this.typeData.name = item.name
          this.typeData.remark = item.remark
        }
      })
    },
    deleteNovelType() {
      this.$confirm('此操作将永久删除[' + this.typeData.name + ']的书籍类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delNovelType(this.typeData.id).then(resp => {
            if (resp) {
              this.typeDialogVisible = false
              this.initNovelType()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doNovelType() {
      if (this.typeData.id) {
        // 编辑
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            upNovelType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initNovelType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            addNovelType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initNovelType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
