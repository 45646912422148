<template>
  <div>
    <div class="permissManaTool">
      <el-input
        size="mini"
        placeholder="请输入等级权限名称"
        v-model="level.name"
        @keydown.enter.native="addLevel"
      ></el-input>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        style="margin-left: 20px"
        @click="addLevel"
        v-if="
          roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
        "
      >
        添加等级权限
      </el-button>
    </div>

    <div class="permissManaMain">
      <el-collapse v-model="activeName" accordion @change="change">
        <el-collapse-item :title="l.name" :name="l.id" v-for="(l, index) in levels" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>可访问资源</span>
              <el-button
                style="float: right; padding: 3px 0; color: #ff0000"
                type="text"
                icon="el-icon-delete"
                @click="doDeleteLevel(l)"
                v-if="
                  roleButton.buttonLevelComponents !== null &&
                  roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
                "
              ></el-button>
            </div>
            <div>
              <el-tree
                show-checkbox
                :data="allModules"
                :props="defaultProps"
                :default-checked-keys="selectedModules"
                node-key="id"
                :key="index"
                ref="tree"
              ></el-tree>
              <div style="display: flex; justify-content: flex-end">
                <el-button size="mini" @click="cancelUpdate">取消修改</el-button>
                <el-button
                  size="mini"
                  type="primary"
                  @click="doUpdate(l, index)"
                  v-if="
                    roleButton.buttonLevelComponents !== null &&
                    roleButton.buttonLevelComponents.indexOf('ModulePermiss') !== -1
                  "
                >
                  确认修改
                </el-button>
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { addPermiss, delPermiss, permiss } from '@/api/sys/permiss'
import { modulePermissModuleIdsByLid, modulePermissModules, upModulePermiss } from '@/api/sys/modulePermiss'

export default {
  name: 'ModulePermiss',
  data() {
    return {
      level: {
        name: ''
      },
      levels: [],
      allModules: [],
      activeName: -1,
      selectedModules: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initLevels()
  },
  methods: {
    initLevels() {
      permiss().then((resp) => {
        if (resp) {
          this.levels = resp
        }
      })
    },
    initAllModules() {
      modulePermissModules().then((resp) => {
        if (resp) {
          this.allModules = resp
        }
      })
    },
    addLevel() {
      if (this.level.name) {
        addPermiss(this.level).then((resp) => {
          if (resp) {
            this.initLevels()
          }
        })
      } else {
        this.$message.error('输入等级权限名称才能添加哦~')
      }
    },
    change(lid) {
      if (lid) {
        this.initAllModules()
        this.selectedModules = []
        this.initSelectedModules(lid)
      }
    },
    initSelectedModules(lid) {
      modulePermissModuleIdsByLid(lid).then((resp) => {
        if (resp) {
          this.selectedModules = resp
        }
      })
    },
    cancelUpdate() {
      this.activeName = -1
    },
    doDeleteLevel(level) {
      this.$confirm('此操作将永久删除[' + level.name + ']等级权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delPermiss(level.id).then((resp) => {
            if (resp) {
              this.initLevels()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doUpdate(l, index) {
      let tree = this.$refs.tree[index]
      // 获取所有选择的key true 只选择子节点下选中的
      let selectedKeys = tree.getCheckedKeys(true)
      let url = '/sys/module-permiss/?lid=' + l.id
      selectedKeys.forEach((key) => {
        url += '&hmids=' + key
      })

      let params = {
        lid: l.id,
        hmids: selectedKeys
      }
      upModulePermiss(params).then((resp) => {
        if (resp) {
          this.initLevels()
          this.activeName = -1
        }
      })
    }
  }
}
</script>

<style>
.permissManaTool {
  display: flex;
  justify-content: flex-start;
}

.permissManaTool .el-input {
  width: 300px;
  margin-right: 6px;
}

.permissManaMain {
  margin-top: 10px;
  width: 700px;
}
</style>
