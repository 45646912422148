<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="遗器名称"
          v-model="searchStarRailRelic.name"
          clearable
          @keydown.enter.native="
            searchStarRailRelic.currentPage = 1
            searchStarRailRelic.size = 10
            initStarRailRelic()
          "
        ></el-input>
        <el-select
          v-model="searchStarRailRelic.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="遗器类型"
          clearable
          filterable
          @change="
            searchStarRailRelic.currentPage = 1
            searchStarRailRelic.size = 10
            initStarRailRelic()
          "
        >
          <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchStarRailRelic.name = ''
            searchStarRailRelic.type = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchStarRailRelic.currentPage = 1
            searchStarRailRelic.size = 10
            initStarRailRelic()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddStarRailRelicView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('StarRailRelic') !== -1
          "
        >
          添加遗器
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="starRailRelicData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="遗器名称" align="center" width="200" prop="name"></el-table-column>
        <el-table-column label="遗器类型" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1" type="danger">外</el-tag>
            <el-tag v-if="scope.row.type === 2" type="danger">内</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="两件套效果">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.descriptionsTwo && toText(scope.row.descriptionsTwo).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.descriptionsTwo.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptionsTwo }}
              </span>
              <span
                v-if="scope.row.descriptionsTwo"
                v-html="$options.filters.ellipsisHtml(scope.row.descriptionsTwo, 15)"
              ></span>
            </el-tooltip>
            <span
              v-else-if="scope.row.descriptionsTwo"
              v-html="toText(scope.row.descriptionsTwo)"
              slot="content"
            ></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="四件套效果">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.descriptionsFour && toText(scope.row.descriptionsFour).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.descriptionsFour.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.descriptionsFour }}
              </span>
              <span
                v-if="scope.row.descriptionsFour"
                v-html="$options.filters.ellipsisHtml(scope.row.descriptionsFour, 15)"
              ></span>
            </el-tooltip>
            <span
              v-else-if="scope.row.descriptionsFour"
              v-html="toText(scope.row.descriptionsFour)"
              slot="content"
            ></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="150" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="更新时间" width="150" align="center" prop="updateTime" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('StarRailRelic') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateStarRailRelicView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('StarRailRelic') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteStarRailRelic(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchStarRailRelic.currentPage"
        :page-size="searchStarRailRelic.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="starRailRelicForm" :model="starRailRelic" :rules="rules">
        <table>
          <tr>
            <el-form-item label="遗器名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="starRailRelic.name"
                placeholder="请输入遗器名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="遗器类型" prop="type">
              <el-select
                v-model="starRailRelic.type"
                size="mini"
                style="width: 200px"
                placeholder="遗器类型"
                clearable
                filterable
              >
                <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="两件套效果">
              <div id="richText1"></div>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="四件套效果">
              <div id="richText2"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doStarRailRelic">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
// wangeditor富文本
import E from 'wangeditor'
import { addStarRailRelic, starRailRelicPageData, delStarRailRelic, upStarRailRelic } from '@/api/star/relic'
let editor1
let editor2

export default {
  name: 'StarRailRelic',
  data() {
    return {
      loading: false,
      searchStarRailRelic: {
        name: '',
        type: null,
        currentPage: 1,
        size: 10
      },
      starRailRelic: {
        id: null,
        name: '',
        type: null,
        descriptionsTwo: '',
        descriptionsFour: ''
      },
      total: 0,
      starRailRelicData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入遗器名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择遗器类型', trigger: 'change' }]
      },
      roleButton: store.getters.currentRole,
      types: store.getters.starRailRelicTypes
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor1 = ''
    editor2 = ''
  },
  mounted() {
    this.initStarRailRelic()
  },
  methods: {
    initStarRailRelic() {
      this.loading = true
      starRailRelicPageData(this.searchStarRailRelic).then(resp => {
        this.loading = false
        if (resp) {
          this.starRailRelicData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchStarRailRelic.currentPage = page
      this.initStarRailRelic()
    },
    sizeChange(size) {
      this.searchStarRailRelic.size = size
      this.initStarRailRelic()
    },
    deleteStarRailRelic(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']该遗器, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delStarRailRelic(data.id).then(resp => {
            if (resp) {
              this.initStarRailRelic()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddStarRailRelicView() {
      this.starRailRelic = {
        id: null,
        name: '',
        type: null,
        descriptionsTwo: '',
        descriptionsFour: ''
      }
      this.dialogVisible = true
      this.title = '添加遗器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        // 清空内容
        editor1.txt.html('')
        editor2.txt.html('')
      })
    },
    showUpdateStarRailRelicView(data) {
      // 拷贝
      this.starRailRelic = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑遗器'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor1) {
          editor1 = new E('#richText1')
          // 配置上传图片地址
          editor1.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor1.config.uploadFileName = 'file'
          editor1.config.height = 200
          editor1.create()
        }
        if (!editor2) {
          editor2 = new E('#richText2')
          // 配置上传图片地址
          editor2.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor2.config.uploadFileName = 'file'
          editor2.config.height = 200
          editor2.create()
        }
        // 内容赋值
        editor1.txt.html('')
        editor2.txt.html('')
        editor1.txt.html(this.starRailRelic.descriptionsTwo)
        editor2.txt.html(this.starRailRelic.descriptionsFour)
      })
    },
    doStarRailRelic() {
      if (this.starRailRelic.id) {
        // 编辑
        this.$refs['starRailRelicForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailRelic.descriptionsTwo = editor1.txt.html()
            this.starRailRelic.descriptionsFour = editor2.txt.html()
            upStarRailRelic(this.starRailRelic).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initStarRailRelic()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['starRailRelicForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailRelic.descriptionsTwo = editor1.txt.html()
            this.starRailRelic.descriptionsFour = editor2.txt.html()
            addStarRailRelic(this.starRailRelic).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initStarRailRelic()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['starRailRelicForm'].resetFields()
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
