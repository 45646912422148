import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取未绑定的所有申请人
export function permissRelevanceUnBindRoles() {
	return getRequest("/permission/permission-relevance/getUnBindRoles");
}

// 获取审批关联列表
export function permissRelevancePageData(params = {}) {
	return postRequest("/permission/permission-relevance/getPageData", params);
}

// 删除审批关联
export function delPermissRelevance(id) {
	return deleteRequest("/permission/permission-relevance/" + id);
}

// 添加审批关联
export function addPermissRelevance(params = {}) {
	return postRequest("/permission/permission-relevance", params);
}

// 编辑审批关联
export function upPermissRelevance(params = {}) {
	return putRequest("/permission/permission-relevance", params);
}