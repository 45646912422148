import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 朋友记录列表
export function friendPageData(params = {}) {
	return postRequest("/record/friend/getPageData", params);
}

// 删除朋友
export function delFriend(id) {
	return deleteRequest("/record/friend/" + id);
}

// 添加朋友
export function addFriend(params = {}) {
	return postRequest("/record/friend", params);
}

// 更新朋友
export function upFriend(params = {}) {
	return putRequest("/record/friend", params);
}

// 朋友记录图表信息
export function friendCount() {
	return getRequest("/record/friend/count");
}