import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// app通知推送
export function pushMessage(params = {}) {
	return postRequest("/sys/message-notification/pushMessage", params);
}

// 消息通知列表
export function msgNotificationPageData(params = {}) {
	return postRequest("/sys/message-notification/getPageData", params);
}

// 删除消息通知
export function delMessageNotification(id) {
	return deleteRequest("/sys/message-notification/" + id);
}

// 添加消息通知
export function addMessageNotification(params = {}) {
	return postRequest("/sys/message-notification", params);
}