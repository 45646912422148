<template>
  <div>
    <div>
      <div>
        <el-select
          v-model="searchMessageNotification.roleId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="接收人"
          clearable
          filterable
          @change="
            searchMessageNotification.currentPage = 1
            searchMessageNotification.size = 10
            initMessageNotification()
          "
        >
          <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="消息标题"
          v-model="searchMessageNotification.title"
          clearable
          @keydown.enter.native="
            searchMessageNotification.currentPage = 1
            searchMessageNotification.size = 10
            initMessageNotification()
          "
        ></el-input>
        <el-select
          v-model="searchMessageNotification.status"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="是否已读"
          clearable
          filterable
          @change="
            searchMessageNotification.currentPage = 1
            searchMessageNotification.size = 10
            initMessageNotification()
          "
        >
          <el-option label="是" value="true"></el-option>
          <el-option label="否" value="false"></el-option>
        </el-select>
        <el-select
          v-model="searchMessageNotification.enabled"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="是否启用"
          clearable
          filterable
          @change="
            searchMessageNotification.currentPage = 1
            searchMessageNotification.size = 10
            initMessageNotification()
          "
        >
          <el-option label="是" value="true"></el-option>
          <el-option label="否" value="false"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetSearch">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchMessageNotification.currentPage = 1
            searchMessageNotification.size = 10
            initMessageNotification()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddMessageNotificationView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('MessageNotification') !== -1
          "
        >
          消息推送
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-s-promotion"
          @click="showPushMessageView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('MessageNotification') !== -1
          "
        >
          App通知推送
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="messageNotificationData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="接收人" width="200" align="center" prop="roleName"></el-table-column>
        <el-table-column label="消息标题" width="200" align="center" prop="title"></el-table-column>
        <el-table-column label="是否已读" width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="消息内容" align="center">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.content" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.content.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.content }}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="180" prop="createTime" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="60">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('MessageNotification') !== -1 &&
                scope.row.enabled
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteMessageNotification(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchMessageNotification.currentPage"
        :page-size="searchMessageNotification.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form ref="messageNotificationForm" :model="messageNotification" :rules="rules">
        <table>
          <tr>
            <el-form-item label="接收人" prop="roleIds">
              <el-select
                v-model="messageNotification.roleIds"
                size="mini"
                multiple
                style="width: 400px"
                placeholder="接收人"
                clearable
                filterable
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消息标题" prop="title">
              <el-input
                size="mini"
                style="width: 400px"
                v-model="messageNotification.title"
                placeholder="请输入消息标题"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="消息内容" prop="content">
              <el-input
                type="textarea"
                :rows="10"
                size="mini"
                style="width: 400px"
                v-model="messageNotification.content"
                placeholder="请输入消息内容"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMessageNotification">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="App通知推送"
      @close="closePushMessageDialog()"
      :visible.sync="pushMessageDialogVisible"
      width="600px"
    >
      <el-form ref="pushMessageForm" :model="pushMessage" :rules="pushMessageRules">
        <table>
          <tr>
            <el-form-item label="接收人" prop="roleIds">
              <el-select
                v-model="pushMessage.roleIds"
                size="mini"
                multiple
                style="width: 400px"
                placeholder="接收人"
                clearable
                filterable
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="通知标题" prop="title">
              <el-input
                size="mini"
                style="width: 400px"
                v-model="pushMessage.title"
                placeholder="请输入通知标题"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="通知内容" prop="content">
              <el-input
                type="textarea"
                :rows="10"
                size="mini"
                style="width: 400px"
                v-model="pushMessage.content"
                placeholder="请输入通知内容"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doPushMessage">推 送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import {
  addMessageNotification,
  delMessageNotification,
  msgNotificationPageData,
  pushMessage
} from '@/api/sys/messageNotification'
import { roles } from '@/api/sys/role'

export default {
  name: 'MessageNotification',
  data() {
    return {
      messageNotificationData: [],
      loading: false,
      searchMessageNotification: {
        title: '',
        type: 2,
        status: null,
        roleId: null,
        enabled: null,
        currentPage: 1,
        size: 10
      },
      messageNotification: {
        id: null,
        title: '',
        content: '',
        status: false,
        type: 2,
        enabled: true,
        roleIds: null
      },
      pushMessage: {
        roleIds: null,
        title: '',
        content: ''
      },
      total: 0,
      dialogVisible: false,
      pushMessageDialogVisible: false,
      title: '',
      roles: null,
      rules: {
        title: [{ required: true, message: '请输入消息标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入消息内容', trigger: 'blur' }],
        roleIds: [{ required: true, message: '请选择接收人', trigger: 'change' }]
      },
      pushMessageRules: {
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入通知内容', trigger: 'blur' }],
        roleIds: [{ required: true, message: '请选择接收人', trigger: 'change' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initMessageNotification()
    this.initRoles()
  },
  methods: {
    initRoles() {
      roles().then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    initMessageNotification() {
      this.loading = true
      msgNotificationPageData(this.searchMessageNotification).then(resp => {
        this.loading = false
        if (resp) {
          this.messageNotificationData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchMessageNotification.currentPage = page
      this.initMessageNotification()
    },
    sizeChange(size) {
      this.searchMessageNotification.size = size
      this.initMessageNotification()
    },
    deleteMessageNotification(data) {
      this.$confirm('此操作将删除[' + data.title + ']消息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMessageNotification(data.id).then(resp => {
            if (resp) {
              this.initMessageNotification()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddMessageNotificationView() {
      this.messageNotification = {
        id: null,
        title: '',
        content: '',
        status: false,
        type: 2,
        enabled: true,
        roleIds: null
      }
      this.dialogVisible = true
      this.title = '添加消息'
    },
    showPushMessageView() {
      this.pushMessage = {
        roleIds: null,
        title: '',
        content: ''
      }
      this.pushMessageDialogVisible = true
    },
    closeDialog() {
      this.$refs['messageNotificationForm'].resetFields()
    },
    closePushMessageDialog() {
      this.$refs['pushMessageForm'].resetFields()
    },
    doMessageNotification() {
      // 添加
      this.$refs['messageNotificationForm'].validate(valid => {
        if (valid) {
          addMessageNotification(this.messageNotification).then(resp => {
            if (resp) {
              this.dialogVisible = false
              this.initMessageNotification()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    doPushMessage() {
      pushMessage(this.pushMessage)
    },
    resetSearch() {
      this.searchMessageNotification.title = ''
      this.searchMessageNotification.status = null
      this.searchMessageNotification.enabled = null
      this.searchMessageNotification.roleId = null
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
