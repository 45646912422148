import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取全部明日方舟材料类型
export function arknightsMaterialsTypes() {
	return getRequest("/arknights/materials/materialsType");
}

// 明日方舟材料记录列表
export function arknightsMaterialsPageData(params = {}) {
	return postRequest("/arknights/materials/getPageData", params);
}

// 删除明日方舟材料
export function delArknightsMaterials(id) {
	return deleteRequest("/arknights/materials/" + id);
}

// 添加明日方舟材料
export function addArknightsMaterials(params = {}) {
	return postRequest("/arknights/materials", params);
}

// 更新明日方舟材料
export function upArknightsMaterials(params = {}) {
	return putRequest("/arknights/materials", params);
}