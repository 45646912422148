/**
 * 列表字段显示过滤
 *
 * @param {*} value 文本
 * @param length 长度
 * @returns 过滤文本
 */
export function ellipsis(value, length = 10) {
  if (!value) {
    return ''
  }
  if (value.length > length) {
    return value.slice(0, length - 2) + '...'
  } else {
    return value
  }
}

/**
 * 列表字段显示过滤
 *
 * @param {*} value Html文本
 * @param length 长度
 * @returns 过滤文本
 */
export function ellipsisHtml(value, length = 10) {
  if (!value) {
    return ''
  }
  let input = value
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
  if (input.length > length) {
    return input.slice(0, length) + '...'
  } else {
    return input
  }
}

export default {
  filters: { ellipsis, ellipsisHtml }
}
