<script>
export default {
  computed: {
    datePickerConfig() {
      return {
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              var y = new Date().getFullYear()
              var m = new Date().getMonth()
              var d = new Date().getDate()
              const end = new Date(y + '-' + (m + 1) + '-' + d + ' 23:59:59')
              const start = new Date(y + '-' + (m + 1) + '-' + d + ' 00:00:00')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨日',
            onClick(picker) {
              var today = new Date()
              var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000)
              var y = yesterday.getFullYear()
              var m = yesterday.getMonth()
              var d = yesterday.getDate()
              const end = new Date(y + '-' + (m + 1) + '-' + d + ' 23:59:59')
              const start = new Date(y + '-' + (m + 1) + '-' + d + ' 00:00:00')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              var today = new Date()
              var y = today.getFullYear()
              var m = today.getMonth()
              var d = today.getDate()
              var weekday = new Date(today.getTime() - 3600 * 1000 * 24 * 7)
              var wy = weekday.getFullYear()
              var wm = weekday.getMonth()
              var wd = weekday.getDate()
              const end = new Date(y + '-' + (m + 1) + '-' + d + ' 23:59:59')
              const start = new Date(wy + '-' + (wm + 1) + '-' + wd + ' 00:00:00')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              var today = new Date()
              var y = today.getFullYear()
              var m = today.getMonth()
              var d = today.getDate()
              var monthday = new Date(today.getTime() - 3600 * 1000 * 24 * 30)
              var my = monthday.getFullYear()
              var mm = monthday.getMonth()
              var md = monthday.getDate()
              const end = new Date(y + '-' + (m + 1) + '-' + d + ' 23:59:59')
              const start = new Date(my + '-' + (mm + 1) + '-' + md + ' 00:00:00')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '15号月度',
            onClick(picker) {
              var today = new Date()
              var y = today.getFullYear()
              var m = today.getMonth()
              var d = today.getDate()
              var start
              var end
              if (d >= 15) {
                start = new Date(y + '-' + (m + 1) + '-15 00:00:00')
                end = new Date(y + '-' + (m + 2) + '-15 23:59:59')
              } else {
                start = new Date(y + '-' + m + '-15 00:00:00')
                end = new Date(y + '-' + (m + 1) + '-15 23:59:59')
              }
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  }
}
</script>
