import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";
import { downloadRequest } from "@/utils/download";

// 获取所有原神角色
export function genshinRoles() {
	return getRequest("/genshin/role/getAll");
}

// 原神角色记录列表
export function genshinRolePageData(params = {}) {
	return postRequest("/genshin/role/getPageData", params);
}

// 删除原神角色
export function delGenshinRole(id) {
	return deleteRequest("/genshin/role/" + id);
}

// 添加原神角色
export function addGenshinRole(params = {}) {
	return postRequest("/genshin/role", params);
}

// 更新原神角色
export function upGenshinRole(params = {}) {
	return putRequest("/genshin/role", params);
}

// 原神角色列表导出
export function genshinRoleExport() {
	return downloadRequest("/genshin/role/export");
}

// 原神角色图表
export function genshinRoleCount() {
	return getRequest("/genshin/role/count");
}