import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取全部崩铁命途信息
export function starRailDestinies() {
	return getRequest("/star-rail/destiny/getAll");
}

// 崩铁命途列表
export function starRailDestinyPageData(params = {}) {
	return postRequest("/star-rail/destiny/getPageData", params);
}

// 删除崩铁命途
export function delStarRailDestiny(id) {
	return deleteRequest("/star-rail/destiny/" + id);
}

// 添加崩铁命途
export function addStarRailDestiny(params = {}) {
	return postRequest("/star-rail/destiny", params);
}

// 更新崩铁命途
export function upStarRailDestiny(params = {}) {
	return putRequest("/star-rail/destiny", params);
}