import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 天气 - 获取城市信息
export function weatherCityInfo(params = {}) {
	return postRequest("/weather/searchCityInfo", params);
}

// 天气 - 获取7天天气预报信息
export function sevenDayWeatherInfo(params = {}) {
	return postRequest("/weather/searchSevenDayWeatherInfo", params);
}

// 天气 - 获取当天空气质量
export function airQualityInfo(params = {}) {
	return postRequest("/weather/searchAirQualityInfo", params);
}

// 天气 - 获取当天天气预警
export function weatherWarningInfo(params = {}) {
	return postRequest("/weather/searchWeatherWarningInfo", params);
}

// 天气 - 获取所有天气指数类型
export function weatherAllIndexTypes() {
	return getRequest("/weather/getAllWeatherIndexTypes");
}

// 天气 - 获取当天天气指数
export function weatherIndexInfo(params = {}) {
	return postRequest("/weather/searchWeatherIndexInfo", params);
}