import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 幻塔装备列表
export function hottaEquippedPageData(params = {}) {
	return postRequest("/hotta/equipped/getPageData", params);
}

// 查看所有装备配置项
export function hottaEquippedMenus() {
	return getRequest("/hotta/equipped/getHottaEquippedMenuList");
}

// 添加幻塔装备
export function addHottaEquipped(params = {}) {
	return postRequest("/hotta/equipped", params);
}

// 编辑幻塔装备
export function upHottaEquipped(params = {}) {
	return putRequest("/hotta/equipped", params);
}

// 删除幻塔装备
export function delHottaEquipped(id) {
	return deleteRequest("/hotta/equipped/" + id);
}

// 删除装备配置项
export function delHottaEquippedMenu(id) {
	return deleteRequest("/hotta/equipped/deleteHottaEquippedMenu/" + id);
}

// 更新装备配置项
export function upHottaEquippedMenu(params = {}) {
	return putRequest("/hotta/equipped/updateHottaEquippedMenu", params);
}

// 新增装备配置项
export function addHottaEquippedMenu(params = {}) {
	return postRequest("/hotta/equipped/addHottaEquippedMenu", params);
}