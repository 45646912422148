<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="等级名称"
          v-model="searchLevel.name"
          clearable
          @keydown.enter.native="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        ></el-input>
        <el-select
          v-model="searchLevel.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="等级类型"
          clearable
          filterable
          @change="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        >
          <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchLevel.name = ''">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        >
          搜索
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="levels"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="等级名称" prop="name"></el-table-column>
        <el-table-column label="组件" prop="component"></el-table-column>
        <el-table-column label="等级排序" sortable prop="sort"></el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchLevel.currentPage"
        :page-size="searchLevel.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { xrgLevelTypes, xrgLevelPageData } from '@/api/xrg/level'

export default {
  name: 'Level',
  data() {
    return {
      levels: [],
      total: 0,
      loading: false,
      types: [],
      searchLevel: {
        name: '',
        type: 1,
        currentPage: 1,
        size: 10
      },
      role: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initLevelTypes()
    this.initLevels()
  },
  methods: {
    initLevelTypes() {
      this.loading = true
      xrgLevelTypes().then(resp => {
        this.loading = false
        if (resp) {
          this.types = resp
        }
      })
    },
    initLevels() {
      this.loading = true
      xrgLevelPageData(this.searchLevel).then(resp => {
        this.loading = false
        if (resp) {
          this.levels = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchLevel.currentPage = page
      this.initLevels()
    },
    sizeChange(size) {
      this.searchLevel.size = size
      this.initLevels()
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
