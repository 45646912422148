var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',[_c('el-input',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"size":"mini","placeholder":"游戏名称","clearable":""},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchGame.currentPage = 1
          _vm.searchGame.size = 10
          _vm.initGame()}},model:{value:(_vm.searchGame.name),callback:function ($$v) {_vm.$set(_vm.searchGame, "name", $$v)},expression:"searchGame.name"}}),_c('el-select',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"size":"mini","placeholder":"游戏类型","clearable":"","filterable":""},on:{"change":function($event){_vm.searchGame.currentPage = 1
          _vm.searchGame.size = 10
          _vm.initGame()}},model:{value:(_vm.searchGame.type),callback:function ($$v) {_vm.$set(_vm.searchGame, "type", $$v)},expression:"searchGame.type"}},_vm._l((_vm.gameTypes),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1),_c('el-button',{attrs:{"icon":"el-icon-delete","type":"warning","size":"mini"},on:{"click":function($event){_vm.searchGame.name = ''
          _vm.searchGame.type = ''}}},[_vm._v(" 重置 ")]),_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-search"},on:{"click":function($event){_vm.searchGame.currentPage = 1
          _vm.searchGame.size = 10
          _vm.initGame()}}},[_vm._v(" 搜索 ")]),(_vm.roleButton.buttonLevelComponents !== null && _vm.roleButton.buttonLevelComponents.indexOf('Game') !== -1)?_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-plus"},on:{"click":_vm.showAddGameView}},[_vm._v(" 添加游戏 ")]):_vm._e()],1)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"size":"mini","data":_vm.gameData,"border":"","stripe":"","row-class-name":_vm.tableRowClassName,"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('el-table-column',{attrs:{"label":"游戏名称","align":"center","prop":"name"}}),_c('el-table-column',{attrs:{"label":"游戏类型","align":"center","prop":"type"}}),_c('el-table-column',{attrs:{"label":"创建时间","width":"200","align":"center","prop":"createTime","sortable":""}}),_c('el-table-column',{attrs:{"label":"更新时间","width":"200","align":"center","prop":"updateTime","sortable":""}}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.roleButton.buttonLevelComponents !== null && _vm.roleButton.buttonLevelComponents.indexOf('Game') !== -1
            )?_c('el-button',{staticStyle:{"padding":"3px"},attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.showUpdateGameView(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(
              _vm.roleButton.buttonLevelComponents !== null && _vm.roleButton.buttonLevelComponents.indexOf('Game') !== -1
            )?_c('el-button',{staticStyle:{"padding":"3px"},attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteGame(scope.row)}}},[_vm._v(" 删除 ")]):_vm._e()]}}])})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-top":"5px"}},[_c('el-pagination',{attrs:{"background":"","layout":"sizes, prev, pager, next, jumper, ->, total, slot","current-page":_vm.searchGame.currentPage,"page-size":_vm.searchGame.size,"total":_vm.total},on:{"current-change":_vm.currentChange,"size-change":_vm.sizeChange}})],1),_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.title,"visible":_vm.dialogVisible,"width":"400px"},on:{"close":function($event){return _vm.closeDialog()},"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"gameForm",attrs:{"model":_vm.game,"rules":_vm.rules}},[_c('table',[_c('tr',[_c('el-form-item',{attrs:{"label":"游戏名称","prop":"name"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"size":"mini","placeholder":"请输入游戏名称"},model:{value:(_vm.game.name),callback:function ($$v) {_vm.$set(_vm.game, "name", $$v)},expression:"game.name"}})],1)],1),_c('tr',[_c('el-form-item',{attrs:{"label":"游戏类型","prop":"type"}},[_c('el-select',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"size":"mini","placeholder":"游戏类型","clearable":"","filterable":""},model:{value:(_vm.game.type),callback:function ($$v) {_vm.$set(_vm.game, "type", $$v)},expression:"game.type"}},_vm._l((_vm.gameTypes),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1)],1)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.doGame}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }