<template>
  <div>
    <div>
      <div>
        <div>
          <el-date-picker
            style="width: 200px; margin-right: 20px"
            size="mini"
            placeholder="记录时间"
            type="date"
            v-model="searchLog.date"
            value-format="yyyy-MM-dd"
            clearable
            @change="
              searchLog.currentPage = 1
              searchLog.size = 10
              initLog()
            "
          ></el-date-picker>
          <el-select
            v-model="searchLog.operatorId"
            size="mini"
            style="width: 200px; margin-right: 20px"
            placeholder="操作人"
            clearable
            filterable
            @change="
              searchLog.currentPage = 1
              searchLog.size = 10
              initLog()
            "
          >
            <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
          </el-select>
          <el-select
            v-model="searchLog.operation"
            size="mini"
            style="width: 200px; margin-right: 20px"
            placeholder="操作方法"
            clearable
            filterable
            @change="
              searchLog.currentPage = 1
              searchLog.size = 10
              initLog()
            "
          >
            <el-option
              v-for="item in operations"
              :key="item.operation"
              :label="item.name"
              :value="item.operation"
            ></el-option>
          </el-select>
          <el-select
            v-model="searchLog.oprResult"
            size="mini"
            style="width: 200px; margin-right: 20px"
            placeholder="操作结果"
            clearable
            filterable
            @change="
              searchLog.currentPage = 1
              searchLog.size = 10
              initLog()
            "
          >
            <el-option key="success" label="success" value="success"></el-option>
            <el-option key="fail" label="fail" value="fail"></el-option>
          </el-select>
        </div>
        <div style="margin-top: 10px">
          <el-button
            icon="el-icon-delete"
            type="warning"
            size="mini"
            @click="
              searchLog.date = ''
              searchLog.operatorId = ''
              searchLog.operation = ''
              searchLog.oprResult = ''
            "
          >
            重置
          </el-button>
          <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            @click="
              searchLog.currentPage = 1
              searchLog.size = 10
              initLog()
            "
          >
            搜索
          </el-button>
          <el-button type="danger" size="mini" @click="showOperationView">审计操作配置</el-button>
        </div>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="logData"
        border
        stripe
        tooltip-effect="dark"
        :row-class-name="tableRowClassName"
        :row-key="getRowKeys"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="background-color: aliceblue">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="操作方法名">
                  <span>{{ props.row.operation }}</span>
                </el-form-item>
                <el-form-item label="请求参数">
                  <span>{{ props.row.args }}</span>
                </el-form-item>
                <el-form-item v-if="props.row.oprResult === 'fail'" label="异常信息">
                  <span>{{ props.row.oprException }}</span>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="链路编号" width="300" align="center" prop="logId"></el-table-column>
        <el-table-column label="操作来源" width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.channel }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作人" width="100" align="center" prop="operator"></el-table-column>
        <el-table-column label="操作方法" width="200" align="center" prop="operationName"></el-table-column>
        <el-table-column label="方法描述" align="center">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.description && scope.row.description.length > 20"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.description.replace(/\n/gm, '<br>')" slot="content">
                {{ scope.row.description }}
              </span>
              <span v-if="scope.row.description">{{ scope.row.description | ellipsis }}</span>
            </el-tooltip>
            <span v-else-if="scope.row.description" v-html="scope.row.description"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作结果" width="100" align="center" prop="oprResult">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.oprResult === 'success'" type="success">{{ scope.row.oprResult }}</el-tag>
            <el-tag v-else-if="scope.row.oprResult === 'fail'" type="danger">{{ scope.row.oprResult }}</el-tag>
            <el-tag v-else>-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="记录时间" width="200" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AuditLog') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteLog(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchLog.currentPage"
        :page-size="searchLog.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <!-- 审计操作配置 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="operationTitle"
      @close="closeOperationDialog()"
      :visible.sync="operationDialogVisible"
      width="400px"
    >
      <el-form ref="operationForm" :model="operationData" :rules="operationRules">
        <table>
          <tr>
            <el-form-item label="编号">
              <el-select
                v-model="operationData.id"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="审计操作编号"
                clearable
                filterable
                @change="initOperationInputData()"
              >
                <el-option v-for="item in operations" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="operationData.name"
                placeholder="请输入审计操作名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="方法名" prop="operation">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="operationData.operation"
                placeholder="请输入审计操作方法名"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operationDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('AuditLog') !== -1 &&
            operations.id !== null
          "
          type="danger"
          @click="deleteAuditOperation"
        >
          删 除
        </el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('AuditLog') !== -1
          "
          type="primary"
          @click="doAuditOperation"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { rolesByParams } from '@/api/sys/role'
import {
  addOperation,
  auditLogOperations,
  auditLogPageData,
  delAuditLog,
  delOperation,
  upOperation
} from '@/api/audit/log'
import { levelsByParam } from '@/api/sys/level'
import { SessionStorageKey } from '@/constant/SessionStorageKey'
import { MasterLevelName } from '@/constant/RoleLevel'
import { LevelType } from '@/constant/RoleLevel'

export default {
  name: 'AuditLog',
  data() {
    return {
      searchLog: {
        date: '',
        operatorId: '',
        operation: '',
        oprResult: '',
        currentPage: 1,
        size: 10
      },
      total: 0,
      logData: [],
      loading: false,
      dialogVisible: false,
      roles: [],
      operations: [],
      operationTitle: '',
      operationDialogVisible: false,
      operationData: {
        id: null,
        name: '',
        operation: ''
      },
      operationRules: {
        name: [{ required: true, message: '请输入审计操作名称', trigger: 'blur' }],
        operation: [{ required: true, message: '请输入审计操作方法名', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  async mounted() {
    this.initRoles()
    this.initOperations()
    this.initLog()
  },
  methods: {
    getRowKeys(row) {
      return row.logId
    },
    currentChange(page) {
      this.searchLog.currentPage = page
      this.initLog()
    },
    sizeChange(size) {
      this.searchLog.size = size
      this.initLog()
    },
    async initRoles() {
      // 获取Sqr等级类型的角色信息
      let levels
      if (!window.sessionStorage.getItem(SessionStorageKey.MASTER_LEVELS)) {
        levels = await levelsByParam({ type: LevelType.MASTER })
        window.sessionStorage.setItem(SessionStorageKey.MASTER_LEVELS, JSON.stringify(levels))
      } else {
        levels = JSON.parse(window.sessionStorage.getItem(SessionStorageKey.MASTER_LEVELS))
      }

      let levelId
      levels.forEach(level => {
        if (level.name === MasterLevelName.Sqr || level.name === MasterLevelName.Stu) {
          levelId = level.id
        }
      })
      rolesByParams({ levelId: levelId }).then(resp => {
        if (resp) {
          this.roles = resp
        }
      })
    },
    initOperations() {
      auditLogOperations().then(resp => {
        if (resp) {
          this.operations = resp
        }
      })
    },
    initLog() {
      this.loading = true
      auditLogPageData(this.searchLog).then(resp => {
        this.loading = false
        if (resp) {
          this.logData = resp.data
          this.total = resp.total
        }
      })
    },
    deleteLog(data) {
      this.$confirm('此操作将永久删除[' + data.logId + ']记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAuditLog(data.logId).then(resp => {
            if (resp) {
              this.initLog()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showOperationView() {
      this.operationTitle = '审计操作配置'
      this.operationDialogVisible = true
    },
    closeOperationDialog() {
      this.operationData.id = null
      this.operationData.name = ''
      this.operationData.operation = ''
    },
    initOperationInputData() {
      let id = this.operationData.id

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeOperationDialog()
        return
      }

      this.operations.forEach(item => {
        if (String(item.id) === String(id)) {
          this.operationData.id = item.id
          this.operationData.name = item.name
          this.operationData.operation = item.operation
        }
      })
    },
    doAuditOperation() {
      if (this.operationData.id) {
        // 编辑
        this.$refs['operationForm'].validate(valid => {
          if (valid) {
            upOperation(this.operationData).then(resp => {
              if (resp) {
                this.operationDialogVisible = false
                this.initOperations()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['operationForm'].validate(valid => {
          if (valid) {
            addOperation(this.operationData).then(resp => {
              if (resp) {
                this.operationDialogVisible = false
                this.initOperations()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    deleteAuditOperation() {
      this.$confirm('此操作将永久删除[' + this.operationData.name + ']的审计操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delOperation(this.operationData.id).then(resp => {
            if (resp) {
              this.operationDialogVisible = false
              this.initOperations()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.demo-table-expand {
  font-size: 0;
  margin-left: 60px;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.demo-table-expand span {
  display: inline-block;
  width: 90%;
  word-break: break-all;
  white-space: normal;
}
</style>
