<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="角色登录名"
          v-model="searchRole.username"
          clearable
          @keydown.enter.native="
            searchRole.currentPage = 1
            searchRole.size = 8
            initRoles()
          "
        ></el-input>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="角色名称"
          v-model="searchRole.realname"
          clearable
          @keydown.enter.native="
            searchRole.currentPage = 1
            searchRole.size = 8
            initRoles()
          "
        ></el-input>
        <el-select
          v-model="searchRole.levelId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色等级"
          filterable
          clearable
          @change="
            searchRole.currentPage = 1
            searchRole.size = 8
            initRoles()
          "
        >
          <el-option v-for="item in levels" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select
          v-model="searchRole.source"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="角色来源"
          filterable
          clearable
          @change="
            searchRole.currentPage = 1
            searchRole.size = 8
            initRoles()
          "
        >
          <el-option v-for="item in sources" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="resetData">重置</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchRole.currentPage = 1
            searchRole.size = 8
            initRoles()
          "
        >
          搜索
        </el-button>
        <el-button type="success" size="mini" icon="el-icon-download" @click="exportData">导出数据</el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddRoleView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1"
        >
          添加角色
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="roles"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column prop="username" label="角色登录名" align="center" width="150"></el-table-column>
        <el-table-column prop="realname" label="角色名称" align="center" width="150"></el-table-column>
        <el-table-column prop="level.name" label="角色等级" align="center" width="150"></el-table-column>
        <el-table-column label="角色头像" align="center" width="100">
          <template #default="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="initUserFace(scope.row.userFace)"
              :preview-src-list="[initUserFace(scope.row.userFace)]"
            >
              <template #error>
                <div class="image-slot" style="text-align: center; line-height: 49px; border: 1px solid #ccc">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="source" label="角色来源"></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateRoleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteRole(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: flex-end; margin-top: 5px">
        <el-pagination
          background
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :page-sizes="[5, 8, 20, 40, 50, 100]"
          :current-page="searchRole.currentPage"
          :page-size="searchRole.size"
          :total="total"
          @current-change="currentChange"
          @size-change="sizeChange"
        ></el-pagination>
      </div>

      <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        @close="closeDialog()"
        width="400px"
      >
        <el-form ref="roleForm" :model="role" :rules="rules">
          <table>
            <tr>
              <el-form-item label="角色登录名" prop="username" class="quantity-limit">
                <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="role.username"
                  placeholder="请输入角色登录名"
                  maxlength="16"
                ></el-input>
                <span style="position: absolute; right: 10px; bottom: 0">
                  {{ role.username ? role.username.length : 0 }}/16
                </span>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色密码" prop="password">
                <el-input
                  size="mini"
                  type="password"
                  style="width: 200px"
                  v-model="role.password"
                  :disabled="disabled"
                  placeholder="请输入角色密码"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色手机号">
                <el-input
                  size="mini"
                  maxlength="11"
                  style="width: 200px"
                  v-model="role.phone"
                  placeholder="请输入角色手机号"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色名称" prop="realname">
                <el-input
                  size="mini"
                  style="width: 200px"
                  v-model="role.realname"
                  placeholder="请输入角色名称"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色等级" prop="levelId">
                <el-select
                  v-model="role.levelId"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色等级"
                  clearable
                  filterable
                >
                  <el-option v-for="item in levels" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="按钮权限">
                <el-select
                  v-model="role.buttonLevels"
                  collapse-tags
                  size="mini"
                  multiple
                  style="width: 200px"
                  placeholder="按钮权限"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in buttonLevels"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色头像">
                <el-upload
                  ref="upload"
                  :show-file-list="false"
                  :action="filesUploadUrl"
                  :on-success="filesUploadSuccess"
                >
                  <el-button type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <div style="margin-left: 130px">
                <el-image
                  v-if="role.userFace !== undefined && role.userFace !== null && role.userFace !== ''"
                  style="width: 100px; height: 100px; margin-top: 20px"
                  :src="initUserFace(role.userFace)"
                  :preview-src-list="[initUserFace(role.userFace)]"
                ></el-image>
              </div>
            </tr>
            <tr>
              <el-form-item label="角色来源" prop="source">
                <el-select
                  v-model="role.source"
                  size="mini"
                  style="width: 200px"
                  placeholder="角色来源"
                  clearable
                  filterable
                >
                  <el-option v-for="item in sources" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </tr>
          </table>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doRole">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import baseUrl from '@/utils/baseUrl'
import store from '@/store'
import { getUserInfoLevels, userinfoButtonLevels } from '@/api/userinfo'
import { addRole, delRole, roleExport, rolePageData, upRole } from '@/api/sys/role'

export default {
  name: 'Role',
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.role.username === '') {
        callback(new Error('请输入角色登录名'))
      } else if (/[\u4E00-\u9FA5]/g.test(this.role.username)) {
        callback(new Error('不能输入汉字'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      roles: [],
      total: 0,
      searchRole: {
        username: '',
        realname: '',
        source: '',
        levelId: null,
        currentPage: 1,
        size: 8
      },
      levels: [],
      buttonLevels: [],
      dialogVisible: false,
      title: '',
      disabled: false,
      role: {
        id: null,
        username: '',
        password: '',
        realname: '',
        levelId: null,
        source: '',
        phone: '',
        userFace: '',
        buttonLevels: []
      },
      rules: {
        username: [{ validator: validateUsername, trigger: 'blur' }],
        realname: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入角色密码', trigger: 'blur' }],
        levelId: [{ required: true, message: '请选择角色等级', trigger: 'change' }]
      },
      filesUploadUrl: process.env.VUE_APP_UPLOAD_API_HOST + '/files/cosUpload',
      roleButton: store.getters.currentRole,
      sources: store.getters.sources
    }
  },
  computed: {},
  mounted() {
    this.initRoles()
    this.initLevels()
    this.initButtonLevels()
  },
  methods: {
    initRoles() {
      this.loading = true
      rolePageData(this.searchRole).then(resp => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.roles = resp.data
        }
      })
    },
    currentChange(page) {
      this.searchRole.currentPage = page
      this.initRoles()
    },
    sizeChange(size) {
      this.searchRole.size = size
      this.initRoles()
    },
    initLevels() {
      getUserInfoLevels().then(resp => {
        if (resp) {
          this.levels = resp
        }
      })
    },
    initButtonLevels() {
      userinfoButtonLevels().then(resp => {
        if (resp) {
          this.buttonLevels = resp
        }
      })
    },
    deleteRole(data) {
      this.$confirm('此操作将永久删除[' + data.username + ']角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delRole(data.id).then(resp => {
            if (resp) {
              this.initRoles()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddRoleView() {
      this.title = '添加角色'
      this.disabled = false
      this.role = {
        id: null,
        username: '',
        password: '',
        realname: '',
        levelId: null,
        source: '',
        userFace: '',
        buttonLevels: []
      }
      this.dialogVisible = true
    },
    showUpdateRoleView(data) {
      // 拷贝
      this.role = JSON.parse(JSON.stringify(data))
      this.title = '编辑角色'
      // 禁止修改密码
      this.disabled = true
      this.dialogVisible = true
    },
    doRole() {
      if (this.role.id) {
        // 编辑
        this.$refs['roleForm'].validate(valid => {
          if (valid) {
            upRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initRoles()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['roleForm'].validate(valid => {
          if (valid) {
            addRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initRoles()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    exportData() {
      roleExport()
    },
    resetData() {
      this.searchRole.username = ''
      this.searchRole.realname = ''
      this.searchRole.source = ''
      this.searchRole.levelId = null
    },
    filesUploadSuccess(res) {
      this.role.userFace = res.data
    },
    closeDialog() {
      if (this.$refs['upload']) {
        this.$refs['upload'].clearFiles() // 清除历史文件列表
      }
      this.$refs['roleForm'].resetFields()
    },
    initUserFace(image) {
      if (image.startsWith('http')) {
        return baseUrl.default + '/files?filePath=' + image + '&name='
      } else {
        return baseUrl.default + '/files/cosGet?filePath=' + image + '&name='
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
