<template>
  <div>
    <div>
      <div>
        <el-date-picker
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="时间"
          type="date"
          v-model="searchDayRecord.createTime"
          value-format="yyyy-MM-dd"
          clearable
          @change="
            searchDayRecord.currentPage = 1
            searchDayRecord.size = 5
            initDayRecord()
          "
        ></el-date-picker>
        <el-button icon="el-icon-delete" type="warning" size="mini" @click="searchDayRecord.createTime = null">
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchDayRecord.currentPage = 1
            searchDayRecord.size = 5
            initDayRecord()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddDayRecordView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('DayRecord') !== -1
          "
        >
          添加记录
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="dayRecordData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="记录内容">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.context" class="item" effect="dark" placement="right-end">
              <span v-html="scope.row.context.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.context }}</span>
              <!--<span>{{scope.row.context | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="记录时间" align="center" width="140" sortable prop="createTime"></el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" style="padding: 3px" type="warning" @click="showRecordDetail(scope.row)">
              查看
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateDayRecordView(scope.row)"
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('DayRecord') !== -1
              "
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteDayRecord(scope.row)"
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('DayRecord') !== -1
              "
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :page-sizes="[5, 10, 15]"
        :total="total"
        :current-page="searchDayRecord.currentPage"
        :page-size="searchDayRecord.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="dayRecordForm" :model="dayRecord" :rules="rules">
        <table>
          <tr>
            <el-form-item label="记录内容" prop="context">
              <div id="richText"></div>
            </el-form-item>

            <el-form-item label="记录时间">
              <el-date-picker
                style="width: 200px"
                v-model="dayRecord.createTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doDayRecord">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="查看记录源码" :visible.sync="dialogVisible2" width="700px">
      <el-card>
        <div v-html="detail"></div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import { addDayRecord, dayRecordPageData, delDayRecord, upDayRecord } from '@/api/work/dayRecord'
let editor

export default {
  name: 'DayRecord',
  data() {
    var validateContext = (rule, value, callback) => {
      if (editor.txt.html() === undefined || editor.txt.html() === '') {
        callback(new Error('请输入记录内容'))
      } else {
        callback()
      }
    }
    return {
      dayRecordData: [],
      loading: false,
      searchDayRecord: {
        createTime: null,
        currentPage: 1,
        size: 5
      },
      dayRecord: {
        id: null,
        context: '',
        createTime: ''
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        context: [{ validator: validateContext, trigger: 'blur' }]
      },
      detail: '',
      dialogVisible2: false,
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initDayRecord()
  },
  methods: {
    initDayRecord() {
      this.loading = true
      dayRecordPageData(this.searchDayRecord).then(resp => {
        this.loading = false
        if (resp) {
          this.dayRecordData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchDayRecord.currentPage = page
      this.initDayRecord()
    },
    sizeChange(size) {
      this.searchDayRecord.size = size
      this.initDayRecord()
    },
    deleteDayRecord(data) {
      this.$confirm('此操作将永久删除[' + data.createTime + ']时间的记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDayRecord(data.id).then(resp => {
            if (resp) {
              this.initDayRecord()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddDayRecordView() {
      this.dayRecord = {
        id: null,
        context: '',
        createTime: ''
      }
      this.dialogVisible = true
      this.title = '添加记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateDayRecordView(data) {
      // 拷贝
      this.dayRecord = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 500
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.dayRecord.context)
      })
    },
    doDayRecord() {
      if (this.dayRecord.id) {
        // 编辑
        this.$refs['dayRecordForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.dayRecord.context = editor.txt.html()
            upDayRecord(this.dayRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initDayRecord()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['dayRecordForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.dayRecord.context = editor.txt.html()
            addDayRecord(this.dayRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initDayRecord()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['dayRecordForm'].resetFields()
    },
    showRecordDetail(data) {
      this.dialogVisible2 = true
      this.detail = data.context
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
