/**
 * 主权限等级名称
 */
export class MasterLevelName {
  /**
   * admin操作员
   */
  static Sqr = 'Sqr'

  /**
   * 管理员
   */
  static Stu = 'Stu'
  static Suv = 'Suv'
  static Suw = 'Suw'
  static Look = 'Look'
}

/**
 * 权限等级类型
 */
export class LevelType {
  /**
   * 主权限等级
   */
  static MASTER = 1

  /**
   * 按钮权限等级
   */
  static BUTTON = 2
}
