import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 密码列表
export function passPageData(params = {}) {
	return postRequest("/person/pass/getPageData", params);
}

// 删除密码
export function delPass(id) {
	return deleteRequest("/person/pass/" + id);
}

// 添加密码
export function addPass(params = {}) {
	return postRequest("/person/pass", params);
}

// 更新密码
export function upPass(params = {}) {
	return putRequest("/person/pass", params);
}