<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="光锥名称"
          v-model="searchStarRailLightAwl.name"
          clearable
          @keydown.enter.native="
            searchStarRailLightAwl.currentPage = 1
            searchStarRailLightAwl.size = 10
            initStarRailLightAwl()
          "
        ></el-input>
        <el-select
          v-model="searchStarRailLightAwl.star"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="光锥星级"
          clearable
          filterable
          @change="
            searchStarRailLightAwl.currentPage = 1
            searchStarRailLightAwl.size = 10
            initStarRailLightAwl()
          "
        >
          <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-model="searchStarRailLightAwl.destinyId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="光锥命途"
          clearable
          filterable
          @change="
            searchStarRailLightAwl.currentPage = 1
            searchStarRailLightAwl.size = 10
            initStarRailLightAwl()
          "
        >
          <el-option v-for="item in destinies" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchStarRailLightAwl.name = ''
            searchStarRailLightAwl.star = null
            searchStarRailLightAwl.destinyId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchStarRailLightAwl.currentPage = 1
            searchStarRailLightAwl.size = 10
            initStarRailLightAwl()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddStarRailLightAwlView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('StarRailLightAwl') !== -1
          "
        >
          添加光锥
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="starRailLightAwlData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="光锥名称" align="center" width="150" prop="name"></el-table-column>
        <el-table-column label="光锥星级" align="center" sortable prop="star">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.star }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="光锥阶级" align="center" sortable prop="step">
          <template slot-scope="scope">
            <el-tag type="primary">{{ scope.row.step }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="光锥命途" align="center">
          <template slot-scope="scope">
            <el-tag type="danger">{{ scope.row.destiny.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="光锥技能">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.skill && toText(scope.row.skill).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.skill.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.skill }}</span>
              <span v-if="scope.row.skill" v-html="$options.filters.ellipsisHtml(scope.row.skill, 15)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.skill" v-html="toText(scope.row.skill)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="200" align="center" prop="createTime" sortable></el-table-column>
        <el-table-column label="更新时间" width="200" align="center" prop="updateTime" sortable></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('StarRailLightAwl') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateStarRailLightAwlView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('StarRailLightAwl') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteStarRailLightAwl(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchStarRailLightAwl.currentPage"
        :page-size="searchStarRailLightAwl.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="starRailLightAwlForm" :model="starRailLightAwl" :rules="rules">
        <table>
          <tr>
            <el-form-item label="光锥名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="starRailLightAwl.name"
                placeholder="请输入光锥名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥星级" prop="star">
              <el-select
                v-model="starRailLightAwl.star"
                size="mini"
                style="width: 200px"
                placeholder="光锥星级"
                clearable
                filterable
              >
                <el-option v-for="item in stars" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥阶级" prop="step">
              <el-select
                v-model="starRailLightAwl.step"
                size="mini"
                style="width: 200px"
                placeholder="光锥阶级"
                clearable
                filterable
              >
                <el-option v-for="item in steps" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥命途" prop="destinyId">
              <el-select
                v-model="starRailLightAwl.destinyId"
                size="mini"
                style="width: 200px"
                placeholder="光锥命途"
                clearable
                filterable
              >
                <el-option v-for="item in destinies" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="光锥技能">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doStarRailLightAwl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
// wangeditor富文本
import E from 'wangeditor'
import { starRailDestinies } from '@/api/star/destiny'
import {
  addStarRailLightAwl,
  starRailLightAwlPageData,
  delStarRailLightAwl,
  upStarRailLightAwl
} from '@/api/star/lightAwl'
let editor

export default {
  name: 'StarRailLightAwl',
  data() {
    return {
      loading: false,
      destinies: [],
      searchStarRailLightAwl: {
        name: '',
        star: null,
        destinyId: null,
        currentPage: 1,
        size: 10
      },
      starRailLightAwl: {
        id: null,
        name: '',
        star: null,
        step: null,
        destinyId: null,
        skill: ''
      },
      total: 0,
      starRailLightAwlData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入光锥名称', trigger: 'blur' }],
        star: [{ required: true, message: '请选择光锥星级', trigger: 'change' }],
        step: [{ required: true, message: '请选择光锥阶级', trigger: 'change' }],
        destinyId: [{ required: true, message: '请选择光锥命途', trigger: 'change' }]
      },
      roleButton: store.getters.currentRole,
      stars: store.getters.starRailStars,
      steps: store.getters.starRailSteps
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initStarRailLightAwl()
    this.initDestinies()
  },
  methods: {
    initDestinies() {
      starRailDestinies().then(resp => {
        if (resp) {
          this.destinies = resp
        }
      })
    },
    initStarRailLightAwl() {
      this.loading = true
      starRailLightAwlPageData(this.searchStarRailLightAwl).then(resp => {
        this.loading = false
        if (resp) {
          this.starRailLightAwlData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchStarRailLightAwl.currentPage = page
      this.initStarRailLightAwl()
    },
    sizeChange(size) {
      this.searchStarRailLightAwl.size = size
      this.initStarRailLightAwl()
    },
    deleteStarRailLightAwl(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']该光锥, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delStarRailLightAwl(data.id).then(resp => {
            if (resp) {
              this.initStarRailLightAwl()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddStarRailLightAwlView() {
      this.starRailLightAwl = {
        id: null,
        name: '',
        star: null,
        step: null,
        destinyId: null,
        skill: ''
      }
      this.dialogVisible = true
      this.title = '添加光锥'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateStarRailLightAwlView(data) {
      // 拷贝
      this.starRailLightAwl = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑光锥'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.starRailLightAwl.skill)
      })
    },
    doStarRailLightAwl() {
      if (this.starRailLightAwl.id) {
        // 编辑
        this.$refs['starRailLightAwlForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailLightAwl.skill = editor.txt.html()
            upStarRailLightAwl(this.starRailLightAwl).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initStarRailLightAwl()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['starRailLightAwlForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.starRailLightAwl.skill = editor.txt.html()
            addStarRailLightAwl(this.starRailLightAwl).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initStarRailLightAwl()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closeDialog() {
      this.$refs['starRailLightAwlForm'].resetFields()
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
