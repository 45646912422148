/**
 * 权限编码
 */
export class SwitchCode {
  /**
   * 天气预警提醒
   */
  static W001 = 'W001'

  /**
   * 账单金额显示
   */
  static A001 = 'A001'
}
