import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 崩铁行迹列表
export function starRailTrackPageData(params = {}) {
	return postRequest("/star-rail/track/getPageData", params);
}

// 删除崩铁行迹
export function delStarRailTrack(id) {
	return deleteRequest("/star-rail/track/" + id);
}

// 添加崩铁行迹
export function addStarRailTrack(params = {}) {
	return postRequest("/star-rail/track", params);
}

// 更新崩铁行迹
export function upStarRailTrack(params = {}) {
	return putRequest("/star-rail/track", params);
}