/**
 * 登录页选项类型
 */
export class LoginOptionType {
  /**
   * 注册
   */
  static REGIEST = 1

  /**
   * 重置密码
   */
  static RESET = 2
}
