import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有明日方舟地区信息
export function arknightsAreas() {
	return getRequest("/arknights/area/getAll");
}

// 明日方舟获取全部父级地区信息
export function arknightsParentAreas() {
	return getRequest("/arknights/area/getAllParentAreas");
}

// 明日方舟地区记录列表
export function arknightsAreaPageData(params = {}) {
	return postRequest("/arknights/area/getPageData", params);
}

// 删除明日方舟地区
export function delArknightsArea(id) {
	return deleteRequest("/arknights/area/" + id);
}

// 添加明日方舟地区
export function addArknightsArea(params = {}) {
	return postRequest("/arknights/area", params);
}

// 更新明日方舟地区
export function upArknightsArea(params = {}) {
	return putRequest("/arknights/area", params);
}