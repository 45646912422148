const pl = {
	state: {
		plAttribute: ["无", "草", "水", "火", "冰", "地", "雷", "龙", "暗"],
		plWork: [
			"播种",
			"浇水",
			"采集",
			"手工",
			"伐木",
			"采矿",
			"发电",
			"冷却",
			"生火",
			"搬运",
			"牧场",
			"制药",
		],
		plAppetite: [1, 2, 3, 4, 5, 6, 7, 8, 9],
	},
	mutations: {},
	actions: {},
};

export default pl;