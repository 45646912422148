<template>
  <div>
    <div style="margin-top: 15px">
      <div>
        <el-select
          v-model="searchMcRecord.typeId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="记录类型"
          clearable
          filterable
          @change="
            searchMcRecord.currentPage = 1
            searchMcRecord.size = 10
            initMcRecord()
          "
        >
          <el-option v-for="item in mcRecordTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="记录名称"
          v-model="searchMcRecord.name"
          clearable
          @keydown.enter.native="
            searchMcRecord.currentPage = 1
            searchMcRecord.size = 10
            initMcRecord()
          "
        ></el-input>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchMcRecord.name = ''
            searchMcRecord.isWall = null
            searchMcRecord.typeId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchMcRecord.currentPage = 1
            searchMcRecord.size = 10
            initMcRecord()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddMcRecordView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('McRecord') !== -1
          "
        >
          添加记录
        </el-button>
        <el-button type="danger" size="mini" @click="showTypeView">记录类型配置</el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="mcRecordData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="记录名称" align="center" prop="name"></el-table-column>
        <el-table-column label="记录类型" align="center" prop="typeName"></el-table-column>
        <el-table-column label="记录地址" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.url === null || scope.row.url === '' || scope.row.url === undefined">-</span>
            <a target="_blank" v-else :href="scope.row.url">{{ scope.row.name }}</a>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" label="记录描述">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsisHtml(scope.row.remark, 15)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('McRecord') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateMcRecordView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('McRecord') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteMcRecord(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchMcRecord.currentPage"
        :page-size="searchMcRecord.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="mcRecordForm" :model="mcRecord" :rules="rules">
        <table>
          <tr>
            <el-form-item label="记录类型">
              <el-select
                v-model="mcRecord.typeId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="记录类型"
                clearable
                filterable
              >
                <el-option v-for="item in mcRecordTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="记录名称" prop="name">
              <el-input
                size="mini"
                style="width: 400px"
                v-model="mcRecord.name"
                placeholder="请输入记录名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="记录地址" prop="url">
              <el-input size="mini" style="width: 700px" v-model="mcRecord.url" placeholder="请输入记录名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="记录描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMcRecord">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 记录类型 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="typeTitle"
      @close="closeTypeDialog()"
      :visible.sync="typeDialogVisible"
      width="400px"
    >
      <el-form ref="typeForm" :model="typeData" :rules="typeRules">
        <table>
          <tr>
            <el-form-item label="类型编号">
              <el-select
                v-model="typeData.id"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="记录类型编号"
                clearable
                filterable
                @change="initTypeInputData()"
              >
                <el-option v-for="item in mcRecordTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="typeData.name"
                placeholder="请输入记录类型名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="类型描述">
              <el-input
                type="textarea"
                :rows="5"
                size="mini"
                style="width: 200px"
                v-model="typeData.remark"
                placeholder="请输入记录类型描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeDialogVisible = false">取 消</el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('McRecord') !== -1 &&
            typeData.id !== null
          "
          type="danger"
          @click="deleteMcRecordType"
        >
          删 除
        </el-button>
        <el-button
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('McRecord') !== -1
          "
          type="primary"
          @click="doMcRecordType"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  mcRecordPageData,
  mcRecordTypes,
  delMcRecordType,
  upMcRecordType,
  delMcRecord,
  addMcRecordType,
  addMcRecord,
  upMcRecord
} from '@/api/mc/mcRecord'
let editor

export default {
  name: 'McRecord',
  data() {
    return {
      mcRecordData: [],
      loading: false,
      searchMcRecord: {
        name: '',
        typeId: null,
        currentPage: 1,
        size: 10
      },
      mcRecord: {
        id: null,
        name: '',
        url: '',
        typeId: null
      },
      total: 0,
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入记录名称', trigger: 'blur' }]
      },
      mcRecordTypes: [],
      typeTitle: '',
      typeDialogVisible: false,
      typeData: {
        id: null,
        name: '',
        remark: ''
      },
      typeRules: {
        name: [{ required: true, message: '请输入记录类型名称', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initMcRecord()
    this.initMcRecordType()
  },
  methods: {
    initMcRecord() {
      this.loading = true
      mcRecordPageData(this.searchMcRecord).then(resp => {
        this.loading = false
        if (resp) {
          this.mcRecordData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchMcRecord.currentPage = page
      this.initMcRecord()
    },
    sizeChange(size) {
      this.searchMcRecord.size = size
      this.initMcRecord()
    },
    deleteMcRecord(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMcRecord(data.id).then(resp => {
            if (resp) {
              this.initMcRecord()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddMcRecordView() {
      this.mcRecord = {
        id: null,
        name: '',
        url: '',
        typeId: null
      }
      this.dialogVisible = true
      this.title = '添加记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateMcRecordView(data) {
      // 拷贝
      this.mcRecord = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑记录'
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.mcRecord.remark)
      })
    },
    closeDialog() {
      this.$refs['mcRecordForm'].resetFields()
    },
    doMcRecord() {
      if (this.mcRecord.id) {
        // 编辑
        this.$refs['mcRecordForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.mcRecord.remark = editor.txt.html()
            upMcRecord(this.mcRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMcRecord()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['mcRecordForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.mcRecord.remark = editor.txt.html()
            addMcRecord(this.mcRecord).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initMcRecord()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    initMcRecordType() {
      mcRecordTypes().then(resp => {
        if (resp) {
          this.mcRecordTypes = resp
        }
      })
    },
    showTypeView() {
      this.initMcRecordType()
      this.typeTitle = '记录类型配置'
      this.typeDialogVisible = true
    },
    closeTypeDialog() {
      this.typeData.id = null
      this.typeData.name = ''
      this.typeData.remark = ''
    },
    initTypeInputData() {
      let id = this.typeData.id

      // 如果是清除就恢复最初数据
      if (id === '') {
        this.closeTypeDialog()
        return
      }

      this.mcRecordTypes.forEach(item => {
        if (String(item.id) === String(id)) {
          this.typeData.id = item.id
          this.typeData.name = item.name
          this.typeData.remark = item.remark
        }
      })
    },
    deleteMcRecordType() {
      this.$confirm('此操作将永久删除[' + this.typeData.name + ']的记录类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMcRecordType(this.typeData.id).then(resp => {
            if (resp) {
              this.typeDialogVisible = false
              this.initMcRecordType()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doMcRecordType() {
      if (this.typeData.id) {
        // 编辑
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            upMcRecordType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initMcRecordType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['typeForm'].validate(valid => {
          if (valid) {
            addMcRecordType(this.typeData).then(resp => {
              if (resp) {
                this.typeDialogVisible = false
                this.initMcRecordType()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
