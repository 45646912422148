<template>
  <div>
    <div>
      <div>
        <el-select
          v-model="searchHomeModule.parentId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="模块类型"
          clearable
          filterable
          @change="
            searchHomeModule.currentPage = 1
            searchHomeModule.size = 10
            initHomeModule()
          "
        >
          <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
        </el-select>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="模块名称"
          v-model="searchHomeModule.name"
          clearable
          @keydown.enter.native="
            searchHomeModule.currentPage = 1
            searchHomeModule.size = 10
            initHomeModule()
          "
        ></el-input>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchHomeModule.name = ''
            searchHomeModule.type = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchHomeModule.currentPage = 1
            searchHomeModule.size = 10
            initHomeModule()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddHomeModuleView"
          v-if="
            roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('HomeModule') !== -1
          "
        >
          添加模块
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="homeModuleData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="模块类型" align="center" prop="typeName"></el-table-column>
        <el-table-column label="模块名称" width="250" align="center" prop="name"></el-table-column>
        <el-table-column label="模块图标" width="250" align="center" prop="cuIcon"></el-table-column>
        <el-table-column label="模块颜色" width="250" align="center" prop="color"></el-table-column>
        <el-table-column label="模块排序" width="150" align="center" prop="mid">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.mid" type="primary">{{ scope.row.mid }}</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否启用">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HomeModule') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateHomeModuleView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('HomeModule') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteHomeModule(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchHomeModule.currentPage"
        :page-size="searchHomeModule.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <el-form ref="homeModuleForm" :model="homeModule" :rules="rules">
        <table>
          <tr>
            <el-form-item label="模块类型" prop="parentId">
              <el-select
                v-model="homeModule.parentId"
                size="mini"
                style="width: 200px"
                placeholder="模块类型"
                clearable
                filterable
              >
                <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模块名称" prop="name">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="homeModule.name"
                placeholder="请输入模块名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模块图标">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="homeModule.cuIcon"
                placeholder="请输入模块图标"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模块颜色">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="homeModule.color"
                placeholder="请输入模块颜色"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="模块排序">
              <el-input-number
                size="mini"
                style="width: 150px"
                :min="0"
                v-model="homeModule.mid"
                placeholder="请输入模块排序"
              ></el-input-number>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="是否启用">
              <el-switch
                v-model="homeModule.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="padding-top: 6px"
              ></el-switch>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doHomeModule">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { addHomeModule, delHomeModule, homeModulePageData, homeModuleTypes, upHomeModule } from '@/api/sys/homeModule'

export default {
  name: 'HomeModule',
  data() {
    return {
      types: [],
      homeModuleData: [],
      loading: false,
      searchHomeModule: {
        parentId: null,
        name: '',
        currentPage: 1,
        size: 10
      },
      total: 0,
      dialogVisible: false,
      title: '',
      homeModule: {
        id: null,
        parentId: null,
        name: '',
        cuIcon: '',
        color: '',
        mid: '',
        enabled: true
      },
      rules: {
        parentId: [{ required: true, message: '请选择模块类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入模块名称', trigger: 'blur' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initTypes()
    this.initHomeModule()
  },
  methods: {
    initTypes() {
      homeModuleTypes().then(resp => {
        if (resp) {
          this.types = resp
        }
      })
    },
    initHomeModule() {
      this.loading = true
      homeModulePageData(this.searchHomeModule).then(resp => {
        this.loading = false
        if (resp) {
          this.homeModuleData = resp.data
          this.total = resp.total
        }
      })
    },
    currentChange(page) {
      this.searchHomeModule.currentPage = page
      this.initHomeModule()
    },
    sizeChange(size) {
      this.searchHomeModule.size = size
      this.initHomeModule()
    },
    showAddHomeModuleView() {
      this.homeModule.id = null
      this.homeModule.parentId = null
      this.homeModule.name = ''
      this.homeModule.cuIcon = ''
      this.homeModule.color = ''
      this.homeModule.mid = ''
      this.homeModule.enabled = true

      this.dialogVisible = true
      this.title = '添加模块'
    },
    showUpdateHomeModuleView(data) {
      // 拷贝
      this.homeModule = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑模块'
    },
    closeDialog() {
      this.$refs['homeModuleForm'].resetFields()
    },
    doHomeModule() {
      if (this.homeModule.id) {
        // 编辑
        this.$refs['homeModuleForm'].validate(valid => {
          if (valid) {
            upHomeModule(this.homeModule).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHomeModule()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['homeModuleForm'].validate(valid => {
          if (valid) {
            addHomeModule(this.homeModule).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initHomeModule()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    deleteHomeModule(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']模块, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delHomeModule(data.id).then(resp => {
            if (resp) {
              this.initHomeModule()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
