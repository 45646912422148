import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 我的世界记录列表
export function mcRecordPageData(params = {}) {
	return postRequest("/mc/record/getPageData", params);
}

// 删除记录
export function delMcRecord(id) {
	return deleteRequest("/mc/record/" + id);
}

// 添加记录
export function addMcRecord(params = {}) {
	return postRequest("/mc/record", params);
}

// 编辑记录
export function upMcRecord(params = {}) {
	return putRequest("/mc/record", params);
}

// 获取我的世界所有类型
export function mcRecordTypes() {
	return getRequest("/mc/record/getMcRecordTypeList");
}

// 删除我的世界类型
export function delMcRecordType(id) {
	return deleteRequest("/mc/record/deleteMcRecordType/" + id);
}

// 编辑我的世界类型
export function upMcRecordType(params = {}) {
	return putRequest("/mc/record/updateMcRecordType", params);
}

// 添加我的世界类型
export function addMcRecordType(params = {}) {
	return postRequest("/mc/record/addMcRecordType", params);
}