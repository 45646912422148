<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          size="mini"
          placeholder="地区名称"
          v-model="searchArea.name"
          clearable
          @keydown.enter.native="
            searchArea.currentPage = 1
            searchArea.size = 10
            initArea()
          "
        ></el-input>
        <el-select
          v-model="searchArea.parentId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="父级地区"
          clearable
          filterable
          @change="
            searchArea.currentPage = 1
            searchArea.size = 10
            initArea()
          "
        >
          <el-option v-for="item in parentArea" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          size="mini"
          @click="
            searchArea.name = ''
            searchArea.parentId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="
            searchArea.currentPage = 1
            searchArea.size = 10
            initArea()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="showAddAreaView"
          v-if="
            roleButton.buttonLevelComponents !== null &&
            roleButton.buttonLevelComponents.indexOf('ArknightsArea') !== -1
          "
        >
          添加地区
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="areaData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column prop="name" align="center" width="120" label="地区名称"></el-table-column>
        <el-table-column prop="parent.name" align="center" width="120" label="父级地区"></el-table-column>
        <el-table-column align="center" width="120" label="地区原型">
          <template slot-scope="scope">
            <span v-if="scope.row.prototype">{{ scope.row.prototype }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="地区描述">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 50"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsisHtml(scope.row.remark, 50)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsArea') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateAreaView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null &&
                roleButton.buttonLevelComponents.indexOf('ArknightsArea') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteArea(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchArea.currentPage"
        :page-size="searchArea.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form ref="areaForm" :model="area" :rules="rules" label-width="125px">
        <table>
          <tr>
            <el-form-item label="地区名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="area.name" placeholder="请输入地区名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="父级地区" prop="parentId">
              <el-select
                v-model="area.parentId"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="请选择父级地区"
                clearable
                filterable
              >
                <el-option v-for="item in parentArea" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="地区原型">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="area.prototype"
                placeholder="请输入地区原型"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="地区描述">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doArea">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// wangeditor富文本
import E from 'wangeditor'
import store from '@/store'
import {
  addArknightsArea,
  delArknightsArea,
  arknightsAreaPageData,
  arknightsParentAreas,
  upArknightsArea
} from '@/api/arknights/area'
let editor

export default {
  name: 'ArknightsArea',
  data() {
    return {
      areaData: [],
      parentArea: [],
      loading: false,
      total: 0,
      currentPage: 1,
      size: 10,
      searchArea: {
        name: '',
        parentId: null,
        currentPage: 1,
        size: 10
      },
      area: {
        id: null,
        name: '',
        parentId: null,
        remark: '',
        prototype: ''
      },
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入地区名称', trigger: 'blur' }],
        parentId: [{ required: true, message: '请选择父级地区', trigger: 'change' }]
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initArea()
    this.initParentArea()
  },
  methods: {
    initArea() {
      this.loading = true
      arknightsAreaPageData(this.searchArea).then(resp => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.areaData = resp.data
        }
      })
    },
    initParentArea() {
      arknightsParentAreas().then(resp => {
        if (resp) {
          this.parentArea = resp
        }
      })
    },
    currentChange(page) {
      this.searchArea.currentPage = page
      this.initArea()
    },
    sizeChange(page) {
      this.searchArea.size = page
      this.initArea()
    },
    deleteArea(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']地区, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delArknightsArea(data.id).then(resp => {
            if (resp) {
              this.initArea()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddAreaView(data) {
      this.area = {
        id: null,
        name: '',
        parentId: null,
        remark: '',
        prototype: ''
      }
      this.title = '添加地区'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateAreaView(data) {
      // 拷贝
      this.area = Object.assign({}, data)
      this.title = '编辑地区'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 300
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html('')
        editor.txt.html(this.area.remark)
      })
    },
    closeDialog() {
      this.$refs['areaForm'].resetFields()
    },
    doArea() {
      if (this.area.id) {
        // 编辑
        this.$refs['areaForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.area.remark = editor.txt.html()
            upArknightsArea(this.area).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initArea()
                this.initParentArea()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['areaForm'].validate(valid => {
          if (valid) {
            // 富文本需要手动赋值
            this.area.remark = editor.txt.html()
            addArknightsArea(this.area).then(resp => {
              if (resp) {
                this.dialogVisible = false
                this.initArea()
                this.initParentArea()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
