/**
 * html转文本
 *
 * @param {*} HTML
 * @returns 文本
 */
export function toText(HTML) {
  let input = HTML
  return input
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
}

/**
 * 列表样式
 *
 * @param {*} param0 列表
 * @returns 样式
 */
export function tableRowClassName({ row, rowIndex }) {
  if (rowIndex % 2 !== 0) {
    return 'warning-row'
  } else if (rowIndex % 2 === 0) {
    return 'success-row'
  }
  return ''
}

/**
 * 是否是操作员
 *
 * @returns 是否
 */
export function isOperator() {
  let role = JSON.parse(window.sessionStorage.getItem('role'))
  return role.levelId && (role.levelId === 1 || role.levelId === 2)
}

export default {
  methods: { toText, tableRowClassName, isOperator }
}
