const arknights = {
	state: {
		arknightsLevelTypes: ["未精", "精一", "精二"],
		arknightsMasteryTypes: [
			"0",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"专一",
			"专二",
			"专三",
		],
		arknightsPotentials: [1, 2, 3, 4, 5],
		arknightsStars: [1, 2, 3, 4, 5, 6],
		arknightsMaterialsProbabilities: ["罕见", "小概率", "概率掉落", "固定掉落"],
	},
	mutations: {},
	actions: {},
};

export default arknights;