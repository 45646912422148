import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/utils/api.js";

// 获取所有原神圣遗物
export function genshinHolyRelics() {
	return getRequest("/genshin/holy-relic/getAll");
}

// 原神圣遗物记录列表
export function genshinHolyRelicPageData(params = {}) {
	return postRequest("/genshin/holy-relic/getPageData", params);
}

// 删除原神圣遗物
export function delGenshinHolyRelic(id) {
	return deleteRequest("/genshin/holy-relic/" + id);
}

// 添加原神圣遗物
export function addGenshinHolyRelic(params = {}) {
	return postRequest("/genshin/holy-relic", params);
}

// 更新原神圣遗物
export function upGenshinHolyRelic(params = {}) {
	return putRequest("/genshin/holy-relic", params);
}